export const es = {
  next: "Siguiente, próxima",
  youWontBeCharged:
    "No se le cobrará hasta que confirme los detalles de la sesión",
  home: "Casa,hogar",
  chats: "Chats",
  ratings: "Calificaciónes, clasificaciónes",
  settings: "Ajustes, configuraciónes",
  moreSettings: "Más configuraciónes, más ayustes",
  settingsNotify: "Notificaciónes de configuraciones/ Waylight",
  logOut: "Cerra la sessión",
  changePassword: "Cambia la contraseña",
  receiveResetPassword:
    "Haga clic en “Restablecer“ a continuación para recibir un enlace de restablecimiento de contraseña.",
  enterEmailForResetPassword:
    "Ingrese su correo electrónico para obtener un enlace de restablecimiento de contraseña.",
  successResetPassword:
    "El correo electrónico de restablecimiento de contraseña ha sido enviado!",
  failResetPassword: "Error al restablecer la contraseña",
  reset: "Reiniciar, restablecer",
  signOut: "Desconecta, cerra la sessión",
  availableBooking: "Disponible para reservar",
  unavailableBooking: "No disponible para reservar",
  shareProfile: "Comparte perfil",
  requests: "Peticiones",
  hours: "Horas",

  totalEarned: "Total ganado",
  totalHours: "Horas totales",

  runningNow: "Se está ejecutando ahora",

  offer: "Oferta",
  gallery: "Galería",
  photo: "Foto",
  occupation: "Ocupación",
  skills: "Habilidades",
  experts: "Expertos",
  noInformation: "Sin información",
  speaks: "Habla",
  socialLinks: "Vínculos sociales",
  contacts: "Contactos",
  noOffersYet: "No hay ofertas disponibles",
  all: "Todos",
  requested: "Está solicitado",
  confirmed: "Está confirmado",
  passed: "Está Pasado",
  joined: "Está unido",
  ongoing: "Está en curso",
  canceled: "Está cancelado",
  rejected: "Está rechazado",
  approved: "Está aprobado",
  calendar: "Calendario",
  favorites: "Está seguido",
  removeAll: "Eliminar todo",
  viewProfile: "Observer perfil",
  today: "Hoy, este día",
  upcoming: "Próximas ",
  attachments: "Archivos adjuntos",

  prepare: "Prepararse",

  prerequisites: "Requisitos previos",
  download: "Descargar",
  past: "Pasado",
  decline: "Rechazar",
  accept: "Aceptar",

  wantDeclinePaidSession:
    "¿Está seguro de que desea rechazar la solicitud? El pago de su cliente será reembolsado.",
  wantDeclineAfterApprove:
    "¿Está seguro de que quiere cancelar? El pago de su cliente será reembolsado.",
  videoCall: "Empieza a transmitir",

  findExpert: "Encontra experto",
  selectAnExpert: "Selecciona un experto",
  inviteClients: "Invita a tu clientes",
  selectAChat: "Selecciona un chat para iniciar la mensajería",
  noChatsYet: "Todavía no hay chats",
  notHaveMessages: "Aún no tiene ningún mensaje.",
  shareProfileToClient: "Comparte el perfil con el cliente",
  remove: "Quitar",
  time: "Tiempo",
  timeSlots: "Franjas horarias",
  deleteOffer: "Eliminar oferta",
  myOffers: "Ofertas",

  createNewOffer: "Crear la oferta nueva",
  editOffer: "Editar oferta",
  successfulyBooked: "Sesión reservada con éxito",
  close: "Cerca",
  account: "Cuenta",
  price: "Precio",
  priceAndLength: "Precio y duración",

  uploadAFile: "Subir un archivo",
  uploadAFileError: "El archivo no se cargó",
  confirm: "Confirmar",
  IDLogin: "Su correo electrónico",
  myProfile: "El perfil del cliente",
  transactions: "Transacciones",
  transactionHistory: "La historia de transacciones",
  noTransactions: "Todavía no tiene transacciones",

  typicalSchedule: "Horario típico",
  amount: "Cantidad",
  status: "Estatus",
  paid: "Pagado",
  action: "Acción",
  description: "Descripción",
  billing: "Facturación",
  card: "Tarjeta",
  clients: "Clientes",

  payout: "Desembolso",
  firstName: "Nombre de pila",
  lastName: "Apellido",
  skip: "Saltar",
  connect: "Conectar",
  about: "Sobre",
  title: "Título",
  languages: "Idiomas",
  currency: "Moneda",
  addYourOffer: "Añade su oferta",
  join: "Unirse",
  joinIn: "Únase a...",
  joinOn: "Únase en...",
  message: "Mensaje",
  client: "Cliente",
  expert: "Experto",
  Expert: "Experto",
  noMoreOptions: "No mas opciones",
  male: "Masculino",
  female: "Femenino",
  custom: "Personalizar",
  copyYourLink: "Copia su enlace",
  done: "Está hecho",
  search: "Buscar",
  searchWaylight: "Encuentra su Waylight",
  suggested: "Está Sugirido",

  startASession: "Sesión rápida",
  quickSession: "Sesión rápida",
  rate: "Calificar",
  perHour: "por hora",
  packages: "Paquetes",
  timezone: "Zona horaria",
  book: "Libro",
  favorite: "Favorito",
  lastSession: "Última interacción",
  ago: "Atrás, hace",
  gender: "Género",
  loading: "Carga, cargando",
  min: "Mín",
  hour: "Hora",
  online: "Online",
  offline: "Offline",
  date: "Fecha",
  displayName: "Monstra el nombre",
  closePanel: "Cierra el panel",
  goToStripe: "Abre tu panel de pagos",
  completeStripeOnBoarding: "Completa la incorporación de Stripe Express",
  stripeOnBoarding: "Incorporación de Stripes",
  completed: "Está terminado",
  notCompleted: "No se ha completado",
  myClients: "Mis clientes",
  findClients: "No podemos encontrar clientes.",
  clientDisplayedHere: "Cuando encuentre clientes, se mostrarán aquí.",
  writeSomething: "Ingrese algo y presione Enter para crear un elemento",

  registerAs: "Regístrese como:",
  addedCards: "Sus tarjetas",
  cardType: "Tipo de la tarjeta",
  billingHistory: "Historial de facturación",
  deleteCard: "Elimina la tarjeta",
  wantToDeleteCard: "¿Está seguro de que desea eliminar la tarjeta?",
  selectPaymentMethod: "Seleccione el método de pago",
  addNewCard: "Agrega una nueva tarjeta",
  emailAddress: "Correo electrónico público",
  phoneNumber: "Número de teléfono",
  publicPhoneNumber: "Número de teléfono público",
  username: "Nombre de usuario",
  socialProfilesOrLinks: "Perfiles sociales / Otros enlaces",
  newOffer: "Nueva oferta",
  myExperts: "Mis expertos",
  myClientProfile: "Mi perfil de cliente",
  noExpertsFound: "No se han encontrado expertos",
  openMenu: "Abre el menú",
  becomeAnExpert: "Únase a Waylight Business",
  switchToExpert: "Cambia a la vista de empresa",
  switchToClient: "Cambia a la vista de cliente",
  notHaveExperts: "Aún no tiene ningún expertos",
  endCall: "Detenia la transmisión",
  endSharing: "Deja de compartir",
  lastOnline: "Dura online",
  clientSince: "Es un ciente desde...",
  welcome: "Bienvenidos",
  messageError: "Campo obligatorio",
  support: "Apoyo",
  profile: "Perfil",
  portfolio: "Portafolio",
  publicId: "Identificación pública",
  files: "Archivos, files",
  reason: "Razón, motivo",
  declineReason: "Hágale saber a su cliente por qué tiene que rechazar",
  rejectionReason: "Motivo de rechazar",
  business: "Business, negocio, empresa",
  passwordResetInvalidEmail: "El correo electrónico no es válido",
  logIn: "Iniciar la sesión",
  logInSubtitle:
    "Para realizar cualquier acción, inicie la sesión o regístrese.",
  enterEmail: "Ingrese su correo electrónico",
  enterPassword: "Contraseña",
  capsLockIsOn: "Caps Lock está activado",
  numLockIsOn: "Num Lock está activado",
  forgotPassword: "¿Olvidase su contraseña?",
  forgotPasswordEmail:
    "Ingrese su dirección de correo electrónico para obtener un enlace de restablecimiento de contraseña.",
  requestPasswordReset: "Solicita el restablecimiento de la contraseña",
  requestResetLink: "Reenvia el enlace",
  in: "En",
  sec: "Segundo, momento",
  passwordResetRequestReceived:
    "Se ha recibido la solicitud de restablecimiento de contraseña",
  passwordResetIfAccountExist:
    "Si existe una cuenta, pronto recibirá un correo electrónico con un enlace.",
  resetPassword: "Restablece la contraseña",
  passwordChangeSuccessful: "Conraceńa ha cambiado correctamente",
  passwordChangeSuccessfulSubtitle:
    "Ahora puede usar su nueva contraseña para iniciar sesión en su cuenta.",
  setNewPassword:
    "Establece una nueva contraseña para iniciar sesión en su cuenta",
  passwordChange: "Cambia la contraseña",
  passwordOld: "Contraseña anterior",
  passwordNew: "Contraseña nueva",
  dontHaveAccount: "¿No tiene una cuenta?",
  alreadyRegistered: "¿Ya tiene una cuenta?",
  tokenExpired: "Token caducada",
  tokenExpiredDescription: "Este token ha caducado o se ha utilizado antes",
  validLength: "8 o más signos",
  upperCase: "Letras mayúsculas (A-Z)",
  lowerCase: "Letras minúsculas (a-z)",
  numbersOrSymbols: "Números o símbolos (0-9!@#$%^&*)",
  joinFree: "Únese gratis",
  businessTitle: "Waylight | Herramienta Link in BIO que te ayuda a ganar!",
  businessDescription:
    "¡Gana 10 veces más en tus redes sociales! ¡Inicia en solo 2 minutos!",

  spotlightYourExperience:
    "Instrumentos completos para su contenido,  expertos y corrientes de ingresos de la comunidad",
  monetizationPlatform: "Estudio de Negocios Online",
  createAccount: "Crea una cuenta",
  watchVideo: "Vea el video",
  bestToolsAvailable:
    "Queremos que tenga las mejores herramientas disponibles y no le cobraremos por usarlas.",
  schedulingManagement: "Gestión de la programación.",
  chooseConvenientTimeSlots:
    "Elija franjas horarias convenientes para el trabajo y obtenga reservas.",
  expertLandingPayouts: "Desembolsos.",
  simpleOnboarding: "Incorporación sencilla de sus cuentas financieras.",
  industryStandard:
    "Calidad y disponibilidad de llamadas estándar de la industria.",
  lead: "Guiar",
  engage: "involucrar",
  frequentlyAskedQuestions: "FAQ(preguntas frecuentes)",
  contactUs: "Contáctenos:",
  accordionLabel0: "Si el servicio es gratuito, ¿cómo funciona Waylight?",
  accordionText0:
    "Estamos tomando una comisión basada en el volumen de las cargas de expertos y las llamadas de descubrimiento y de caridad están sobre nosotoros!",
  accordionLabel1: "¿Admite varios tipos de ofertas?",
  accordionText1:
    "Por supuesto, puede crear tantas ofertas como desee, especificando el precio, la duración y los detalles de cada oferta.",
  accordionLabel2: "¿Cómo me pagan?",
  accordionText2:
    "Estamos utilizando Stripe y otras puertas de enlace para manejar los pagos. Se requiere una simple incorporación para conectar su cuenta bancaria o método de pago, que funciona en más de 55 países. Múltiples métodos de pago más y configuraciones de pago flexibles están en camino.",
  accordionLabel3: "¿Puedo usar Zoom, Meet o Skype?",
  accordionText3:
    "Tenemos una solución de video totalmente integrada, diseñada para garantizar un flujo seguro para ambas partes. El uso de otras soluciones de video con menos seguridad pero más flexibilidad será una opción en el futuro.",
  accordionLabel4: "¿Puedo usar Zapier, Calendly, etc.?",
  accordionText4:
    "Estamos trabajando activamente en una acumulación(backlog) de integraciones que nuestros usuarios más desean. Estaremos encantados de escuchar sus ideas sobre este asunto. Envíe un correo electrónico rápido a support@waylight.me. ¡Gracias!",
  freeAudience: "¡Nosotros desarrollamos, usted crea!",
  businessProfile: "Perfil de business(negocio).",
  onlineBusiness:
    "Cree negocios en línea en minutos, destaque lo que hace mejor.",
  paidCalls: "Llamadas de audio y video de pago.",
  paidChatsAndBlog: "Chats y blog de pago.",
  setUpSubscription: "Configure la suscripción para chatear y blog personal.",
  web3: "Calificaciones y reseñas de Web3.",
  collectRealRatings:
    "Recopile calificaciones y reseñas reales para construir su marca personal.",
  getStartedToday: "Tres sencillos pasos para una nueva fuente de ingresos:",
  createProfile: "1. Crea tu perfil",
  fillTemplate:
    "Complete una plantilla preconstruida de su perfil, horario y ofertas, configure precios para el chat y el blog pagados y en cuestión de minutos estará listo para aceptar pedidos y suscriptores. ",
  shareIt: "2. Compártelo con el mundo",
  linkYourProfile:
    "Vincule su perfil con todos sus canales: sus redes sociales, anuncios, correo electrónico y más. Comparta información sobre su negocio en línea en publicaciones, historias, videos, mensajes personales, etc.",
  growYourBusiness: "3. Haga crecer su negocio",
  shareYourExpertise:
    "Comparta su experiencia y conocimiento, actualice su blog, responda a reservas y chats, realice consultas de video en línea y obtenga sus calificaciones y reseñas públicas de Web3.",
  businessflow: "Flujo",
  businessflowStep1Title: "Agrega enlace en BIO",
  businessflowStep1:
    "Agrega el enlace de tu perfil de Waylight Business en las redes sociales BIO (Instagram, Facebook, YouTube, LinkedIn, Twitter, TikTok, etc.) y comparte la información a través de historias y publicaciones al respecto.",
  businessflowStep2Title: "Videollamadas 1:1, chats 1:1, blog Personal",
  businessflowStep2:
    "Monetiza a través de videollamadas 1:1, chats 1:1 con tus suscriptores y clientes. Cree contenido solo para miembros en su blog personal con tutoriales, artículos, consejos en video, backstage, experiencias de vida personal, lecciones, música, etc.",
  businessflowStep3Title: "Recibe su pago",
  businessflowStep3:
    "Recibe pagos por sus videollamadas, chats y suscripciones exclusivas para miembros en su blog personal. ¡Haz crecer tu monetización compartiendo más información sobre su Waylight!",
  bookOnboardingCall: "Reserve una llamada de incorporación gratuita",
  talkToFounders:
    "Dídales con los fundadores cómo empezar a monetizar con Waylight.",
  orScanQR: "o escanear QR",
  yourFreeTools: "Tus herramientas gratuitas",
  features: "Características",
  personalLink: "URL personal",
  personalQR: "Código QR personal",
  scheduling: "Horario, la programación",
  bookings: "Reservaciones",
  paidChats: "Chats 1:1 pagados",
  personalPaidBlog: "Blog personal pagado",
  soon: "pronto",
  subscriptions: "Suscripciones",
  payments: "Pagos en más de 100 países",
  payouts: "Desembolsos en más de 58 países",
  cryptoPayments: "Pagos criptográficos",
  coinsAwarding: "Monedas de premio",
  web3Ratings: "Calificaciones Web3, reseñas",
  clientsManagement: "Gestión (administración) de clientes",

  dashboard: "Tablero",
  businessCalculator: "Calculadora de ingresos de negocios de Waylight",
  howMuchMoney:
    "Descúbrelo cuánto dinero aportarían los suscriptores a su empresa.",
  estimateNumber: "1. Estime el número de clientes y suscriptores",
  howMuchYouCharge: "2. ¿Cuánto puede cobrar por videollamada o suscripción?",
  monthlyIncome: "Ingresos mensuales",
  newToday: "Nuevo hoy",

  atThisWeek: "Esta semana",
  welcomeToWaylight: "¡Bienvenido a Waylight!",
  welcomeToWaylightBusiness: "¡Bienvenido a Waylight Business!",
  linkToVideo: "Enlace al video introductorio",
  writeSentences: "Escribe algunas oraciones sobre Usted.",
  emptyExpertTitle: "Parece que esta página no existe",
  emptyExpertText:
    "El enlace puede estar roto, o la página ha sido desactivada o eliminada.",
  profileData: "Datos de perfil",
  headerImageTooltip:
    "Las dimensiones sugeridas de la imagen del encabezado son de aproximadamente 10:1. Puede probar algo como 1900x190px o 1280x128px.",
  emailTooltip:
    "Esta es una dirección de correo electrónico para visualización pública (no su logín). Puede especificar cualquier correo electrónico que desee o eliminarlo por completo, ¡es opcional!",
  timezoneTooltip:
    "Si no puede encontrar su ciudad, intente buscar la zona, como 'Hora de verano del Pacífico' o 'Hora de Europa del Este'.",
  linkToVideoPresentation: "Agregar un enlace externo",
  expertProfileVideoPresentation: "O cargar un archivo",
  linkToVideoPresentationTooltip:
    "Establece un enlace a un video externo para que forme parte de sus detalles. La mayoría de los servicios de alojamiento de video existentes son compatibles. Si sube un video, tendrá prioridad sobre este enlace.",
  linkToVideoOfferTooltip:
    "Establece un enlace a un video externo para que forme parte de los detalles de la oferta. La mayoría de los servicios de alojamiento de video existentes son compatibles. Si sube un video, tendrá prioridad sobre este enlace.",
  phoneTooltip:
    "Este es un número de teléfono para exhibición pública. ¡Es opcional!",
  socialProfilesTooltip:
    "Agregue enlaces a sus perfiles y sitios sociales, como Youtube, Facebook, Linkedin, Instagram o Tiktok.",
  videoPresentationTooltip:
    "Sube un video para que se muestre en sus detalles. Anulará el enlace de video de arriba.",
  videoOfferTooltip:
    "Sube un video para que se muestre en los detalles de la oferta. Anulará el enlace de video de arriba.",
  occupationTooltip:
    "Cuéntale al mundo sobre su ocupación, por ejemplo, 'Consultor AR' o 'Top Tiktok Influencer'.",
  skillsTooltip:
    "Agregue sus habilidades para que sus clientes sepan en qué es bueno, por ejemplo, 'Emprendimiento' o 'Paternidad'.",
  aboutTooltip:
    "Agrega una descripción personal para que sus clientes se sientan bienvenidos y aprecien su marca personal.",
  speaksTooltip: "Especifique los idiomas que habla.",
  cropPhoto: "Recorta el foto",
  updatePhoto: "Actualiza el foto de perfil",
  crop: "Recortar",

  videoAudioSettings: "Configuración de vídeo y audio",
  videoAudioSettingsDescription:
    "Use esta configuración para ajustar el video y el audio",
  cameras: "Cámara",
  microphones: "Micrófono",
  output: "Altavoces",
  bgSettings: "Elige un fondo de vídeo",
  toStartAChat: "Iniciar un chat,",
  startAChat: "E inicia un chat.",
  messageToAnExpert: "Envía su primer mensaje a un experto",
  messageToAClient: "Envía su primer mensaje a un cliente",
  chatWindowGetToKnow:
    "1. Conoce a sus clientes potenciales y necesidades de ellos",
  requestInformation: "2. Solicite información adicional si es necesario",
  notGettingAReply:
    "3. Si no obtiene una respuesta, puede intentar hacer un seguimiento cortésmente",

  chatWindowProvideABrief:
    "1. Proporcione una breve descripción de lo que está buscando",
  chatWindowAddAttachments: "2. Agregue archivos adjuntos, si es necesario",
  describeYourIssue:
    "Describa su solicitud y lo que desea aprender o necesita ayuda, por favor. Adjunte cualquier archivo adicional si es necesario.",
  getAConfirmation: "Recibirá una confirmación del experto en breve.",
  describeTheDetails:
    "Describa los detalles, el precio y los requisitos previos de su oferta.",
  areYouSure: "¿Está seguro de que desea eliminar esta oferta?",
  offerAvailable: "Oferta disponible",
  offerUnavailable: "Oferta no disponible",
  notHaveOffers: "Aún no tienes ninguna oferta",
  createOne: "Crea uno para empezar a trabajar con sus clientes.",
  getPaid: "Se le paga el precio especificado por un periodo de tiempo.fijo",
  describeYourOffer: "Describa su oferta en breve detalle.",
  priceLimit: "El precio puede ser $0 o de ${{minPrice}} a ${{maxPrice}}.",
  priceLimitForUA: "El precio puede ser ₴0 o de ₴{{minPrice}} a ₴{{maxPrice}}.",

  offerNameDescription:
    "Prueba algo como 'Llamada de Descubrimiento' o 'Diseño Gráfico Básico' si eso es lo suyo.",
  clickingConfirm: "Al hacer clic en “Confirmar“, estoy de acuerdo con...",
  termsAndConditions: "Términos y condiciones",

  paymentFeesMayAlter: "Las comisiones del sistema de pago pueden variar.",
  notBeCharged: "Nunca se le cobrará sin confirmación adicional.",
  last: "Últimos 4",
  lastDigits: "Últimos 4 dígitos",
  billingPageName: "Nombre",
  gateway: "Puerta",
  prepareForTheMeeting: "Prepárase para la reunión",
  prepareForSession: "Prepararse para",
  findAQuiet: "Busca un lugar tranquilo donde nadie se moleste",
  makeSure: "Asegúrase de tener una buena conexión a Internet",
  weRecommendUsing: "Recomendamos el uso de auriculares",
  prepareYourQuestions: "Prepara sus preguntas",
  initialization: "Inicializando...",
  beginOnboardingAsExpert:
    "Comience a incorporarse como el experto en Waylight Business",
  connectStripeAccount:
    "Para recibir dinero de sus clientes, debe crear un método de pago.",
  provideABrief: "1. Proporcione una breve descripción de lo que está buscando",
  addAttachments: "2. Agregue archivos adjuntos, si es necesario",
  gettingAReply:
    "3. Si no recibe una respuesta, puede intentar hacer un seguimiento cortés",

  fileType: "El tipo de archivo no es compatible",
  orDragAndDrop: "O arrastra y solta",
  upTo: "hasta 1 GB (póngase en contacto con el soporte si tiene tamaños de archivo más grandes)",
  headerImage: "El Imagen de encabezamiento",
  inviteClientsModalInviteClients: "Invita los clientes",
  shareYourLink: "¡Comparte su enlace personal para invitar a sus clientes!",
  addDays: "Configura tu horario regular",
  publiclyInformation:
    "Se pueden agregar múltiples intervalos de tiempo por un solo día de la semana.",
  availableTimeslot: "Intervalo de tiempo disponible",
  AddTimeslot: "Agrega un intervalo de tiempo",
  AddToSchedule: "Añade a el horario",
  signUpAs: "Regístrase como",
  registerAsClient: "Quiero aprender algo nuevo",
  registerAsExpert: "Quiero compartir mi experiencia",
  noTimeslots:
    "No hay inervalos de tiempo disponibles. Elija otra fecha o pregunte al experto sobre el horario.",
  toastSavedSuccessfully: "Guardado correctamente",
  toastDeletedSuccessfully: "Eliminado correctamente",
  toastEmailCopied: "Correo electrónico copiado",
  toastProfileCopied: "El perfil ha sido copiado",
  toastBookingAvalable: "Ya está disponible para reservar ahora",
  toastBookingUnavalable: "No está disponible para reservar",
  toastQrCopied: "El código QR ha sido copiado",
  toastPaymentMethodAdded: "El método de pago ha sidso añadido con éxito",
  toastPaymentMethodDeleted: "El método de pago ha sido eliminado con éxito",
  toastUrlCopied: "El enlace personal ha sido copiado",
  toastOfferUrlCopied: "El enlace de oferta ha sido copiado",
  toastPayoutMethodAdded: "El método de pago ha sido agregado con éxito",
  toastPayoutMethodDeleted: "El método de pago ha sido eliminado con éxito",
  toastPrimaryCardError: "No se pudo configurar la tarjeta principal",
  toastPrimaryCardAdded: "La tarjeta principal ha sido añadida con éxito",
  toastTooLargeFile: "El tamaño del archivo es demasiado grande",
  toastOfferNotAdded: "La oferta no fue añadida.",
  toastOfferNotEdited: "La oferta no ha sido editada.",
  toastSessionLinkCopied: "El enlace de la sesión ha sido copiado",
  toastMaxParticipantsReached: "Se ha alcanzado número máximo de participantes",

  toastLinkExpired: "Este enlace ya ha caducado.",
  toastSessionExtendedSuccessfully: "La sesión se extendió con éxito",
  toastTimeslotStartAndEnd:
    "No puede agregar un intervalo de tiempo que comience y termine al mismo tiempo",
  toastTimeslotEndsInPast:
    "No puede agregar un intervalo de tiempo que termine en el pasado",
  toastTimeslotDuplicate: "No puede agregar un intervalo de tiempo duplicado",
  toastSkillNameTooLong:
    "El nombre de su habilidad es demasiado largo, intenta elegir un nombre más corto",
  buttonSave: "Ahorrar",
  buttonCancel: "Cancelar",
  buttonApprove: "Aprobar",
  buttonDelete: "Suprimir",
  buttonChat: "Charlar",
  buttonOpenChat: "Charlar",
  buttonFiles: "Archivos",
  buttonNotNow: "No ahora",
  buttonSubmit: "Enviar",
  buttonNo: "No",
  buttonEnd: "Final",
  buttonSignUp: "Inscribirse",
  buttonYes: "Sí",
  buttonEdit: "Editar",
  buttonCopy: "Copiar",
  buttonReply: "Respuestar",
  buttonAdd: "Agregar",
  buttonSend: "Enviar",
  buttonRequest: "Salicite el soporte",
  buttonSet: "Establecer",
  buttonEditPhoto: "Edita el foto",
  buttonStartAnyway: "Comience de todos modos",
  buttonWait: "Esperar",
  buttonCopyLink: "Copia el link (enlace)",
  buttonLeave: "Abandonar",
  buttonExtraMinutes: "10 minutos adicionales",
  buttonViewOffer: "Ver detalles",
  buttonMoreDetails: "Más detalles",
  buttonContinue: "Continuar",
  buttonBookACall: "Reserva una llamada",
  notificationSetup: "Configuración",
  notificationFillProfile: "Rellene el perfil",
  notificationHi: "¡Hola!",
  notificationAddYourName:
    "Añade su nombre y foto para amenizar la comunicación, si se apetece. Sin presión :)",
  notificationSetupBilling: "Configura facturación",

  notificationGoToBilling: "Vaya a Facturación",
  notificationWeAreSureYouHaveALotToSay:
    "Estamos seguros de que tiene mucho que decir sobre Usted. ¡Rellena su perfil y empieza a compartir!",
  notificationAddAnOffer: "Añade una oferta",
  notificationAddYourOffers: "Añade sus ofertas",
  notificationGoToMyOffers: "Vaya a Ofertas",
  notificationConnectStripe: "Configure los pagos",
  notificationSetupPayouts: "Paga la incorporación",
  notificationInOrderToReceiveMoney:
    "Para recibir dinero de sus clientes, debe crear un método de pago.",
  notificationGoToPayouts: "Vaya a Pagos",
  notificationSetYourSchedule: "Establece su horario",
  notificationWorkAtConvenientHours: "Trabaja en horas convenientes",
  accountOn: "Ls cuenta está activada",
  accountOff: "La cuenta está desactivada",

  paymentFailed: "El pago ha fallado",

  areYouSureCancelPaidSession:
    "¿Estas seguro que quiere cancelar? Su pago será reembolsado.",
  areYouSureCancelLessThanADay:
    "¿Seguro que quiere cancelar cuando quedan menos de 24 horas? Su pago NO será reembolsado.",
  link: "Enlace",
  view: "Vista",
  requires_action: "Requiere acción",
  succeeded: "Logrado",
  signUpTitle: "Inscribirse",
  profileTitleNotify: "Perfil | Waylight",
  dashboardTitleNotify: "Tablero | Waylight",
  chatTitleNotify: "Chatear | Waylight",
  with: "con",
  expertsTitle: "Expertos",
  expertsTitleNotify: "Explorar | Waylight",
  signUpTitleNotify: "Regístrate | Waylight",
  chatTitle: "Charlar",
  clientsTitle: "Clientela",
  clientsTitleNotify: "Clientes | Waylight",
  clientTitle: "Cliente",
  fixedPrice: "Precio fijo",
  internetConnectionLost: "Se ha perdido la conexión a Internet",
  fiveMinutesLeft: "Quedan 5 minutos",
  summarizeMeeting: "Resuma su reunión y comparta sus impresiones.",
  oopsConnectionLost: "Ups... Se ha perdido la conexión con el grupo reunión",
  partyTemporaryLostConnection:
    "Parece que su grupo ha perdido temporalmente la conexión con la reunión. Disculpen las molestias",
  leaveAReviewFor: "Deja un comentario para",
  leaveAReview: "Deja un comentario",
  review: "Revisar",
  followers: "Seguidores",
  rating: "Clasificación",
  hitStarRate: "¡Califica la sesión abajo!",
  reviewsEmpty: "Aún no hay reseñas",
  reviews: "Reseñas",

  thankYouForLighting: "¡Gracias por iluminar el camino de alguien!",
  sessionNotStarted: "La sesión no ha comenzado.",
  devicePermissionTittle:
    "Permita que Waylight acceda a su micrófono y cámara. Actualice la página si no tiene video.",
  networkConnection: "La calidad de su conexión a Internet es",
  unknown: "Desconocido",
  low: "Bajo",
  average: "Promedio",
  good: "Bueno",
  expertDidNotCome:
    "Desafortunadamente, su experto no se presentó. No se le cobrará y obtendrá un reembolso. Pedimos disculpas por las molestias.",
  noFilesAdded: "No se agregaron archivos.",
  enterYourEmail: "Ingrese su dirección de correo electrónico",
  required: "Este campo es obligatorio.",
  emailOrPassword: "El correo electrónico o la contraseña son incorrectos",
  emailInUse: "Este correo electrónico ya está en uso",
  businessIsNotAvailableTitle:
    "Waylight Business no está disponible en su país. Sé el primero en saber cuándo se lanza.",
  beInTouch: "Mantente en contacto",
  businessIsNotAvailableEmailAddress: "La dirección de correo electrónico",
  pleaseTryAgain:
    "No pudimos guardar su dirección de correo electrónico. Inténtalo de nuevo.",
  thankYou:
    "Gracias, nos pondremos en contacto tan pronto como esto se despliegue",

  yourCountry: "País de su banco",
  open: "Abierto",
  offerName: "El nombre de la oferta",
  gatewayCountryWarning:
    "Advertencia: este debe ser el país de su institución bancaria y NO se puede cambiar más tarde, lo que puede afectar su capacidad para recibir pagos.",
  addAttachment: "Añade un adjunto",
  you: "Tú",
  explore: "Explorar",
  stats: "Estadísticas",
  more: "Ver más",
  noPrice: "Ofertas gratis",
  theyWillAppear: "Aparecerán aquí cuando empiece a interactuar.",
  noExpertFoundUsingFilter: "No se encontraron expertos usando este filtro.",
  tryAnotherInput: "Pruebe con otra etiqueta o entrada.",
  noFavoriteExpertsFound: "No se encontraron expertos favoritos.",
  tryAddingSomeone: "Intenta agregar a alguien a favoritos.",
  agoraDeniedError:
    "¡Habilite su acceso al micrófono y la cámara en la configuración para usar Waylight!",
  editCover: "Edita la portada",
  language: "Idioma",
  invalid: "Inválido",

  typeHere: "Escriba aquí",
  schedule: "Horario",
  offers: "Ofertas",
  qrSharing: "Descarga y comparte su código QR.",
  qrSharingMobile:
    "Mantenga presionado el código QR para copiarlo y compartirlo.",
  preview: "Previsualizar",
  back: "Atrás (regresa)",
  love: "Hecho con amor por Waylight",
  allRightsReserved: "© 2024 Waylight Inc. Todos los derechos son reservados.",
  allRightsReservedSecond:
    "© 2022 Arceon Consulting OÜ. Todos los derechos son reservados",
  termsOfService: "Términos de servicio",
  privacyPolicy: "Política de privacidad",
  sessionSharingpopupTitle:
    "¡No se puede comenzar el uso compartido de pantalla!",
  sessionSharingpopupDescription:
    "Compruebe si tiene una versión reciente de su navegador y si tiene permisos del sistema para compartir pantalla. Por último, puede intentar actualizar la página. Si el problema persiste, póngase en contacto con el soporte.",
  sessionSharingpopupSafariDescription:
    "El uso compartido de pantalla no es compatible con su navegador. Las últimas versiones de los navegadores populares admiten esta función.",
  copy: "Copiar",
  expertWithoutOffers:
    "Este experto aún no ha agregado ninguna oferta de pago.",
  errorViewHeader: "¡Ups! Algo salió mal!",
  errorViewDescription:
    "Ha encontrado un error. Es posible que este enlace esté roto o que no tenga acceso a un elemento.",
  returnToHome: "Vuelve a casa",
  invalidURL:
    "URL está invalida. Asegúrase de que comience con ' https://' o 'http:// '",
  invalidVideoExternalURL:
    "URL de vídeo no está válida. Agregue un enlace de Youtube o Vimeo.",
  repeatedURL: "Este enlace ya ha sido agregado",
  tooLongUrl:
    "Este enlace es demasiado largo. Por favor, no utilice enlaces de más de 128 caracteres.",
  edited: "Editado",
  emptyCards: "Aún no ha añadido ninguna tarjeta.",
  emptyBillingHistory: "Aún no tienes transacciones",
  addNewSkill: "Agrega nueva habilidad",
  confirmationAddSkill:
    "¿Está seguro de que desea agregar una nueva habilidad?",
  addPaymentMethod: "Añadia el método de pago",
  stripeDescription: "Para transacciones con expertos de más de 50 países",
  fondyDescription: "Para transacciones con expertos de Ucrania",
  fondyAddCardDescription:
    "Agrega un método de pago para continuar. Se intentará realizar una carga de prueba para confirmar.",
  timeZoneChangedHeader: "Zona horaria ha cambiado",
  timeZoneSChangedDescription:
    "Hemos detectado que su zona horaria ha cambiado. ¿Quere actualizar la zona horaria de su perfil?",
  updateButton: "Actualizar",
  and: "y",
  moreFiles: "más archivos",
  addPayout: "Agrega al menos un método para recibir pagos",
  testChargeWarning:
    "Se intentará realizar una carga de prueba para confirmar.",
  payoutMethods: "Sus métodos de pago",
  identifier: "Identificador",
  stopWar: "Para la guerra",
  yourAreOnlyOneHere: "Usted es el único aquí",
  yourAreOnlyOneHereSingle:
    "Usted es el único aquí. Comparte el enlace de abajo para que otros se unan",
  clientView: "Cliente",
  businessView: "Negocio",
  mainPage: "Pagina principal",
  signUpCompleted: "Regístrase completado",
  selectPaymentInfo:
    "Los métodos de pago disponibles dependen de la puerta de enlace de su experto",
  payment: "Pagos",
  paymentWasDeclined: "El pago fue rechazado.",
  payoutWasDeclined: "El pago fue rechazado.",
  primary: "Primario",
  notUsed: "No utilizado",
  releaseBannerTitle: "Waylight ha sido actualizado a una nueva versión.",
  refresh: "Actualizar",
  country: "País",
  viewAll: "Ver todo",
  videoPresentation: "La presentación de video",
  bookFor: "Reserva para",
  bookForFree: "Reserva gratis",
  follow: "Seguir",
  followed: "Seguido",
  emptyPortfolio: "Parece que no hay archivos en este portafolio",
  media: "Medios de comunicación",
  other: "Archivos",
  emptyReview: "Parece que no hay reseñas.",
  newest: "El más nuevo",
  oldest: "El más antiguo",
  viewProfileInfo: "Ver información de perfil",
  emptyTransactions: "Parece que no hay transacciones.",
  emptyOffers: "Desafortunadamente, este experto aún no tiene ninguna oferta.",
  searchExperts: "Busca expertos",
  modifySchedule: "Modifica el horario",
  editMode: "Edita el modo",
  finishEditing: "Termina la edición",
  editPersonalInfo: "Edita la información personal",
  editPortfolio: "Edita el portafolio",

  profileId: "La identificación pública personal",
  profileSettings: "Configuración de perfil",
  editPublicID: "Edita la identificación pública",
  removeTimeslot: "Quita el intervalo de tiempo",
  areYouSureRemoveTimeslot:
    "¿Está seguro de que desea eliminar el intervalo de tiempo?",
  qrCode: "El código QR",
  communication: "Comunicación",
  editCoverDescription: "Personaliza su imagen de portada",
  editProfileData: "Edita datos de perfil",
  editProfileDataDescription:
    "Añade información sobre Usted y sus antecedentes.",
  editPublicIDDescription:
    "Elija una identificación pública única para su perfil",
  profilePhoto: "El foto de perfil",
  publicIDDescription: "¡Esta es una identificación generada automáticamente!",
  publicIDWarning:
    "No olvide cambiarlo por algo genial en el modo de edición, como “supergirl“.",
  followWarning: "El botón “Seguir“ funcionará para todos los usuarios.",
  openChatWarning: "No podrás chatear contigo mismo, pero tus clientes sí.",
  bookingOfferWarning:
    "Los usuarios podrán adquirir una oferta haciendo clic aquí.",
  shareOffer: "Copiar enlace",
  turnOffAccount: "Desactivar la cuenta",
  turnOnAccount: "Activa la cuenta",
  areYouSureYouWantTurnOff:
    "¿Está seguro de que desea desactivar completamente su cuenta?",
  areYouSureYouWantTurnOn:
    "¿Está seguro de que quieres volver a activar su cuenta?",
  disableFreeChat: "Desactiva el chat gratis",
  enableFreeChat: "Habilita el chat gratis",
  areYouSureYOuWantDisableFreeChat:
    "¿Está seguro que quieres desactivar el chat gratuito?",
  areYouSureYOuWantEnableFreeChat:
    "¿Está seguro de que desea habilitar el chat gratuito?",
  cancelEditing: "Cancela la edición",
  descriptionCancelEditing: "¿Está seguro de que desea cancelar la edición?",
  takenPublicID: "Esta identificación pública ya está en uso",
  charactersMatch: "2-64 caracteres",
  charactersBetweenMatch: "El campo debe tener de 1 a 64 caracteres",
  charactersMaxMatch: "La longitud máxima del campo es de 64 caracteres.",
  deletePhoto: "Elimina el foto",
  descriptionDeletePhoto: "¿Es seguro que quieres borrar su foto?",
  accountOnDescription:
    "Cuando la cuenta está desactivada, no está disponible y no es visible por cualquier medio (enlaces directos, resultados de búsqueda, etc.)",
  freechat: "El chat gratis",
  freechatDescription:
    "Cuando el chat gratuito esté deshabilitado, los usuarios no podrán iniciar la comunicación consigo por este medio. Sin embargo, podrá hacerlo con sus clientes.",

  editMessage: "Edita mensaje",
  replyTo: "Responde a",
  file: "File (Expediente)",

  backgroundWarning:
    "Advertencia: los fondos no son totalmente compatibles con los navegadores Safari y Firefox y es posible que no funcionen.",
  single: "Rápido",

  connectIn: "Conéctese",
  connectOn: "Continua para conectarse",
  sessionDecisionTittle: "Tu grupo no ha aparecido.",
  cancelledSessionExpertDescription:
    "El pago ha sido transferido a su cuenta. Pedimos disculpas por las molestias.",
  cancelledSessionClientDescription:
    "No se le cobrará y obtendrá un reembolso. Pedimos disculpas por las molestias.",

  addExtraMinutes: "Añade 10 minutos adicionales",

  unableAddExtraMinutes: "No se pueden agregar 10 minutos adicionales",

  monday: "Lunes",
  tuesday: "Martes",
  wednesday: "Miércoles",
  thursday: "Jueves",
  friday: "Viernes",
  saturday: "Sábado",
  sunday: "Domingo",
  h: "h",
  sharingSetting: "La configuración para compartir",
  call: "Llamar",
  public: "Público",
  byLinkOnly: "Solo por enlace",
  offerInfo: "Ofrece la información ",
  coverVideo: "Portada / vídeo",
  imageVideo: "Imagen / vídeo",
  chooseCountry: "Elige país",
  passwordChangedSuccessfully: "La contraseña ha cambiada con éxito",
  connected: "Conectado",
  passedALot: "Pasado",
  chooseCountryBank: "Elija el país de su banco o servicio financiero.",
  createAccountContinue: "Crea una cuenta para continuar",
  metasignInTitle: "Inicia la sesión | Waylight",
  metasignUpTitle: "Regístrase | Waylight",
  metapasswordForgotTitle: "He olvidado la contraseña | Waylight",
  metapasswordResetTitle: "Restablece la contraseña | Waylight",
  clientflowStep1Title:
    "Encuentra el enlace en BIO o en la búsqueda de Waylight",
  clientflowStep1:
    "Encuentra el enlace de Waylight de su creador, influencer, experto o tutor en las redes sociales (Instagram, Facebook, YouTube, LinkedIn, etc.) o escribe el nombre o nickname en la búsqueda de Waylight.",
  clientflowStep2Title:
    "Reserva una videollamada o suscríbese a un blog o chat",
  clientflowStep2:
    "Abre y explora el perfil comercial de su creador, influencer, experto o tutor. Encuentre una oferta interesante para una videollamada 1:1, resérvela y acceda a ella a la hora programada. Intente chatear después o antes para obtener más información a largo plazo. Suscríbase al blog personal solo para miembros con tutoriales, artículos, consejos en video, backstage, experiencias de vida personal, lecciones, música, etc.",
  clientflowStep3Title:
    "Verifique las calificaciones y reseñas descentralizadas",
  clientflowStep3:
    "Las calificaciones descentralizadas construyen un historial para creadores y expertos, no se pueden vender ni falsificar y crean un sentido de confianza, transparencia, prueba de experiencia y demanda de contenido para clientes y suscriptores. Deje sus calificaciones reales después de las videollamadas, los chats y las publicaciones exclusivas para miembros en los blogs. Comparta sus reseñas para ayudar a la comunidad de Waylight a destacar las mejores.",
  confirmBooking: "Confirmar selección",
  free: "Libre ",
  unableVerifyEmail: "No pudimos verificar su correo electrónico.",
  thankYouForVerifiedEmail: "¡Gracias por verificar su email!",
  reSend: "Reenviar",
  signInToContinue: "Inicia sesión para continuar",
  switchingToClientView: "Switching to client view",
  switchingToExpertView: "Switching to business view",
  cookiePermission:
    "Apreciamos su privacidad. Utilizamos cookies para mejorar su experiencia de navegación, ofrecer contenido personalizado y analizar nuestro tráfico. Al hacer clic en 'Aceptar', acepta nuestro uso de cookies.",
  readMore: "Lee más.",
  notReadableDevicesWarning:
    "Advertencia: la cámara y/o el micrófono están en uso o no se puede acceder a ellos.",
  howPayoutsWork: "¿Cómo funcionan los pagos?",
  payoutsOnboarding1: "Complete la incorporación para habilitar los pagos.",
  payoutsOnboarding2: "Mantenemos su dinero seguro hasta que suba a bordo.",
  payoutsOnboarding3:
    "Necesitará sus datos bancarios o de tarjeta en función de su país.",
  payoutsOnboarding4: "Los pagos se realizan todos los lunes.",
  payoutsOnboarding5: "Puede verificar el estado de pago en las Transacciones.",
  payoutsOnboarding6: "La pasarela de pago cobra 0.25% + $0.25 por pago.",
  payoutsOnboardingEcommpay6:
    "La pasarela de pago cobra el 0,5% por tarjetas y el 0% por comerciantes si se habilitan las tarifas del cliente.",
  payoutsOnboarding7:
    "Si es la primera vez que recibe el pago, puede tardar de 7 a 14 días hábiles en llegar debido a las limitaciones de la puerta de enlace",
  payoutsOnboarding8:
    "Normalmente, los pagos tardan unos 3 días hábiles en llegar.",
  payoutsOnboarding9:
    "Actualmente no es posible cambiar de país. Póngase en contacto con nosotros si lo necesita e intentaremos resolverlo caso por caso.",
  payoutsOnboarding10:
    "Waylight cobra una comisión variable basada en tu plan.",
  payoutsOnboarding11: "Si tienes alguna pregunta, contacta a",
  supportAlternative: "Apoyo",

  cancelledFreeSessionDescription:
    "Es la única que apareció. Disculpen las molestias.",
  m: "m",
  cityNotFound:
    "No se han encontrado opciones. Elija la ciudad grande más cercana.",
  notifications: "Notificaciones",
  telegramBot: "Bot de Telegram",
  notConnected: "no conectado",
  connectTelegramBot: "Conectar Telegram Bot",
  disconnectTelegramBot: "Desconectar Telegram Bot",
  telegramConnected: "conectado",
  bgSettingsDescription:
    "Utilice esta configuración para ajustar el fondo de su secuencia de vídeo",
  selectBackground: "Seleccionar fondo",
  bookingDelay: "Establecer un plazo de reserva",
  bookingDelayDescription:
    "Especifique con cuánta antelación se le puede reservar.",
  processingFee: "Tasa del sistema de pago",
  payoutsOnboardingEcommpay1:
    "Seleccione el método de pago de tarjeta de crédito personal o emprendimiento privado.",
  payoutsOnboardingEcommpay3:
    "Debe agregar al menos una tarjeta de crédito personal + su código tributario individual O completar el registro de comerciante de Fondy para que las transacciones funcionen.",
  payoutsOnboardingEcommpay4:
    "Los pagos se realizan después de cada transacción.",
  payoutsOnboardingEcommpay7:
    "Los pagos se realizan a más tardar al siguiente día hábil.",
  tutorials: "Productos",
  tutorialsAndCourses: "Comprar productos",
  noOffers: "Parece que aquí aún no hay ofertas",
  newTutorialOffer: "Nueva oferta de producto",
  newTutorialOfferDescription: "Crea un producto de pago o gratuito",

  tutorialName: "Nombre del producto",
  tutorialNameDescription:
    "Elige un nombre para tu producto, como “Clase de baile #1”",
  cover: "Portada",
  tutorialCoverTooltip:
    "Sube una imagen o video para mostrar en la descripción del producto.",
  getTutorialPaid: "Recibirás dinero por cada compra de este producto.",
  unlockedAfterPurchase: "Desbloqueado después de la compra",
  unlockedAfterPurchaseDescription:
    "Añade contenido que tus clientes desbloquearán.",
  tutorialDetailsDescription:
    "Añade comentarios, detalles o instrucciones aquí",
  removeSection: "Eliminar sección",
  addAnotherSection: "Añadir otra sección",
  details: "Detalles",
  toastEmptyUnlockedContent: "El contenido bloqueado no puede estar vacío",
  toastTutorialNotEdited: "Este producto no ha sido editado",
  removeSectionDescription:
    "¿Estás seguro de que deseas eliminar esta sección?",
  buyFor: "Comprar por",
  buttonOpenChatAlt: "Abrir chat",
  buttonShare: "Compartir",
  tutorial: "Producto",
  toastBuyingYourOwnTutorial: "No puedes comprar tu propio producto.",
  purchasingInProgress: "Transacción en curso",
  byClickingConfirm: "Al hacer clic",
  iAgreeWith: "estoy de acuerdo con",
  allowWaylightToCharge:
    "y permitir que Waylight cargue mi tarjeta ahora y en el futuro según los términos de la suscripción hasta que cancele.",
  paymentProcessingFees: "Pueden aplicarse tarifas de procesamiento de pagos.",
  bookingTutorialWarning:
    "Los usuarios podrán comprar un producto haciendo clic aquí.",
  editTutorials: "Editar productos",
  topReview: "Reseña principal",
  addForFree: "Añadir gratis",
  tutorialPaymentFailed:
    "Tu pago no se realizó y no pudiste comprar este producto.",
  buy: "Comprar",
  tutorialWith: "producto de",
  purchased: "Comprado",
  noTutorialsFound: "No se encontraron productos",
  noTutorialsFoundDescription:
    "Aparecerán aquí cuando compres o añadas un producto",
  tags: "Etiquetas",

  createTutorial: "Crear oferta de producto",
  createTutorialDescription: "Añade un producto de pago o gratuito.",
  requires_transfer: "Requiere capacitación",
  pending: "Esperando pago",
  requires_payout: "Esperando pago",
  requires_capture: "En espera",
  reschedule: "Reprogramar",
  rescheduleDelay: "Establecer retraso de reprogramación",

  buttonChange: "Reprogramar",
  timeslots: "Franjas horarias",
  specifyReason: "Especifica un motivo para cambiar la fecha y la hora.",

  rescheduleReason: "Motivo de la reprogramación",
  optionLink: "Agregar un enlace externo",
  optionUpload: "O cargar un archivo",
  tutorialLinkDescription: "Añade el enlace a tu producto.",
  tutorialUploadDescription:
    "Carga cualquier archivo aquí. Puedes agregar un archivo por sección.",
  bgNone: "Ninguno",
  bgLowBlur: "Desenfoque bajo",
  bgMediumBlur: "Desenfoque medio",
  bgHighBlur: "Desenfoque alto",
  bgSolidColour: "Color sólido",
  bgCustomImage: "Imagen personalizada",
  telegramNotifications: "Notificaciones de Telegram",
  connectYourTelegramAccount:
    "Conecta tu cuenta de Telegram para recibir notificaciones de Waylight.",
  buttonConnectTelegram: "Conectar Telegram",
  notificationGoToMyProfile: "Ir a mi perfil",
  setupSchedule: "Configurar horario",
  aboutTheTutorial: "Acerca del producto",
  added: "Añadido",
  orderingChangeInProgress: "Cambiando orden...",
  gloryToUkraine: "¡Gloria a Ucrania!",
  terroristStates:
    "Waylight no funciona para usuarios de países sancionados con regímenes dictatoriales y terroristas: Rusia, Irán, Corea del Norte, Siria, Cuba, Venezuela, Afganistán.",
  never: "Sin límite",
  expiration: "Límite de tiempo",
  expirationDescription:
    "Los clientes tendrán acceso a este producto durante un número determinado de días",
  daysLeft: "días restantes",
  tutorialExpired: "Tu acceso a este producto ha expirado.",
  days: "días",
  tips: "Donaciones",
  newTipOffer: "Nueva oferta de donación",
  newTipOfferDescription: "Crear una donación",
  tipName: "Nombre de la donación",
  tipNameDescription: "Elige un nombre para tu donación",
  tipCoverTooltip:
    "Carga una imagen o video que se mostrará en la descripción de la donación.",
  aboutTheTip: "Acerca de la donación",
  describeYourTip:
    "Agrega una descripción de la donación: ¿recaudación de donaciones, organización de una obra benéfica o compra de un café?",
  tipAmounts: "Agregar tres cantidades de donación",
  tipAmountsDescription:
    "Tus clientes también podrán ingresar su propio monto si lo desean.",
  bonusTipper: "(Opcional) Enviar un bono al cliente",
  bonusTipperDescription:
    "Agrega contenido o un mensaje que tus clientes recibirán como bono después de hacer una donación.",
  tipLinkDescription: "Agrega el enlace a tu bono.",
  tipMessageDescription: "Agrega aquí tu mensaje de texto.",
  toastTipNotEdited: "Esta donación no ha sido editada",
  leaveATip: "Dejar una donación",
  bookingTipWarning:
    "Los usuarios podrán dejar una donación haciendo clic aquí.",
  includesAReward: "Incluye un bono",
  selectTip: "Selecciona cuánto te gustaría donar",
  selectTipAmount: "Selecciona una cantidad para la donación",
  toastBuyingYourOwnTip: "¡No puedes comprar tu propia donación!",
  tipSuccessfullyBought: "Donación comprada exitosamente",
  tip: "Donación",
  tipPaymentFailed:
    "Tu pago no se ha realizado y no pudiste comprar esta donación.",
  purchasedOffers: "Comprado",
  noTipsFound: "No se encontraron donaciones",
  noTipsFoundDescription: "Aparecerán aquí cuando compres una donación",
  thankYouExclamation: "¡Gracias!",
  editTips: "Editar donaciones",
  tipPriceLimit: "El precio puede ser de ${{minPrice}} a ${{maxPrice}}.",
  tipPriceLimitForUA: "El precio puede ser de ₴{{minPrice}} a ₴{{maxPrice}}.",
  cardAuthorization: "Autorización de tarjeta de crédito",
  failed: "Error",
  communityName: "Nombre de la comunidad",
  communityNameDescription:
    "Elija un nombre para su comunidad, como “Mi canal exclusivo solo para suscriptores de Telegram“",
  getCommunityPaid: "Precio de suscripción",
  Period: "Periodo*",
  expirationCommunityDescription: "Establezca la duración de la suscripción",
  aboutTheCommunityOffer: "Sobre la oferta",
  TelegramChannelName: "Nombre del canal/grupo de Telegram*",
  communityTelegramNameDescription:
    "Seleccione el nombre de su canal o grupo. Si está vacío, haga clic en el icono de información para obtener instrucciones.",
  communities: "Comunidades",
  community: "Comunidad",
  subscribeFor: "Suscríbase por",
  subscribed: "Suscrito",
  unsubscribe: "Cancelar suscripción",
  unsubscribeDescription:
    "¿Está seguro de que desea cancelar la suscripción?\nSu acceso expirará el",
  subscribeDescription: "{{community}}.",
  usubscribed: "Cancelado",
  unlimited: "Ilimitado",
  subscribeAgain: "Volver a suscribirse",
  unsubscribeDescriptionModal:
    "¿Está seguro de que desea volver a suscribirse?\nSe le cobrará por el próximo período el",
  subscribe: "Suscribirse",
  currentlyMember: "Actualmente es miembro de {{title}} en Telegram.",
  notCurrentlyMember: "Actualmente no es miembro de {{title}} en Telegram.",
  newCommunityOffer: "Nueva oferta de comunidad",
  newCommunityOfferDescription: "Cree o administre una comunidad",
  editCommunities: "Editar comunidades",
  joinCommunities: "Unirse a comunidades",
  noCommunitiesAdded: "No se han agregado comunidades",
  TheyWillAppear: "Aparecerán aquí cuando crees tus ofertas.",
  createCommunity: "Crear comunidad",
  noAccessToData: "¡Sin acceso a datos! Agregue",
  noAccessToDataAsAnAdministrator:
    "como administrador a su grupo para proporcionar acceso.",
  retryPayment: "Reintentar pago",
  areYouSureYouWantToSetCard:
    "¿Está seguro de que desea establecer la tarjeta *{{last4}} como principal?",
  thingsToTry: "Cosas que intentar:",
  checkIfYouHaveSufficient:
    "Compruebe si tiene suficientes fondos en su saldo o si los pagos por Internet están habilitados.",
  selectDifferentPaymentMethod: "Seleccione un método de pago diferente.",
  set: "Establecer",
  primaryCard: "Principal",
  setPrimary: "Establecer como principal",
  inviteMember: "Invitar miembro",
  copyLink: "Copiar enlace",
  members: "Miembros",
  cancel: "Cancelar",
  actions: "ACCIONES",
  subscribedUntil: "SUSCRITO HASTA",
  waylightClients: "Cliente WAYLIGHT",
  nameMembers: "NOMBRE",
  admin: "Administrador",
  external: "Externo",
  subscriber: "Suscriptor",
  unsubscribed: "Cancelado",
  kicked: "Expulsado",
  owner: "Propietario",
  save: "Guardar",
  kickAndBan: "Expulsar",
  areYouSureYouWantToRemove:
    "¿Está seguro de que desea eliminar “{{name}}” de su canal?",
  WeHaveEncounteredAnIssue:
    "¡Hemos encontrado un problema con su pago recurrente!",
  yourPaymentHasNotGone:
    "Su pago no se ha realizado y no hemos podido volver a intentar el cargo por su suscripción.",
  gatewayFee: "Los clientes pagan las tarifas de la pasarela",
  gatewayFeeDescription:
    "Con esta configuración activada, se agregará la tarifa de la pasarela de pago a las facturas del cliente. Cuando está desactivado, la tarifa se restará de los pagos de la empresa.",
  enableGatewayFee: "¿Activar la tarifa de la pasarela del cliente?",
  areYouSureYOuWantEnableGatewayFee:
    "¿Está seguro de que desea activar la tarifa de la pasarela del cliente?",
  disableGatewayFee: "¿Desactivar la tarifa de la pasarela del cliente?",
  areYouSureYOuWantDisableGatewayFee:
    "¿Está seguro de que desea desactivar la tarifa de la pasarela del cliente? ¡La tarifa se restará de sus pagos!",
  communityTooltip:
    "¡Asegúrese de haber agregado Waylight Bot a su grupo/canal como administrador, conectado su cuenta de Telegram a Waylight y de que esta sea la única oferta para este canal!",
  communityCoverTooltip:
    "Cargue una imagen o video para que se muestre en la descripción de la comunidad.",
  setCard: "Establecer",
  expirationDateMustBeInFuture: "La fecha de vencimiento debe ser en el futuro",
  accessGranted: "Acceso concedido",
  noAccess: "Sin acceso",
  manageCommunity: "Administrar comunidad",
  manage: "Administrar",
  January: "Enero",
  February: "Febrero",
  March: "Marzo",
  April: "Abril",
  May: "Mayo",
  June: "Junio",
  July: "Julio",
  August: "Agosto",
  September: "Septiembre",
  October: "Octubre",
  November: "Noviembre",
  December: "Diciembre",
  toastBuyingYourOwnCommunity: "No puedes suscribirte a tu propia comunidad.",
  priceLimitCommunity: "El precio puede ser de ${{minPrice}} a ${{maxPrice}}.",
  priceLimitCommunityForUA:
    "El precio puede ser de ₴{{minPrice}} a ₴{{maxPrice}}.",
  toastRetryPaymentSuccess: "Tu reintento de pago fue exitoso.",
  toastCommunityLinkCopied: "Enlace de la comunidad copiado",
  communityPaymentFailed: "Pago de la comunidad fallido",
  AddExpirationDateManually: "Agregar fecha de vencimiento manualmente:",
  SetTimeFormat: "Establecer formato de hora",
  events: "Eventos",
  newEventOffer: "Nueva oferta de evento",
  newEventOfferDescription: "Crea un evento programado",
  eventName: "Nombre del evento",
  eventNameDescription: 'Elige un nombre para tu evento, como "Máster en SEO"',
  eventCoverTooltip: "Consejo de portada del evento",
  getEventPaid: "Recibes un pago por cada compra de asientos en este evento",
  aboutTheEventOffer: "Acerca del evento",
  dateAndTime: "Fecha y hora",
  clickToSetDateAndTime: "Haz clic para establecer fecha y hora",
  DescribeYourEvent: "Describe tu evento de manera breve.",
  onlineEvent: "Evento en línea",
  offlineEvent: "Evento presencial",
  eventLinkDescription:
    "Añade información sobre el lugar del evento, como Zoom o Meet",
  uploadOptional: "Subir (opcional)",
  uploadEventDetails:
    "Sube aquí cualquier archivo con detalles opcionales del evento",
  addInformationAboutLocation:
    "Añade información sobre la ubicación del evento o un enlace de mapa",
  location: "Enlace de ubicación",
  address: "Dirección",
  addressOptional: "Dirección (opcional)",
  addAddressEvent: "Añade la dirección del evento",
  AllDayEvent: "Este es un evento que dura todo el día.",
  toastBuyingYourOwnEvent: "You can not buy your own event!",
  event: "Evento",
  timeToEvent: "Tiempo para el evento",
  hoursEvent: "horas",
  seatsLeft: "asientos disponibles",
  follower: "Seguidor",
  priceLimitEventForUA: "El precio puede ser de ₴{{minPrice}} a ₴{{maxPrice}}.",
  soldOut: "¡Agotado!",
  joinEvents: "Asistir a eventos",
  noEventFound: "No se encontraron eventos",
  noEventsFoundDescription:
    "Aparecerán aquí cuando asistas al menos a un evento",
  editEvents: "Editar eventos",
  attendForfree: "Asistir gratis",
  disabled: "Desactivado",
  enabled: "Habilitado",
  personalCreditcard: "Personal (Tarjeta de crédito)",
  privateEntrepreneur: "Empresario individual (IBAN)",
  selectPayoutMethod: "Seleccionar método de pago",
  enterYourPersonalTaxCode: "Introduce tu código tributario personal*",
  createFondyMerchantAccount:
    "Crea una cuenta de comerciante en Fondy y completa el proceso de incorporación:",
  create: "Crear",
  participantsMin: "El número mínimo de participantes es 1",
  personalTaxCodeError:
    "¡El código de impuestos individual debe ser un número válido!",
  participantsError: "La cantidad de participantes debe ser un número válido!",
  peName: "Nombre de PE",
  url: "Sitio web",
  okpo: "OKPO",
  phone: "Teléfono",
  peNameTooltip:
    "El nombre oficial de tu emprendimiento privado: 'Apellido Nombre Patrónimo'.",
  urlTooltip:
    "La URL de tu negocio. Esto puede ser tu sitio web, Facebook, Instagram, etc.",
  okpoTooltip: "Código OKPO de tu emprendimiento privado.",
  phonePayoutTooltip: "Tu número de teléfono.",
  paymentsTipStripe:
    "Los pagos están siempre habilitados para usuarios de Stripe. Los pagos están disponibles después de completar el registro.",
  paymentsTipFondy1:
    "Empresarios privados: los pagos se habilitan después de crear el comerciante. Los pagos están disponibles después de completar el registro.",
  paymentsTipFondy2:
    "Tarjetas de crédito: los pagos y las transferencias están habilitados después de agregar una combinación de tarjeta de crédito privada y código fiscal individual.",
  phoneError: "El teléfono debe ser un número válido!",
  toastTaxCodeAdded:
    "Se ha añadido correctamente su código de impuestos individual.",
  toastTaxCodeError:
    "Hubo un error al intentar añadir su código de impuestos individual.",
  toastPECodeAdded:
    "Se ha creado correctamente su nuevo comerciante Fondy. ¡Revise su correo electrónico para comenzar la integración!",
  toastPECodeError:
    "Hubo un error al intentar crear su nuevo comerciante Fondy.",
  onboarding: "Onboarding",
  notCompletedOnboarding: "No completado",
  completedOnboarding: "Completado",
  completeOnboarding: "Completar Onboarding",
  onboardingTooltip:
    "Completa el proceso de onboarding en el panel de control de Fondy. Revisa tu correo electrónico para una invitación a crear una contraseña, inicia sesión en el panel de control de Fondy, carga los documentos requeridos y agrega una firma digital. Una vez aprobado, tu cuenta se activará.",
  fondyMerchantAccountDetails: "Detalles de la cuenta de comerciante de Fondy",
  eventIsLife: "El evento está en vivo",
  created: "Iniciado",
  processing: "Procesando",
  goToFondyDashboard: "Abra su panel de control de Fondy",
  youMustCompleteFondy:
    "Debe completar la incorporación a Fondy a través de la invitación por correo electrónico que recibió en:",
  chooseEventType: "Seleccione el tipo de evento",
  addException: "Agregar excepción",
  manageScheduleExceptions: "Gestionar excepciones en el horario",
  addRemoveSpecificTime:
    "Agrega o elimina un tiempo específico cuando estás disponible o no disponible.",
  exceptionType: "Tipo de excepción:",
  available: "Disponible",
  notAvailable: "No disponible",
  yes: "Sí",
  no: "No",
  removeException: "Eliminar excepción",
  removeExceptionDesc:
    "¿Estás seguro de que deseas eliminar la excepción {{time}}?",
  NoExceptionsAdded: "No se han agregado excepciones",
  availableAllDay: "Estaré disponible todo el día.",
  notAvailableAllDay: "No estaré disponible todo el día.",
  exceptionDeleteSuccessfully: "Excepción eliminada exitosamente",
  exceptionDeleteError: "No se pudo eliminar su excepción",
  exceptionSavedSuccessfully: "Excepción guardada exitosamente",
  exceptionSavedError: "No se pudo guardar su excepción",
  addJoininstructions: "Agregar instrucciones de unión",
  addJoininstructionsDesc:
    "Agrega contenido o mensaje que tus clientes verán después de la compra cuando aún no se han unido al canal",
  joinChannel: "Unirse al canal",
  communityUploadDescription: "Subir imagen o video.",
  detailsOptional: "Detalles (opcional)",
  type: "Tipo",
  youCannotAddPast:
    "No puedes agregar un intervalo de tiempo que termina en el pasado",
  Sun: "Dom",
  Mon: "Lun",
  Tue: "Mar",
  Wed: "Mié",
  Thu: "Jue",
  Fri: "Vie",
  Sat: "Sáb",
  toastEventNotEdited: "¡Se produjo un error al intentar editar tu evento!",
  refunded: "reembolsado",
  declined: "rechazado",
  verified: "verificado",
  transactionSuccessful: "Transacción exitosa",
  myTabs: "Mis pestañas",
  tabStatus: "{{tabName}} están {{status}}",
  controlOfferTabs:
    "Tipos de ofertas que los clientes pueden ver en tu página principal:",
  onlyEnglishLetters: "Solo se permiten letras y números en inglés",
  notificationCompleteOnboarding: "Complete el proceso de incorporación",
  notificationYourPaidOffersAreUnavailable:
    "Sus ofertas pagadas no están disponibles para sus clientes.",
  products: "Productos",
  bookingAvailable: "Disponibilidad de reserva",
  bookingAvailableDescription:
    "Especifica si estás disponible para reservas en general.",
  availableKey: "Disponible",
  notAvailableKey: "No disponible",
  paymentOccurs: "Pago debido cada",
  paymentOccursOneTime: "Pago único.",
  eventIsOver: "El evento ha terminado",
  integrations: "Integraciones",
  externalAnalytics: "Análisis externo",
  externalAnalyticsDescription:
    "Establezca sus ID de cuentas de análisis externo a continuación.",
  participantsManage: "Participantes",
  manageEvent: "Gestionar evento",
  noEventsAdded: "No se han añadido eventos",
  createEvent: "Crear evento",
  shareEvent: "Compartir evento",
  waylightProfile: "Perfil de Waylight",
  tickedId: "ID del boleto",
  checkIn: "Check-in",
  checkedIn: "Marcado como presente",
  notCheckedin: "No marcado como presente",
  codeIsValid: "¡El código es válido! Marcado como presente.",
  codeisInvalid: "¡Código no válido! Por favor, inténtelo de nuevo.",
  codeIsUsed: "¡Este código ya ha sido utilizado!",
  buyMore: "Comprar más",
  noParticipantsYet: "Aún no hay participantes",
  emptyDeteilsBuysTickets:
    "Aparecerán aquí cuando alguien compre boletos para su evento.",
  connectYourTelegram: "Conecta tu cuenta de Telegram para acceder al canal!",
  change: "Cambio",
  numberOfTickets: "Número de boletos:",
  selectPaymentMethodTitle: "Seleccionar método de pago:",
  or: "- o -",
  addACard: "Agregar una tarjeta",
  subscriptionLevels: "Niveles de suscripción",
  subscriptionLevelsTooltip:
    "Agrega hasta 3 niveles a la suscripción de tu comunidad. Por ejemplo: mensual, trimestral y anual.",
  levelName: "Nombre del nivel",
  starter: "Principiante",
  pro: "Profesional",
  levelDiscription: "Nombre del nivel que se muestra a los clientes",
  period: "Periodo",
  periodDiscription:
    "A los clientes se les cobrará para permanecer suscritos en este intervalo",
  priceDiscription: "Recibes pago en intervalos específicos",
  removeLevel: "Eliminar nivel",
  addAnotherLevel: "Agregar otro nivel",
  noTrial: "Sin período de prueba",
  trialDiscription:
    "Cantidad de días de acceso gratuito que obtendrán tus clientes",
  trialPeriod: "Período de prueba:",
  selectSubscription: "Seleccionar suscripción:",
  subscription: "Suscripción",
  removeLevelDescription: "¿Estás seguro de que quieres eliminar el nivel?",
  tryForFree: "Prueba gratuita",
  startTrial: "Iniciar prueba",
  edit: "Editar",
  expirationColumName: "caducidad",
  kick: "Expulsar",
  trial: "Prueba",
  notJoined: "No se ha unido",
  telegramProfile: "Perfil de Telegram",
  notAuthorized: "No autorizado",
  noMembersFoundFilter:
    "No se encontraron miembros según el filtro establecido.",
  tryADifferentFilter:
    "Prueba con un filtro diferente o una entrada de búsqueda.",
  "not joined": "No se ha unido",
  "not authorized": "No autorizado",
  membership: "Membresía",
  priceDiscriptionEvents:
    "Recibes pago por cada compra de asientos en este evento",
  fanZone: "Zona de fanáticos",
  vipZone: "Zona VIP",
  selectTickets: "Seleccionar boletos:",
  attendance: "Asistencia",
  eventLevels: "Niveles del evento",
  noTicketsSelected: "No se seleccionaron boletos",
  SetMaximumNumberOfSeats:
    "Establecer el número máximo de asientos para este nivel",
  participants: "Número de asientos",
  import: "Importar",
  export: "Exportar",
  labelNameDescription: "Seleccione una etiqueta para sus datos importados",
  hidden: "oculto",
  upload: "Subir",
  labelName: "Nombre de la etiqueta",
  fileIsRequired: "¡Debe cargar un archivo!",
  code: "código",
  level: "Nivel",
  label: "Etiqueta",
  exportFileError: "Error al exportar!",
  exportFileSuccess: "Archivo exportado con éxito.",
  importFileSuccess: "Archivo importado con éxito.",
  importFileError: "Error al importar!",
  statsSuccess: "Estadísticas de registro cargadas con éxito.",
  statsError: "Hubo un error al cargar las estadísticas de registro!",
  uploadAText: "Texto simple o archivo CSV de hasta 50 MB",
  onlyTextFilesAllowed: "Solo se permiten archivos de texto o CSV!",
  toastCommunityNotEdited: "Hubo un error al editar la comunidad",
  toastYouCanSelectOnlyImage:
    "Solo puedes seleccionar una imagen para el encabezado",
  sections: "Secciones",
  logInOrSignup: "Inicia sesión o regístrate en Waylight a continuación",
  continue: "Continuar",
  invalidEmail: "Correo email no válido",
  enterCode: "Introduce el código",
  haveSentCodeTo: "Hemos enviado un código a",
  paste: "Pegar",
  resendCodeIn: "Reenviar código... (en {{time}} segundos)",
  resendCode: "Reenviar código",
  waylight: "Waylight",
  pleaseChooseCountry:
    "Por favor, elige el país de tu banco o servicio financiero.",
  buyAccessFor: "Comprar acceso por",
  thankYouForFollowing: "¡Gracias por seguir!",
  incorrectCode: "¡Código incorrecto o caducado!",
  enterEmailToContinue: "Ingresa el correo electrónico para continuar",
  city: "Ciudad",
  social: "Social",
  earned: "total",
  priceLimitARS:
    "El precio puede ser ARS$ 0, o de ARS$ {{minPrice}} a ARS$ {{maxPrice}}.",
  priceLimitAUD:
    "El precio puede ser A$ 0, o de A$ {{minPrice}} a A$ {{maxPrice}}.",
  priceLimitEUR: "El precio puede ser €0, o de €{{minPrice}} a €{{maxPrice}}.",
  priceLimitBOB:
    "El precio puede ser Bs. 0, o de Bs. {{minPrice}} a Bs. {{maxPrice}}.",
  priceLimitBGN:
    "El precio puede ser лв 0, o de лв {{minPrice}} a лв {{maxPrice}}.",
  priceLimitCAD:
    "El precio puede ser CA$ 0, o de CA$ {{minPrice}} a CA$ {{maxPrice}}.",
  priceLimitCLP:
    "El precio puede ser CL$ 0, o de CL$ {{minPrice}} a CL$ {{maxPrice}}.",
  priceLimitCOP:
    "El precio puede ser COL$ 0, o de COL$ {{minPrice}} a COL$ {{maxPrice}}.",
  priceLimitCRC: "El precio puede ser ₡0, o de ₡{{minPrice}} a ₡{{maxPrice}}.",
  priceLimitHRK:
    "El precio puede ser kn 0, o de kn {{minPrice}} a kn {{maxPrice}}.",
  priceLimitCZK:
    "El precio puede ser Kč 0, o de Kč {{minPrice}} a Kč {{maxPrice}}.",
  priceLimitDKK:
    "El precio puede ser kr 0, o de kr {{minPrice}} a kr {{maxPrice}}.",
  priceLimitDOP:
    "El precio puede ser RD$ 0, o de RD$ {{minPrice}} a RD$ {{maxPrice}}.",
  priceLimitHKD:
    "El precio puede ser HK$ 0, o de HK$ {{minPrice}} a HK$ {{maxPrice}}.",
  priceLimitEGP:
    "El precio puede ser EGP 0, o de EGP {{minPrice}} a EGP {{maxPrice}}.",
  priceLimitHUF:
    "El precio puede ser Ft 0, o de Ft {{minPrice}} a Ft {{maxPrice}}.",
  priceLimitISK:
    "El precio puede ser kr 0, o de kr {{minPrice}} a kr {{maxPrice}}.",
  priceLimitINR: "El precio puede ser ₹0, o de ₹{{minPrice}} a ₹{{maxPrice}}.",
  priceLimitIDR:
    "El precio puede ser Rp 0, o de Rp {{minPrice}} a Rp {{maxPrice}}.",
  priceLimitILS: "El precio puede ser ₪0, o de ₪{{minPrice}} a ₪{{maxPrice}}.",
  priceLimitUYU:
    "El precio puede ser $U 0, o de $U {{minPrice}} a $U {{maxPrice}}.",
  priceLimitGBP: "El precio puede ser £0, o de £{{minPrice}} a £{{maxPrice}}.",
  priceLimitTRY: "El precio puede ser ₺0, o de ₺{{minPrice}} a ₺{{maxPrice}}.",
  priceLimitTTD:
    "El precio puede ser TT$ 0, o de TT$ {{minPrice}} a TT$ {{maxPrice}}.",
  priceLimitTHB: "El precio puede ser ฿0, o de ฿{{minPrice}} a ฿{{maxPrice}}.",
  priceLimitCHF:
    "El precio puede ser CHF 0, o de CHF {{minPrice}} a CHF {{maxPrice}}.",
  priceLimitSEK:
    "El precio puede ser kr 0, o de kr {{minPrice}} a kr {{maxPrice}}.",
  priceLimitZAR:
    "El precio puede ser R 0, o de R {{minPrice}} a R {{maxPrice}}.",
  priceLimitSGD:
    "El precio puede ser SGD$ 0, o de SGD$ {{minPrice}} a SGD$ {{maxPrice}}.",
  priceLimitSAR:
    "El precio puede ser SAR 0, o de SAR {{minPrice}} a SAR {{maxPrice}}.",
  priceLimitRON:
    "El precio puede ser lei 0, o de lei {{minPrice}} a lei {{maxPrice}}.",
  priceLimitPLN:
    "El precio puede ser zł 0, o de zł {{minPrice}} a zł {{maxPrice}}.",
  priceLimitPHP:
    "El precio puede ser ₱ 0, o de ₱ {{minPrice}} a ₱ {{maxPrice}}.",
  priceLimitPEN:
    "El precio puede ser S/. 0, o de S/. {{minPrice}} a S/. {{maxPrice}}.",
  priceLimitPYG:
    "El precio puede ser ₲ 0, o de ₲ {{minPrice}} a ₲ {{maxPrice}}.",
  priceLimitNOK:
    "El precio puede ser kr 0, o de kr {{minPrice}} a kr {{maxPrice}}.",
  priceLimitNZD:
    "El precio puede ser NZ$ 0, o de NZ$ {{minPrice}} a NZ$ {{maxPrice}}.",
  priceLimitMXN:
    "El precio puede ser MX$ 0, o de MX$ {{minPrice}} a MX$ {{maxPrice}}.",
  priceLimitKES:
    "El precio puede ser KSh 0, o de KSh {{minPrice}} a KSh {{maxPrice}}.",
  linkInBIOtool: "Herramienta Enlace en BIO",
  thatHelpsYouEarn: "¡que te ayuda a ganar!",
  earnMore: "Gana más",
  withWaylight: "con Waylight:",
  descriptionEarn: "¡Gana 10 veces más en tus ingresos de redes sociales!",
  descriptionEarnPart: "¡Inicia en solo 2 minutos!",
  earnOnPaidCommunities: "Gana en comunidades pagas.",
  createAndManage:
    "Crea y gestiona tus comunidades basadas en suscripciones en Telegram.",
  EarnPaidDigital: "Gana con productos digitales pagos.",
  hostAndManage:
    "Organiza y gestiona tus cursos, tutoriales, libros electrónicos, guías, archivos, etc.",
  earnOnline: "Gana en eventos en línea y presenciales.",
  sellTicketsAndManage:
    "Vende boletos y gestiona tus eventos grupales en línea y presenciales.",
  EarnServices: "Gana con servicios pagos.",
  sellManage: "Vende y gestiona el flujo de tus servicios.",
  earnOnlineVideoSessions: "Gana con sesiones de video en línea pagas.",
  EarnAppointments: "Gana con citas en persona pagas.",
  ScheduleSignups:
    "Establece un horario y obtén registros para tus citas individuales o grupales.",
  earnTipping: "Gana con propinas.",
  getTippingSupport:
    "Recibe propinas en apoyo a tus canales o por compartir contenido en redes sociales.",
  youWilGet: "Obtendrás",
  tools: "1. Herramientas",
  sales: "2. Ventas",
  clientsGet: "3. Clientes",
  howItWorks: "Cómo funciona",
  GetClients: "Consigue nuevas ventas y clientes",
  useWaylightGrowth:
    "Utiliza las herramientas y consejos del motor de crecimiento de Waylight para vender más y obtener nuevos clientes en las redes sociales. ",
  createWaylight: "Crear Waylight",
  creatorsExperts: "Creadores y expertos",
  interactionsMonthly: "Interacciones mensuales",
  monthOverMonth: "Crecimiento mes a mes",
  forWhom: "Para quién:",
  pricing: "Precios:",
  chooseYourPlan: "Elige tu plan",
  saveWhenChoosingYearlyPlan: "¡Ahorra más al elegir un plan anual!",
  monthlyBilling: "Facturación mensual",
  annuallyBilling: "Facturación anual",
  freePlan: "Gratis",
  startLowestCosts: "Comienza con los costos más bajos y explora Waylight",
  mo: "/mes",
  signUp: "Registrarse",
  impressionMo: "{{comission}}% de comisión por transacción.",
  basicToolsIncluded: "¡Se incluyen herramientas básicas!",
  basic: "Básico",
  getEverythingBasic:
    "Obtén todo en básico con un nivel inferior de tarifas de transacción",
  contactSales: "Contactar a ventas",
  transactionFees: "Tarifas de transacción del 2%.",
  publishingMarketplace: "Publicación en la búsqueda del mercado.",
  saveBadge: "Ahorra {{percent}}%",
  bestValue: "Mejor valor",
  GetMoreSales:
    "Obtén más ventas desde tus redes sociales sin tarifas de transacción",
  ziroTransactionFees: "0% tarifas de transacción.",
  proToolsIncluded: "¡Se incluyen herramientas profesionales!",
  customizations: "Personalizaciones.",
  verifiedSign: "Sello verificado.",
  prioritySupport: "Soporte prioritario.",
  launchExpert: "Experto en lanzamiento incluido.",
  askTelegram: "Pregunta en Telegram",
  askWhatsApp: "Pregunta en WhatsApp",
  askEmail: "Pregunta por correo electrónico",
  MakeTenX: "Haz 10 veces",
  onYourSocialMedia: "en tus redes sociales",
  revenue: "¡ingresos! 🎉",
  launchJust: "Lanza en solo",
  twoMinutes: "2 minutos!",
  product: "Producto:",
  customersDataManagement: "Gestión de datos de clientes",
  reviewsProduct: "Reseñas",
  portfolioProduct: "Portafolio",
  businessWebsite: "Sitio web empresarial",
  securePayments: "Pagos seguros",
  tipping: "Propinas",
  paidAppointments: "Citas en persona pagas",
  paidServices: "Servicios pagos",
  ticketsOnlineEvents: "Boletos para eventos en línea",
  ticketsScanner: "Escáner de boletos",
  ticketsPersonEvents: "Boletos para eventos en persona",
  paidAccessCourses: "Acceso pago a cursos",
  paidAccessDigitalProducts: "Acceso pago a productos digitales",
  paidGroupChannelAccessTelegram: "Acceso pago a grupos y canales en Telegram",
  paidSubscriptionCommunitiesTelegram:
    "Comunidades de suscripción pagas en Telegram",
  productMenuItem: "Producto",
  PricingMenuItem: "Precios",
  Creator: "Creador",
  Freelancer: "Freelancer",
  Tutor: "Tutor",
  Coach: "Entrenador",
  Influencer: "Influencer",
  ask: "Preguntar",
  anyQuestions: "cualquier pregunta aquí:",
  how: "Cómo",
  itWorks: "funciona",
  businessflowStep1new:
    "Agrega tu enlace de Waylight al BIO de tus redes sociales y comienza a involucrar a tu audiencia y clientes compartiendo historias y publicaciones al respecto.",
  businessflowStep2new:
    "Utiliza las herramientas y consejos del motor de crecimiento de Waylight para vender más y conseguir nuevos clientes en las redes sociales.",
  businessCalculatorNew: "Calculadora de ingresos de Waylight",
  howMuchMoneyNew:
    "Descubre cuánto dinero aportarían los suscriptores a tu negocio.",
  estimateNumberNew: "1. Estima la cantidad de clientes y suscriptores",
  howMuchYouChargeNew: "2. ¿Cuánto puedes cobrar por una oferta?",
  for: "Para",
  whom: "a quién",
  getEverythingBasicNew:
    "Obtén todo en el paquete básico con un nivel más bajo de tarifas de transacción",
  startLowestCostsNew: "Comienza sin costos iniciales y explora Waylight",
  GetMoreSalesNew:
    "Obtén más ventas de tus redes sociales sin tarifas de transacción",
  onYourSocialMediaNew: "en tus ingresos de las redes sociales! 🎉",
  accordionv2label0: "¿En qué países trabajas?",
  accordionv2text0:
    "Waylight Business opera en más de 55 países en 5 continentes. Puedes ponerte en contacto con nuestro soporte sobre tu país o simplemente intentar registrarte y comprobarlo. Los clientes pueden pagar por tus ofertas desde más de 150 países de todo el mundo.",
  accordionv2label1: "¿Cómo me pagan?",
  accordionv2text1:
    "Recibirás pagos en tu cuenta bancaria cada lunes. Se tarda menos de 1 minuto en conectar tu cuenta bancaria a Waylight Business.",
  accordionv2label2: "¿Qué herramientas están incluidas en el precio?",
  accordionv2label3: "¿Tienen Apple Pay, Google Pay, PayPal?",
  accordionv2text3:
    "Sí, tenemos. Lo llamamos 'Smooth Checkout'. Waylight trabaja para automatizar los flujos de ventas con tasas de conversión más altas, así que tenemos pagos con un solo clic (con PayPal en camino).",
  accordionv2label4: "¿Cómo me ayudará Waylight a ganar 10 veces más?",
  accordionv2text4:
    "Tendrás acceso al motor de crecimiento de ventas y a la academia de monetización, lo que te ayudará a vender más y obtener más clientes de tus redes sociales. Además, Waylight ya ha creado flujos y herramientas automatizadas que te ofrecen tasas de conversión más altas.",
  accordionv2label5: "¿Qué necesito para empezar?",
  accordionv2text5:
    "Es fácil 👌 Regístrate, añade tu cuenta bancaria, crea ofertas, únete a la academia de monetización, añade enlaces en las redes sociales y comienza a ganar cada vez más dinero.",
  connectWaylight: "¡Conéctate en Waylight!",
  toastTooLargeFileTwilio:
    "Este archivo es demasiado grande. El tamaño máximo del archivo es de 150 MB.",
  enableSold: "¿Activar indicadores de ventas de ofertas?",
  areYouSureYOuWantEnableSold:
    "¿Está seguro de que desea activar los indicadores de ventas de ofertas? Los valores de venta serán visibles para todos los usuarios.",
  disableSold: "¿Desactivar indicadores de ventas de ofertas?",
  areYouSureYOuWantDisableSold:
    "¿Está seguro de que desea desactivar los indicadores de ventas de ofertas?",
  soldEnabled: "Los indicadores de ventas de ofertas están habilitados",
  soldEnabledDescription:
    "Cuando esta configuración está habilitada, los valores de venta son visibles para todos los usuarios.",
  tipOrCommunitypriceLimitARS:
    "El precio puede ser de ARS$ 1879.31 a ARS$ 9396554.46.",
  tipOrCommunitypriceLimitAUD: "El precio puede ser de A$ 3.01 a A$ 15097.1 .",
  tipOrCommunitypriceLimitEUR: "El precio puede ser de €1.81 a €9070.",
  tipOrCommunitypriceLimitBOB:
    "El precio puede ser de Bs. 13.81 a Bs. 69119.48.",
  tipOrCommunitypriceLimitBGN: "El precio puede ser de лв 3.55 a лв 17750.81.",
  tipOrCommunitypriceLimitCAD:
    "El precio puede ser de CA$ 2.74 a CA$ 13718.98.",
  tipOrCommunitypriceLimitCLP:
    "El precio puede ser de CL$ 1864.56 a CL$ 9321644.2.",
  tipOrCommunitypriceLimitCOP:
    "El precio puede ser de COL$ 8072.11 a COL$ 40360576.84.",
  tipOrCommunitypriceLimitCRC: "El precio puede ser de ₡1055.02 a ₡5277582.56.",
  tipOrCommunitypriceLimitHRK: "El precio puede ser de kn 36 a kn 70472.",
  tipOrCommunitypriceLimitCZK: "El precio puede ser de Kč 45.71 a Kč 228558.",
  tipOrCommunitypriceLimitDKK: "El precio puede ser de kr 13.54 a kr 67719.",
  tipOrCommunitypriceLimitDOP: "El precio puede ser de RD$ 295 a RD$ 588928.",
  tipOrCommunitypriceLimitHKD: "El precio puede ser de HK$ 15.58 a HK$ 77915.",
  tipOrCommunitypriceLimitEGP:
    "El precio puede ser de EGP 154.75 a EGP 309501.",
  tipOrCommunitypriceLimitHUF: "El precio puede ser de Ft 716 a Ft 3579217.",
  tipOrCommunitypriceLimitISK: "El precio puede ser de kr 684 a kr 1369300.",
  tipOrCommunitypriceLimitINR: "El precio puede ser de ₹167.89 a ₹839435.",
  tipOrCommunitypriceLimitIDR:
    "El precio puede ser de Rp 78852 a Rp 157703833.",
  tipOrCommunitypriceLimitILS: "El precio puede ser de ₪19 a ₪36657.",
  tipOrCommunitypriceLimitUYU: "El precio puede ser de $U 81 a $U 402925.",
  tipOrCommunitypriceLimitGBP: "El precio puede ser de £1.56 a £7779.95.",
  tipOrCommunitypriceLimitTRY: "El precio puede ser de ₺67.06 a ₺335289.19.",
  tipOrCommunitypriceLimitTTD:
    "El precio puede ser de TT$ 13.58 a TT$ 67917.76.",
  tipOrCommunitypriceLimitTHB: "El precio puede ser de ฿69.82 a ฿349109.6.",
  tipOrCommunitypriceLimitCHF: "El precio puede ser de CHF 1.73 a CHF 8647.",
  tipOrCommunitypriceLimitSEK: "El precio puede ser de kr 53 a kr 104092.39.",
  tipOrCommunitypriceLimitZAR: "El precio puede ser de R 94 a R 186359.",
  tipOrCommunitypriceLimitSGD:
    "El precio puede ser de SGD$ 2.63 a SGD$ 13146.69.",
  tipOrCommunitypriceLimitSAR: "El precio puede ser de SAR 19 a SAR 37496.",
  tipOrCommunitypriceLimitRON:
    "El precio puede ser de lei 9.04 a lei 45196.94.",
  tipOrCommunitypriceLimitPLN: "El precio puede ser de zł 7.8 a zł 39008.41.",
  tipOrCommunitypriceLimitPHP: "El precio puede ser de ₱ 281 a ₱ 560255.",
  tipOrCommunitypriceLimitPEN: "El precio puede ser de S/. 19 a S/. 38040.",
  tipOrCommunitypriceLimitPYG: "El precio puede ser de ₲ 36387 a ₲ 72772680.",
  tipOrCommunitypriceLimitNOK: "El precio puede ser de kr 21.38 a kr 106903.",
  tipOrCommunitypriceLimitNZD: "El precio puede ser de NZ$ 3.33 a NZ$ 16646.",
  tipOrCommunitypriceLimitMXN: "El precio puede ser de MX$ 37.92 a MX$ 189595.",
  tipOrCommunitypriceLimitKES: "El precio puede ser de KSh 803 a KSh 1605000.",
  authorizeToAccess: "Debes autorizarte para acceder",
  noCommunitiesFound: "No se encontraron comunidades",
  noCommunitiesFoundDescription:
    "Aparecerán aquí cuando compres o agregues una comunidad",
  following: "Siguiendo",
  appointments: "Citas",
  share: "Compartir",
  addOffer: "Agregar oferta",
  aboutMe: "Acerca de mí",
  estimatedAmount:
    "Monto total de las transferencias exitosas realizadas a su cuenta de Stripe incorporada en su moneda predeterminada.",
  ConnectYourTelegram: "Verbinden Sie Ihr Telegram:",
  ConnectYourTelegramAccount:
    "Verbinden Sie Ihr Telegramm-Konto, um Benachrichtigungen von den Erstellern zu erhalten, denen Sie folgen.",
  connectTelegram: "Telegram verbinden",
  buyAgain: "Comprar de nuevo",
  purchases: "Comprado",
  videoGuide: "Guía en vídeo",
  businessBilling: "Facturación empresarial",
  noPaymentMethodsAdded: "No se han añadido métodos de pago",
  yourPaymentMethods: "Tus métodos de pago",
  yourPlan: "Tu plan",
  yearlyBilling: "Facturación anual",
  selectFree: "Seleccionar gratis",
  whatIncluded: "Qué está incluido",
  selectStarter: "Seleccionar básico",
  selectPro: "Seleccionar Pro",
  enterPromoCode: "Introduce el código promocional:",
  apply: "Aplicar",
  selected: "Seleccionado",
  waylightSubscription: "Suscripción Waylight",
  cashbackRefferals: "Referencias",
  referrals: "Afiliados",
  shareLink: "Compartir enlace",
  howReferralsWork: "Cómo funcionan las referencias",
  cashback: "Cashback",
  yourCashbackCode: "Tu código de cashback",
  yourCustomPartnerReferralCode:
    "Tu código de referencia de socio personalizado",
  howCashbackWork: "Cómo funciona el cashback",
  toastPromoCodeSuccess: "¡Tu código promocional se aplicó con éxito!",
  toastPromoCodeError: "¡Se produjo un error al aplicar tu código promocional!",
  enterPromoCodeSignUp: "Introduce el código promocional (opcional):",
  enterPromoCodeBtn: "+ Introducir código promocional",
  billingBusinessTooltip: "Selecciona tu plan a continuación.",
  referralTooltip: "Este es tu código de cashback único.",
  cashbackTooltip: "Este es tu código de afiliado único.",
  currentPlan: "Plan actual:",
  neverExpires: "Nunca",
  expires: "Caduca:",
  emptyBillingBusinessHistoryDescription:
    "Descripción vacía del historial de facturación empresarial",
  notificationGoToBillingBusiness:
    "¡Hemos encontrado un problema con el pago de tu suscripción! ",
  WeHaveEncounteredAnIssueSubscription:
    "Hemos encontrado un problema con el pago de tu suscripción",
  checkIfYouHaveSufficientSubscription:
    "Comprueba si tienes suficientes fondos en tu saldo o si las pagos por internet están habilitados.",
  selectDifferentPaymentMethodSubscription:
    "Selecciona un método de pago diferente.",
  referralsDescriptions1: "¡Gracias por ser nuestro valioso socio afiliado!",
  referralsDescriptions2:
    "Completa el onboarding para recibir tus pagos de afiliado. Mantenemos tu dinero seguro hasta que te incorpores.",
  referralsDescriptions3:
    "Necesitarás los detalles de tu banco o los detalles de tu tarjeta según tu país.",
  referralsDescriptions4:
    "Los pagos se realizan cada lunes. Puedes consultar el estado de los pagos en Transacciones.",
  referralsDescriptions5:
    "Tu código de afiliado proporciona descuentos adicionales para las suscripciones Waylight.",
  referralsDescriptions6:
    "Tus descuentos y tasas de participación en los ingresos están personalizados según nuestro acuerdo.",
  referralsDescriptions7:
    "Si es la primera vez que recibes el pago, puede tardar de 7 a 14 días hábiles en llegar debido a limitaciones de la pasarela. Normalmente, los pagos tardan unos 3 días hábiles en llegar.",
  cashbackDescriptions1:
    "¡Gracias por ser miembro de nuestro programa de cashback!",
  cashbackDescriptions2:
    "Completa el onboarding para recibir tus pagos de afiliado. Mantenemos tu dinero seguro hasta que te incorpores.",
  cashbackDescriptions3:
    "Necesitarás los detalles de tu banco o los detalles de tu tarjeta según tu país.",
  cashbackDescriptions4:
    "Los pagos se realizan cada lunes. Puedes consultar el estado de los pagos en Transacciones.",
  cashbackDescriptions5:
    "Comparte tu enlace y obtén una participación en los ingresos de nuevos clientes.",
  cashbackDescriptions6:
    "Su cashback es del 20 al 30% de todas las tarifas de Waylight durante 1 año (solo el 30% de las suscripciones anuales Pro).",
  cashbackDescriptions7:
    "Si es la primera vez que recibes el pago, puede tardar de 7 a 14 días hábiles en llegar debido a limitaciones de la pasarela. Normalmente, los pagos tardan unos 3 días hábiles en llegar.",
  invalidGTMiD:
    "ID de GTM no válido. Introduce solo tu ID, no el código, por ejemplo: GTM-AA11BB2.",
  invalidMetaPixelID:
    "ID de Meta Pixel no válido. Introduce solo tu ID, no el código, por ejemplo: 123456789012345.",
  invalidHotjarID:
    "ID de Hotjar no válido. Introduce solo tu ID, no el código, por ejemplo: 1112223.",
  accountCreated: "Cuenta creada",
  toastOUrlCopied: "¡Enlace de referencia copiado correctamente!",
  YouAreOnFreePlan: "Actualmente ya estás en el plan gratuito",
  referralCodeCanNotBeUsed:
    "Este código de referencia no se puede utilizar para el país de tu cuenta actual: {{country}}",
  createWaylightBusiness: "Crear negocio Waylight",
  pleaseChooseCountryBank:
    "Por favor, elige el país de tu banco o servicio financiero:",
  referralsDescriptionsUA4:
    "Los pagos se realizan después de cada transacción. Puedes consultar el estado de los pagos en Transacciones.",
  referralsDescriptionsUA7:
    "Los pagos se realizan a más tardar al siguiente día hábil.",
  cashbackDescriptionsUA4:
    "Los pagos se realizan después de cada transacción. Puedes consultar el estado de los pagos en Transacciones.",
  cashbackDescriptionsUA7:
    "Los pagos se realizan a más tardar al siguiente día hábil.",
  issueSubscriptionPayment:
    "¡Hemos encontrado un problema con el pago de tu suscripción!",
  revenueSharhingSelectPE:
    "El programa de referidos solo está disponible para cuentas PE activadas en Ucrania.",
  youCanChangeYourAccount:
    "Puedes cambiar el tipo de tu cuenta en la configuración.",
  yourReferralCodeWillAppear:
    "Tu código de referencia aparecerá aquí una vez que completes el onboarding de PE.",
  goToPayoutSettings: "Ir a configuración de pagos",
  commission: "Comisión",
  youAreOnSamePlan:
    "¡Actualmente estás en el mismo plan que estás intentando elegir! Por favor, elige otro plan.",
  onlyCyrillicLettersAllowed: "Solo se permiten letras cirílicas",
  dragToChangePosition: "Arrastre para cambiar la posición",
  eventsQrcode: "Códigos cortos de eventos",
  eventsQrcodeDescription:
    "Cuando esta configuración está activada, sus entradas para eventos se generarán con PINs cortos de 4 dígitos y códigos QR. Cuando está desactivada, se generarán PINs más seguros de 8 dígitos con códigos de barras.",
  areYouSureYOuWantEnableQrcode:
    "¿Está seguro de que desea habilitar PINs cortos de eventos con códigos QR?",
  areYouSureYOuWantDisableQrcode:
    "¿Está seguro de que desea deshabilitar los PINs cortos de eventos y generar códigos de barras en su lugar?",
  enableEventsQrcode: "Activar códigos cortos de eventos",
  disableEventsQrcode:
    "Desactivar códigos cortos y cambiar a códigos de barras",
  authorizeScanner: "Autorizar escáner",
  toastScannerUrlCopied: "URL del escáner copiada",
  total: "Total:",
  gtmIdDescription: "GTM local para esta oferta.",
  metaPixelIdDescription: "Meta Pixel local para esta oferta.",
  tutorialBoughtPreview:
    "Esta es la vista previa del estado comprado de tu producto.",
  tutorialNotBoughtPreview:
    "Así es como los clientes ven tu producto cuando no está comprado.",
  authorizeToAccessPurchases:
    "¡Por favor, autorice para acceder a sus compras!",
  myPromotions: "Mi código promocional",

  describeTheDetailsPromotions:
    "Seleccione oferta, especifique el nombre, elija una URL y agregue los detalles de la promoción a continuación",
  promotionDropdownDescription:
    "Seleccione su oferta de la lista. No podrá cambiarla más tarde para esta promoción.",
  offerNamePromotions: "Oferta",
  promotionsTooltip:
    "Seleccione una oferta para la cual se aplicará esta promoción. Puede crear tantas otras promociones para esta oferta como desee.",
  offerNameDescriptionPromotions:
    "Ingrese el nombre de la promoción. Esto es solo para sus propósitos internos.",
  codePromotionsDescription:
    "Ingrese un código promocional único usando letras y números en inglés. ¡Pruebe el botón mágico de generación si lo desea!",
  expirationPromotions: "Vencimiento",
  name: "Nombre",
  codePromotions: "Código",
  usersNumDescription:
    "Este código promocional puede ser usado una cantidad específica de veces.",
  max_uses: "Número de usos",
  pricingPromotionsDiscription: "pricingPromotionsDiscription",
  discount: "Descuento",
  discountTitle: "Descuento(s):",
  selectOfferDiscounts:
    "Seleccione la oferta arriba para comenzar a gestionar descuentos para ella.",
  promoNameCanNotBeEmpty: "El nombre de la promoción no puede estar vacío",
  urlIsAlreadyTaken: "¡Esta URL ya está tomada!",
  promoCodeValidation:
    "El código promocional solo puede contener letras mayúsculas y minúsculas en inglés y números del 0 al 9!",
  promotions: "Promociones",
  enterPromoCodePromotions: "Ingrese código promocional",
  promoCodeIncorrectExpired:
    "¡Este código promocional es incorrecto o ha expirado!",
  selectedPromoCodeNotAvailable:
    "¡El código promocional seleccionado no está disponible!",
  weWereUnableToProcessYourTransaction:
    "No pudimos procesar su transacción con el código promocional seleccionado debido a vencimiento, límite de uso o no disponibilidad. Por favor, inicie su compra nuevamente.",
  promotionsEmptyStateHeader:
    "No se encontraron promociones según el filtro establecido.",
  promotionsEmptyStateDescription:
    "Cree promociones, intente con un filtro diferente o una búsqueda.",
  toastPromotionUrlCopied: "¡URL de la promoción copiada!",
  toastPromotionCodeCopied: "¡Código promocional copiado!",
  managePromotionsForYourOffers: "Gestione promociones para sus ofertas",
  offerPromotions: "Promociones de oferta",
  deletePromotionDescription:
    "¿Está seguro de que desea eliminar la promoción?",
  deletePromotionTitle: "Eliminar promoción",
  addPromotion: "Agregar promoción",
  toastPromotionNotEditedEnabled: "¡No pudimos modificar su promoción!",
  toastPromotionNotEdited: "¡No pudimos modificar su promoción!",
  usage: "Usado",
  discountedPriceError:
    "¡El precio con descuento no puede ser inferior al equivalente de 2 USD!",
  priceWithDiscount: "Precio con descuento",
  promoCodeAddedToast: "¡Código promocional aplicado con éxito!",
  toastPromotionSavedSuccessfully: "Código promocional creado con éxito",
  toastPromotiomNotAdded: "¡Hubo un error al crear su promoción!",
  myPromotionsMenu: "Mis promociones",
  setSubscriptionlevelManually: "Establecer nivel de suscripción manualmente:",
  settingLevelManuallyNotifyClientAutomatically:
    "Configurar un nivel manualmente notificará al cliente automáticamente y se aplicará con el próximo cargo en {{expiration}}",
  subscribeForFree: "Suscribirse gratis",
  toastSavedSuccessfullyUpdatedLevelCommunity:
    "Nivel de comunidad guardado y actualizado con éxito.",
  toastCommunityLevelNotUpdated: "Nivel de comunidad no actualizado.",
  creditCard: "Tarjeta de crédito",
  addNewCardStripe: "Agregar nueva tarjeta (Stripe):",
  promocode: "Promocode",
  nonFollowers: "No seguidores",
  dueOn: "Para el",
  exportMyClientsSuccess: "Exportación de clientes completada con éxito.",
  exportMyClientsError: "Error durante la exportación de clientes.",
  eventTelegramDescription:
    "Seleccione el nombre de su canal o grupo. Si no ve nada aquí, asegúrese de haber agregado Waylight Bot a su grupo o canal como administrador y esta es la única oferta para este canal!",
  onlineTelegramEvent: "Evento de telegrama en línea",
  joinEvent: "Unirse al evento",
  levels: "Niveles",
  paidAccess: "Acceso pagado",
  clientWillPayOnceAccess: "El cliente pagará una vez para obtener acceso",
  noOffersFound: "No se encontraron ofertas",
  noOffersFoundDescription:
    "Aparecerán aquí cuando compres o agregues una oferta",
  youCanNotManageEnabled:
    "No tienes ninguna oferta habilitada que puedas gestionar.",
  youGetPaidSpecifiedPriceForFixedAmountTime:
    "Recibes el precio especificado por un tiempo fijo.",
  videoCallQuick: "Videollamada",
  tabs: "Pestañas",
  disconnectWhatsAppBot: "Desconectar WhatsApp-bot",
  connectWhatsAppBot: "Conectar WhatsApp-bot",
  WhatsAppBot: "WhatsApp-bot",
  videoCallSession: "Videollamada",
  offerCardSize: "Tamaño de la tarjeta de oferta",
  large: "Grande",
  small: "Pequeño",
  chooseCardSizeOffer:
    "Elige el tamaño de la tarjeta para tu oferta en la lista",
  copyProfileLink: "Copiar enlace de perfil",
  profileQRCode: "Mi código QR de perfil",
  copyCommunitiesLink: "Enlace a comunidades",
  copyEventsLink: "Enlace a eventos",
  copyProductsLink: "Enlace a productos",
  copySessionsLink: "Enlace de citas",
  copyTipsLink: "Enlace a consejos",
  copyPortfolioLink: "Enlace a portafolio",
  copyReviewsLink: "Enlace a reseñas",
  leaveReviewForMe: "Deja una reseña para mí",
  shareYourWaylight: "Comparte tu Waylight",
  myProfileQRCode: "Mi código QR de perfil",
  menu: "Menú",
  selectOfferExportData: "Selecciona una oferta para exportar datos",
  setCancellationDelay: "Establecer retraso de cancelación",
  approvalRequired: "Aprobación requerida",
  cancellationFailed: "Cancelación fallida",
  youUnableCancelDesctiption:
    "No puedes cancelar una cita dentro de las {{cancelDelay}} horas antes de que comience. Contacta al propietario de la cita para más detalles.",
  rescheduleFailed: "Reprogramación fallida",
  unableRescheduleDescription:
    "No puedes reprogramar una cita dentro de las {{rescheduleDelay}} horas antes de que comience. Contacta al propietario de la cita para más detalles.",
  errorRefundDescription:
    "Algo salió mal al cancelar la cita. Por favor contacta a ",
  refundFailed: "Reembolso fallido",
  appointment: "Cita",
  addInformationAboutAppointment:
    "Agrega información sobre la cita que tus clientes desbloquearán.",
  addMapLink: "Agregar enlace del mapa",
  addAddressAppointment: "Agregar dirección de la cita",
  uploadFileOptionalAppointmentDetails:
    "Carga cualquier archivo con detalles opcionales de la cita",
  locationOptional: "Enlace de ubicación (opcional)",
  appointmentName: "Nombre de la cita",
  appointmentErrorName: "El nombre de la cita no puede estar vacío",
  upcomingIn: "Próximo en ",
  joinVideoCall: "Unirse a la videollamada",
  cancelAppointment: "Cancelar cita",
  areYouSureWantCancelClient:
    "¿Estás seguro de que quieres cancelar? Tu pago será reembolsado en 5-10 días. Es posible que las tarifas de la pasarela de pago no sean reembolsadas.",
  areYouSureWantCancelExpert:
    "¿Estás seguro de que quieres cancelar? El pago de tu cliente será reembolsado en 5-10 días. Es posible que las tarifas de la pasarela de pago no sean reembolsadas.",
  aboutAppointment: "Acerca de la oferta",
  finishAppointmentTitle: "¿Estás seguro de que deseas finalizar la cita?",
  finishAppointmentDescription:
    "El estado de tu cita se cambiará a ‘finalizada’ y terminará.",
  newAppointmentOffer: "Nueva oferta de cita",
  editAppointments: "Editar citas",
  cancellationReason: "Motivo de cancelación",
  aboutAppointmentCreatUpdate: "Acerca de la cita",
  totalSessions: "Total de citas",
  yourSession: "Tu cita",
  notHaveUpcomingSessions: "Aún no tienes citas próximas",
  notHavePastSessions: "Aún no tienes citas pasadas",
  notHaveQuickSessions: "Aún no has comenzado citas rápidas",
  upcomingSessions: "Citas próximas",
  session: "Cita",
  sessionWith: "cita con",
  requestSession: "Solicitar cita",
  goToSession: "Ir a la cita",
  sessions: "Citas",
  declineSession: "Rechazar cita",
  wantDeclineSession: "¿Estás seguro de que deseas rechazar esta cita?",
  endSession: "Terminar cita",
  areYouSureYouWantEndSession:
    "¿Estás seguro de que deseas terminar esta cita?",
  aboutOffer: "Acerca de la cita",
  aboutTheOffer: "Acerca de la cita",
  noTransactionsDescription:
    "Aparecerán una vez que tengas citas pagadas exitosas",
  sessionsPassed: "Citas pasadas",
  upcomingSession: "Cita próxima",
  bookSession: "Reservar cita",
  sessionsManagement: "Gestión de citas",
  hadNoSessions: "No hay citas esta semana",
  bookASession: "Cuando reserves una cita, aparecerá aquí",
  knowEachOther: "4. Conózcanse y planifiquen sus citas",
  describeRequirements:
    "Describe los requisitos para los clientes antes de unirse a las citas: mensaje o archivos adjuntos.",
  preCharge: "Se aplicará una pre-carga para solicitar tu cita.",
  fewRecomendations:
    "Algunas recomendaciones para que tu cita sea cómoda y productiva.",
  enjoyTheSession: "¡Disfruta la cita!",
  getToKnow: "4. Conózcanse y planifiquen sus citas",
  approveSession: "Aprobar cita",
  approveThisSession:
    "¿Estás seguro de que deseas aceptar esta cita? Al aprobar, aceptas unirte en la hora especificada.",
  toastSessionCanceled:
    "Esta cita ha sido cancelada debido a limitaciones de tiempo",
  toastSessionWithYourself: "¡No puedes reservar una cita contigo mismo!",
  toastNotMayJoin:
    "Lamentablemente, no puedes unirte a esta cita ya que ha alcanzado su capacidad.",
  toastCannotStartSession: "No se puede iniciar cita rápida ahora",
  notificationAddPaymentMethod:
    "Agrega un método de pago para poder reservar citas.",
  notificationSetupVariousKindsOfSessions:
    "Configura varios tipos de citas para tus clientes, que estarán disponibles para reservar en tu perfil.",
  notificationSetupSchedule:
    "Configura un horario y tus clientes solo podrán reservar citas según este.",
  cancelSession: "Cancelar cita",
  paymentFailedDescription:
    "Tu pago no ha sido procesado y no pudimos solicitar una nueva cita.",
  areYouSureCancel: "¿Estás seguro de que deseas cancelar esta cita?",
  sessionEnded: "Cita terminada con éxito",
  wereNoChatMessages: "No hubo mensajes de chat en esta cita",
  scrollPastHistory: "Desplázate por el historial de tu cita pasada arriba",
  earnedFromLastSession: "ganado en la última cita",
  emptyBillingHistoryDescription:
    "Aparecerán aquí cuando comiences a reservar citas",
  editOffers: "Editar citas",
  freeChatWarning:
    "Esta cuenta ha deshabilitado el chat gratuito. Puedes solicitar una cita para comunicarte.",
  unableToJoinSession: "No se puede unirse a la cita",
  scheduleSession: "Programa una cita con un experto o creador.",
  startSessionImmediately:
    "Inicia una cita gratuita inmediatamente. Invita a otros directamente con un enlace.",
  quickSessionWarningTittle: "¿Estás seguro de que deseas iniciar la cita?",
  quickSessionWarningDescription:
    "Tienes una cita programada que se superpone con la cita rápida que estás intentando iniciar.",
  expertSessionDecision:
    "¿Te gustaría terminar la cita o esperar más tiempo? Terminar la cita resultará en la transferencia del pago a tu cuenta.",
  clientSessionDecision:
    "¿Te gustaría terminar la cita o esperar más tiempo? Terminar la cita reembolsará tu pago.",
  cancelledSessionTittle: "Esta cita ha sido cancelada",
  areYouSureYouWantLeaveSession:
    "¿Estás seguro de que deseas salir de la cita?",
  areYouSureYouWantLeaveSessionDescription:
    "Podrás regresar a la cita hasta que termine.",
  addExtraMinutesDescription:
    "¿Estás seguro de que deseas agregar 10 minutos adicionales a esta cita?",
  unableAddExtraMinutesDescription:
    "Lamentablemente, tienes una cita programada en menos de 10 minutos.",
  freeSessionDecision: "¿Te gustaría terminar la cita o esperar más tiempo?",
  newSessionOffer: "Nueva oferta de cita",
  newSessionOfferDescription: "Crea una cita de video paga o gratuita",
  sessionName: "Nombre de la cita",
  createSessionOffer: "Crear oferta de cita",
  createSessionOfferDescription: "Agrega una cita de video paga o gratuita.",
  rescheduleDelayDescription:
    "Especifica con cuánta antelación se pueden reprogramar tus citas.",
  rescheduleSession: "Reprogramar cita",
  rescheduleSessionDescription:
    "¿Estás seguro de que deseas reprogramar la cita?",
  cannotRescheduleSession: "No se puede reprogramar la cita",
  ScheduleBookingsOnlineVideo:
    "Configura un horario y recibe reservas para citas de video en línea con tus clientes.",
  paidVideoSessions: "Citas de video en línea pagadas",
  businessflowStep3new:
    "Obtén pagos por tus comunidades, productos digitales, eventos en línea y fuera de línea, propinas, citas presenciales y de video, etc.",
  accordionv2text2:
    "Depende del plan que elijas, pero en cualquier caso tendrás acceso a los flujos de negocio de monetización completos de Waylight, como comunidades pagas en Telegram, boletos, productos digitales, cursos, citas, citas de video, propinas, sitio web comercial, datos de clientes, motores de ventas, academia de monetización, etc.",
  enableOffersForManagement:
    "Activa citas, eventos o comunidades para gestionarlos aquí.",
  specifyQuickSessionPriceDuration:
    "Especifica el precio y la duración de la cita rápida",
  pleaseEnterEmailAccessQuickSession:
    "Por favor, ingresa tu correo electrónico para acceder a la cita rápida",
  sessionRescheduledByHost: "Tu cita ha sido reprogramada por el anfitrión",
  active: "Activo",
  headline: "Titular",
  userName: "Nombre de usuario",
  turnOf: "Apagar",
  promotion: "Promoción",
  qRCode: "Código QR",
  timeframe: "Plazo",
  youAreInPreviewMode:
    "Estás en modo de vista previa. Para interactuar con el perfil, haz clic abajo para salir de este modo.",
  backToMyProfile: "Volver a mi perfil",
  previewMode: "Modo de vista previa",
  editMenu: "Editar menú",
  createMenu: "Crear menú",
  digitalProduct: "Producto digital",
  buyTicketsHere: "Compra entradas aquí",
  unsubscribedSuccessfully: "Cancelación de suscripción exitosa",
  subscribedSuccessfully: "Suscripción exitosa",
  errorWhileSubscribing: "Error al suscribirse",
  errorWhileUnsubscribing: "Error al cancelar la suscripción",
  add: "Agregar",
  bio: "Biografía",
  bioTooLong: "Por favor, limita tu biografía a 300 caracteres",
  publicIdNotAllowed:
    "Este ID público no se puede crear. Por favor, elige otro.",
  telegram: "Telegram",
  whatsApp: "WhatsApp",
  email: "Correo electrónico",
  instagram: "Instagram",
  less: "Menos",
  moreText: "Más",
  buyAccessForFree: "Obtener acceso gratis",
  verifyClientLink: "Verifique su enlace de cliente",
  toastCopiedSuccessfully: "Copiado con éxito",
};
