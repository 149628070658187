export const cs = {
  next: "Další",
  youWontBeCharged:
    "Nebude vám nic účtováno dokud nepotvrdíte podrobnosti seance",
  home: "Domu",
  chats: "Chaty",
  ratings: "Hodnocení",
  settings: "Nastavení",
  moreSettings: "Více nastavení",
  settingsNotify: "Nastavení | Waylight",
  logOut: "Odhlásit se",
  changePassword: "Změnit heslo",
  receiveResetPassword:
    "Kliknutím na „Resetovat“ níže obdržíte odkaz pro obnovení hesla.",
  enterEmailForResetPassword:
    "Zadejte svůj email a získejte odkaz pro obnovení hesla.",
  successResetPassword: "Email pro obnovení hesla odeslán!",
  failResetPassword: "Obnovení hesla se nezdařilo",
  reset: "Resetovat",
  signOut: "Odhlásit se",
  availableBooking: "K dispozici pro rezervaci",
  unavailableBooking: "Nelze rezervovat",
  shareProfile: "Sdílet profil",
  requests: "Žádosti",
  hours: "hodiny",
  totalSessions: "Celkový počet relací",
  totalEarned: "Celkem vyděláno",
  totalHours: "Celkový počet hodin",
  yourSession: "Vaše seance",
  runningNow: "nyní běží",
  notHaveUpcomingSessions: "Zatím nemáte žádné nadcházející seance",
  notHavePastSessions: "Zatím nemáte žádné minulé seance",
  notHaveQuickSessions: "Ještě jste nezahájili žádné rychlé seance",
  upcomingSessions: "Nadcházející seance",
  offer: "Nabídka",
  gallery: "Galerie",
  photo: "Fotografie",
  session: "Seance",
  sessionWith: "seance s",
  occupation: "Obsazeno",
  skills: "Dovednosti",
  experts: "Experti",
  noInformation: "Žádné informace",
  speaks: "Mluví",
  socialLinks: "Sociální odkazy",
  requestSession: "Žádost o seanci",
  contacts: "Kontakty",
  noOffersYet: "Nejsou k dispozici žádné nabídky",
  all: "Všechno",
  requested: "Vyžádáno",
  confirmed: "Potvrzeno",
  passed: "Prošel",
  joined: "Připojeno",
  ongoing: "Pokračující",
  canceled: "Zrušeno",
  rejected: "Odmítnuto",
  approved: "Schválený",
  calendar: "Kalendář",
  favorites: "Následoval",
  removeAll: "Odebrat všechny",
  viewProfile: "Prohlédnout profil",
  today: "Dnes",
  upcoming: "Nadcházející",
  attachments: "Přílohy",
  goToSession: "Přejít na seance",
  prepare: "Připravit",
  sessions: "Seance",
  prerequisites: "Předpoklady",
  download: "Stáhnout",
  past: "Minulý",
  decline: "Pokles",
  accept: "Akceptovat",
  declineSession: "Odmítnout seanci",
  wantDeclineSession: "Opravdu chcete tuto seanci odmítnout?",
  wantDeclinePaidSession:
    "Opravdu chcete žádost odmítnout? Platba vašeho klienta bude vrácena.",
  wantDeclineAfterApprove:
    "Opravdu chcete zrušit? Platba vašeho klienta bude vrácena.",
  videoCall: "Spustit stream",
  endSession: "Ukončete seanci",
  areYouSureYouWantEndSession: "Opravdu chcete ukončit tuto seanci?",
  findExpert: "Najděte experta",
  selectAnExpert: "vybrat experta",
  inviteClients: "pozvat své klienty",
  selectAChat: "Vyberte chat a zahajte komunikaci",
  noChatsYet: "Zatím žádné chaty",
  notHaveMessages: "Zatím nemáte žádné zprávy",
  shareProfileToClient: "Sdílejte profil klientovi",
  remove: "Odstranit",
  time: "Čas",
  timeSlots: "Časové úseky",
  deleteOffer: "Smazat nabídku",
  myOffers: "Nabídky",
  aboutOffer: "O seance",
  createNewOffer: "Vytvořte novou nabídku",
  editOffer: "Upravit nabídku",
  successfulyBooked: "Seance byla úspěšně zarezervována",
  close: "Zavřít",
  account: "Účet",
  price: "Cena",
  priceAndLength: "Cena a délka",
  aboutTheOffer: "O seance",
  uploadAFile: "Nahrát soubor",
  uploadAFileError: "Soubor nebyl nahrán",
  confirm: "Potvrdit",
  IDLogin: "Tvůj email",
  myProfile: "Profil klienta",
  transactions: "Transakce",
  transactionHistory: "Historie transakcí",
  noTransactions: "Zatím nemáte žádné transakce",
  noTransactionsDescription:
    "Objeví se, jakmile získáte úspěšné zaplacené seance",
  typicalSchedule: "Typický rozvrh",
  amount: "Množství",
  status: "Status",
  paid: "Vypláceno",
  action: "Akce",
  description: "Popis",
  billing: "Fakturace",
  card: "Karta",
  clients: "Klienti",
  sessionsPassed: "Uskutečněné seance",
  payout: "Platby",
  firstName: "Jméno",
  lastName: "Příjmení",
  skip: "Přeskočit",
  connect: "Připojit",
  about: "O",
  title: "Titul",
  languages: "Jazyky",
  currency: "Měna",
  addYourOffer: "Přidejte svou nabídku",
  join: "Připojit",
  joinIn: "Připojit se",
  joinOn: "Připojte se",
  message: "Zpráva",
  client: "Klient",
  expert: "Expert",
  Expert: "Expert",
  noMoreOptions: "Žádné další možnosti",
  male: "mužský",
  female: "ženský",
  custom: "Zvyk",
  copyYourLink: "Zkopírujte svůj odkaz",
  done: "Hotovo",
  search: "Vyhledávání",
  searchWaylight: "Najděte svůj Waylight",
  suggested: "Doporučeno",
  upcomingSession: "Nadcházející seance",
  startASession: "Rychlé seance",
  quickSession: "Rychlé seance",
  rate: "Hodnotit",
  perHour: "za hodinu",
  packages: "Balíčky",
  timezone: "Časové pásmo",
  book: "Rezervovat",
  favorite: "Oblíbený",
  lastSession: "Poslední interakce",
  ago: "před",
  gender: "Pohlaví",
  loading: "načítá se ",
  min: "min",
  hour: "hodina",
  online: "Online",
  offline: "Offline",
  date: "datum",
  displayName: "Zobrazované jméno",
  closePanel: "Zavřít panel",
  goToStripe: "Otevřete hlavní panel plateb",
  completeStripeOnBoarding: "Dokončete registraci Stripe Express",
  stripeOnBoarding: "Stripe registrace",
  completed: "dokončeno",
  notCompleted: "nedokončeno",
  myClients: "Moji klienti",
  findClients: "Nemůžeme najít klienty",
  clientDisplayedHere: "Když najdete klienty, zobrazí se zde",
  writeSomething: "Zadejte něco a stisknutím klávesy Enter vytvoříte položku",
  bookSession: "Objednat seanci",
  registerAs: "Zaregistrujte se jako:",
  addedCards: "Vaše karty",
  cardType: "Typ karty",
  billingHistory: "Fakturační historie ",
  deleteCard: "Smazat kartu",
  wantToDeleteCard: "Opravdu chcete kartu smazat?",
  selectPaymentMethod: "Vyberte způsob platby",
  addNewCard: "Přidat novou kartu",
  emailAddress: "Veřejný email",
  phoneNumber: "Telefonní číslo",
  publicPhoneNumber: "Veřejné telefonní číslo",
  username: "Uživatelské jméno",
  socialProfilesOrLinks: "Sociální profily / Jiné odkazy",
  newOffer: "Nová nabídka",
  myExperts: "Mojí experti ",
  myClientProfile: "Můj profil klienta",
  noExpertsFound: "Nenašli se žádní experti",
  openMenu: "Otevřít nabídku",
  becomeAnExpert: "Připojte se k Waylight Business",
  switchToExpert: "Přepnout na obchodní zobrazení",
  switchToClient: "Přepnout do zobrazení klienta",
  notHaveExperts: "Ještě nemáte žádné experty",
  endCall: "Zastavit stream",
  endSharing: "Přestat sdílet",
  lastOnline: "Poslední on-line",
  clientSince: "Klient od",
  welcome: "Vítejte",
  messageError: "Povinné pole",
  support: "Podpěra",
  profile: "Profil",
  portfolio: "Portfolio",
  publicId: "Veřejné ID",
  files: "Soubory",
  reason: "Důvod",
  declineReason: "Dejte svému klientovi vědět, proč musíte odmítnout",
  rejectionReason: "Důvod odmítnuti",
  business: "Business",
  passwordResetInvalidEmail: "email je neplatný",
  logIn: "Přihlásit se",
  logInSubtitle:
    "Chcete-li provést jakékoli akce, přihlaste se nebo se zaregistrujte.",
  enterEmail: "Zadejte email",
  enterPassword: "Heslo",
  capsLockIsOn: "Caps Lock je zapnutý",
  numLockIsOn: "Num Lock je zapnutý",
  forgotPassword: "Zapomněli jste heslo?",
  forgotPasswordEmail:
    "Zadejte svou emailovou adresu pro získání odkazu pro obnovení hesla.",
  requestPasswordReset: "Požádat o obnovení hesla",
  requestResetLink: "Znovu odeslat odkaz",
  in: "v",
  sec: "sek",
  passwordResetRequestReceived: "Požadavek na obnovení hesla byl přijat",
  passwordResetIfAccountExist:
    "Pokud účet existuje, brzy obdržíte email s odkazem.",
  resetPassword: "Obnovit heslo",
  passwordChangeSuccessful: "Úspěšná změna hesla",
  passwordChangeSuccessfulSubtitle:
    "Nyní se můžete ke svému účtu přihlásit pomocí nového hesla.",
  setNewPassword: "Nastavte nové heslo pro přihlášení ke svému účtu",
  passwordChange: "Změnit heslo",
  passwordOld: "Staré heslo",
  passwordNew: "Nové heslo",
  dontHaveAccount: "Nemáte účet?",
  alreadyRegistered: "Máte již účet?",
  tokenExpired: "Platnost tokenu vypršela",
  tokenExpiredDescription:
    "Platnost tohoto tokenu vypršela nebo byl dříve použit",
  validLength: "8 nebo více znaků",
  upperCase: "Velká písmena (AZ)",
  lowerCase: "malá písmena (az)",
  numbersOrSymbols: "Čísla nebo symboly (0-9!@#$%^&*)",
  joinFree: "Přidej zdarma",
  businessTitle: "Waylight | Nástroj Link in BIO, který vám pomáhá vydělávat!",
  businessDescription:
    "Vydělejte 10x více na svých sociálních médiích! Spusťte se za pouhé 2 minuty!",

  spotlightYourExperience:
    "Efektivní nástroje pro vaše zdroje příjmů: kontent, expertiza, komunita",
  monetizationPlatform: "Online Business Studio",
  createAccount: "Vytvořit účet",
  watchVideo: "Sledovat video",
  bestToolsAvailable:
    "Chceme, abyste měli ty nejlepší dostupné nástroje a nebudeme vám za jejich používání účtovat poplatky.",
  schedulingManagement: "Řízení plánování.",
  chooseConvenientTimeSlots:
    "Vyberte si vhodné časové úseky pro práci a získejte rezervace.",
  expertLandingPayouts: "Platby",
  simpleOnboarding: "Jednoduché zakládání vašich finančních účtů.",
  industryStandard: "Standartní dostupnost a kvalita hovoru",
  lead: "Vést",
  engage: "Zapojit se",
  frequentlyAskedQuestions: "Často kladené otázky",
  contactUs: "Kontaktujte nás:",
  accordionLabel0: "Pokud je služba bezplatná, jak Waylight funguje?",
  accordionText0:
    "Vybíráme provizi dle velikosti ceny služby experta a seznamovací a charitativní hovory jsou na nás!",
  accordionLabel1: "Podporujete více typů nabídek?",
  accordionText1:
    "Rozhodně můžete vytvořit tolik nabídek, kolik chcete, s uvedením ceny, dobou trvání a podrobností každé nabídky.",
  accordionLabel2: "Jak dostanu zaplaceno?",
  accordionText2:
    "Ke zpracování plateb používáme Stripe a další brány. Pro připojení vašeho bankovního účtu nebo platební metody, která funguje ve více než 55 zemích, je vyžadována jednoduchá registrace. Připravujeme několik dalších platebních metod a flexibilní nastavení plateb.",
  accordionLabel3: "Mohu používat Zoom, Meet nebo Skype?",
  accordionText3:
    "Máme plně integrované videospojení, aby zaručovalo bezpečné spojení pro obě strany. V budoucnu bude možností použití jiných video řešení s menším zabezpečením, ale větší flexibilitou.",
  accordionLabel4: "Mohu používat Zapier, Calendly atd.?",
  accordionText4:
    "Aktivně pracujeme nad integrací nahromaděných nevyřízených položek, které si naši uživatelé nejvíce přejí. Rádi si vyslechneme vaše nápady týkající se této záležitosti. Pošlete rychlý email na adresupodpora@waylight.me . Děkuji!",
  freeAudience: "My vyvíjíme - vy tvoříte!",
  businessProfile: "Obchodní profil.",
  onlineBusiness:
    "Vytvořte online business během několika minut, zaměřte se na to, co umíte nejlépe.",
  paidCalls: "Placené audio a video hovory.",
  paidChatsAndBlog: "Placené chaty a blog.",
  setUpSubscription: "Nastavte si odběr pro chatování a osobní blog.",
  web3: "Web3 hodnocení a recenze.",
  collectRealRatings:
    "Sbírejte skutečná hodnocení a recenze, abyste mohli budovat svou osobní značku.",
  getStartedToday: "Tři jednoduché kroky k novému příjmu:",
  createProfile: "1. Vytvořte si svůj profil",
  fillTemplate:
    "Vyplňte předem vytvořenou šablonu svého profilu, rozvrhu a nabídek, nastavte ceny pro placený chat a blog a během několika minut budete připraveni přijímat objednávky a odběry.",
  shareIt: "2. Sdílejte to se světem",
  linkYourProfile:
    "Propojte svůj profil se všemi svými kanály – svými sociálními médii, reklamami, emailem a dalšími. Sdílejte informace o svém online business v příspěvcích, příbězích, videích, osobních zprávách atd.",
  growYourBusiness: "3. Rozvíjejte své business",
  shareYourExpertise:
    "Sdílejte své nejen odborné znalosti, aktualizujte svůj blog, odpovídejte na rezervace a chaty, provádějte online video konzultace a získejte svá veřejná hodnocení a recenze Web3.",
  businessflow: "Tok",
  businessflowStep1Title: "Přidejte odkaz do BIO",
  businessflowStep1:
    "Přidejte odkaz na svůj profil Waylight Business v BIO sociálních sítí (Instagram, Facebook, YouTube, LinkedIn, Twitter, TikTok atd.) a sdílejte informace prostřednictvím příběhů a příspěvků o něm.",
  businessflowStep2Title: "Videohovory 1:1, chaty 1:1, osobní blog",
  businessflowStep2:
    "Monetizujte prostřednictvím videohovorů 1:1 a chatů 1:1 se svými odběrateli a klienty. Vytvářejte na svém osobním blogu obsah pouze pro členy s návody, články, video radami, zákulisím, osobními životními zkušenostmi, lekcemi, hudbou atd.",
  businessflowStep3Title: "Dostat zaplaceno",
  businessflowStep3:
    "Nechte si platit za videohovory, chaty a odběry pouze pro členy na vašem osobním blogu. Zvyšte své zpeněžení sdílením dalších informací o vašem Waylight!",
  bookOnboardingCall: "Zarezervujte si bezplatný vstupní proces",
  talkToFounders:
    "Promluvte si se zakladateli o tom, jak začít monetizovat pomocí Waylight.",
  orScanQR: "nebo naskenujte QR",
  yourFreeTools: "Vaše bezplatné nástroje",
  features: "Funkce",
  personalLink: "Osobní URL",
  personalQR: "Osobní QR",
  scheduling: "Plánování",
  bookings: "Rezervace",
  paidChats: "Placené chaty 1:1",
  personalPaidBlog: "Osobní placený blog",
  soon: "již brzy",
  subscriptions: "Odběry",
  payments: "Platby ve více než 100 zemích",
  payouts: "Výplaty ve více než 58 zemích",
  cryptoPayments: "Krypto platby",
  coinsAwarding: "Udělování mincí",
  web3Ratings: "Web3 hodnocení, recenze",
  clientsManagement: "Správa klientů",
  sessionsManagement: "Správa seancí",
  dashboard: "Hlavní panel",
  businessCalculator: "Waylight obchodní kalkulačka příjmů",
  howMuchMoney: "Zjistěte, kolik peněz by odběratelé přinesli vaší firmě.",
  estimateNumber: "1. Odhadněte počet klientů a odběratelů",
  howMuchYouCharge: "2. Kolik si můžete účtovat za videohovor nebo odběr",
  monthlyIncome: "Měsíční příjem",
  newToday: "dnes nové",
  hadNoSessions: "Tento týden žádné seance",
  atThisWeek: "tento týden",
  welcomeToWaylight: "Vítejte na Waylight!",
  welcomeToWaylightBusiness: "Vítejte ve Waylight Business!",
  linkToVideo: "Odkaz na úvodní video",
  writeSentences: "Napište pár vět o sobě.",
  emptyExpertTitle: "Zdá se, že tento stránka neexistuje",
  emptyExpertText:
    "Odkaz může být nefunkční nebo byla stránka zakázána či odstraněna.",
  profileData: "Údaje o profilu",
  headerImageTooltip:
    "Doporučené rozměry obrázku jsou přibližně 10:1. Můžete zkusit něco jako 1900x190px nebo 1280x128px.",
  emailTooltip:
    "Toto je emailová adresa pro veřejné zobrazení (ne vaše přihlašovací údaje). Můžete zadat libovolný email, který se vám líbí, nebo jej zcela odstranit, je to volitelné!",
  timezoneTooltip:
    "Pokud nemůžete najít své město, zkuste vyhledat zónu, jako je „pacifický letní čas“ nebo „východoevropský čas“.",
  linkToVideoPresentation: "Přidat externí odkaz",
  linkToVideoPresentationTooltip:
    "Nastavte odkaz na externí video, které bude součástí informací o vás. Většina stávajících videohostingových služeb je podporována. Pokud nahrajete video – bude mít přednost před tímto odkazem.",
  linkToVideoOfferTooltip:
    "Nastavte odkaz na externí video, které bude součástí informací v nabídce. Většina stávajících videohostingových služeb je podporována. Pokud nahrajete video – bude mít přednost před tímto odkazem.",
  expertProfileVideoPresentation: "Nebo nahrát soubor",
  phoneTooltip:
    "Toto je telefonní číslo pro veřejné zobrazení. Je to volitelné!",
  socialProfilesTooltip:
    "Přidejte odkazy na své sociální profily a stránky, jako je Youtube, Facebook, Linkedin, Instagram nebo Tiktok.",
  videoPresentationTooltip:
    "Nahrajte video, které se zobrazí v informacích o vás. Přepíše výše uvedený odkaz na video.",
  videoOfferTooltip:
    "Nahrajte video, které  bude součástí informací v nabídce. Přepíše výše uvedený odkaz na video.",
  occupationTooltip:
    "Řekněte světu o své profesi, např. 'AR Consultant' nebo 'Top Tiktok Influencer'.",
  skillsTooltip:
    "Přidejte své dovednosti, aby vaši klienti věděli, v čem jste dobří, např. „business“ nebo „rodičovství“.",
  aboutTooltip:
    "Přidejte svůj popis pro své klienty, aby se cítili vítáni a ocenili vaši osobní značku.",
  speaksTooltip: "Určete jazyky, kterými mluvíte.",
  cropPhoto: "Oříznout fotografii",
  updatePhoto: "Aktualizujte profilovou fotku",
  crop: "Oříznutí",
  bookASession: "Když si zarezervujete seanci zobrazí se zde",
  videoAudioSettings: "Nastavení videa a zvuku",
  videoAudioSettingsDescription:
    "Pomocí těchto nastavení můžete upravit obraz a zvuk",
  cameras: "Kamera",
  microphones: "Mikrofon",
  output: "Řečníci",
  bgSettings: "Vyberte pozadí videa",
  toStartAChat: "Pro zahájení  chatu",
  startAChat: "a zahajte chat.",
  messageToAnExpert: "Pošlete svou první zprávu expertovi",
  messageToAClient: "Pošlete svou první zprávu klientovi",
  chatWindowGetToKnow: "1. Poznejte své potenciální klienty a jejich potřeby",
  requestInformation: "2. V případě potřeby si vyžádejte další informace",
  notGettingAReply:
    "3. Pokud nedostanete odpověď, můžete zkusit zdvořile pokračovat",
  knowEachOther: "4. Seznamte se a naplánujte si seanci",
  chatWindowProvideABrief: "1. Uveďte stručný popis toho, co hledáte",
  chatWindowAddAttachments: "2. V případě potřeby přidejte přílohy",
  describeYourIssue:
    "Popište prosím svůj požadavek a co se chcete naučit nebo s čím potřebujete pomoci. V případě potřeby připojte další soubory.",
  getAConfirmation: "Brzy obdržíte potvrzení od experta.",
  describeTheDetails: "Popište podrobnosti, cenu a požadavky pro vaši nabídku.",
  areYouSure: "Opravdu chcete tuto nabídku smazat?",
  offerAvailable: "Nabídka k dispozici",
  offerUnavailable: "Nabídka není k dispozici",
  notHaveOffers: "Zatím nemáte žádné nabídky",
  createOne: "Vytvořte novou a začněte pracovat se svými klienty.",
  getPaid: "Dostanete zaplacenou stanovenou cenu po pevně stanovenou dobu.",
  describeYourOffer: "Stručně popište svou nabídku.",
  describeRequirements:
    "Popište požadavky na klienty před připojením k seanci: zpráva nebo přílohy.",
  priceLimit: "Cena může být $0, nebo od ${minPrice} do ${maxPrice}.",
  priceLimitForUA: "Cena může být ₴0, nebo od ₴{{minPrice}} do ₴{{maxPrice}}.",
  offerNameDescription:
    "Zkuste něco jako 'Seznamovací hovor' nebo 'základní grafický desigh', pokud tomu rozumíte.",
  clickingConfirm: "Kliknutím na „Potvrdit“ souhlasím s",
  termsAndConditions: "Všeobecné obchodní podmínky",
  preCharge: "Za žádost o seanci bude účtován předběžný poplatek.",
  paymentFeesMayAlter: "Poplatky za platební systém se mohou lišit.",
  notBeCharged: "Nebylo vám nikdy odečteno bez dodatečného potvrzení.",
  last: "Poslední 4",
  lastDigits: "Poslední 4 číslice",
  billingPageName: "název",
  gateway: "Brána",
  prepareForTheMeeting: "Připravte se na schůzku",
  prepareForSession: "Připravit se na",
  fewRecomendations:
    "Několik doporučení, jak zajistit, aby vaše seance byla pohodlná a produktivní.",
  findAQuiet: "Najděte si klidné místo, kde vás nikdo nebude rušit",
  makeSure: "Ujistěte se, že máte dobré internetové připojení",
  weRecommendUsing: "Doporučujeme používat sluchátka",
  prepareYourQuestions: "Připravte si otázky",
  enjoyTheSession: "Užijte si seanci!",
  initialization: "Inicializace...",
  beginOnboardingAsExpert:
    "Začněte se registrovat jako expert na Waylight Business",
  connectStripeAccount:
    "Abyste mohli přijímat peníze od svých klientů, musíte si vytvořit platební metodu.",
  provideABrief: "1. Uveďte stručný popis toho, co hledáte",
  addAttachments: "2. V případě potřeby přidejte přílohy",
  gettingAReply:
    "3. Pokud nedostanete odpověď, můžete zkusit zdvořile pokračovat",
  getToKnow: "4. Seznamte se a naplánujte si seanci",
  fileType: "typ souboru není podporován",
  orDragAndDrop: "nebo přetáhněte",
  upTo: "až 1 GB (kontaktujte podporu, pokud máte větší velikosti souborů)",
  headerImage: "Obrázek záhlaví",
  inviteClientsModalInviteClients: "Pozvěte klienty",
  shareYourLink: "Sdílejte svůj osobní odkaz a pozvěte své klienty!",
  addDays: "Nastavte si svůj pravidelný rozvrh",
  publiclyInformation: "Lze přidat více časových úseků na jeden den v týdnu.",
  availableTimeslot: "Dostupný časový úsek",
  AddTimeslot: "Přidat časové okno",
  AddToSchedule: "Přidat do plánu",
  signUpAs: "Zaregistrujte se jako",
  registerAsClient: "Chci se naučit něco nového",
  registerAsExpert: "Chci se podělit o své zkušenosti",
  approveSession: "Schválit seanci",
  approveThisSession:
    "Opravdu chcete akceptovat tuto seanci? Schválením souhlasíte s připojením v určený čas.",
  noTimeslots:
    "Nejsou k dispozici žádné časové úseky. Vyberte si jiný termín nebo se zeptejte experta na rozvrh.",
  toastSavedSuccessfully: "Úspěšně uloženo",
  toastDeletedSuccessfully: "Úspěšně smazáno",
  toastEmailCopied: "Email zkopírován",
  toastProfileCopied: "Profil zkopírován",
  toastBookingAvalable: "Nyní můžete přistoupit k rezervaci",
  toastBookingUnavalable: "Nemůžete přistoupit k rezervaci",
  toastQrCopied: "QR kód zkopírován",
  toastPaymentMethodAdded: "Platební metoda byla úspěšně přidána",
  toastPaymentMethodDeleted: "Platební metoda byla úspěšně smazána",
  toastUrlCopied: "Osobní odkaz zkopírován",
  toastOfferUrlCopied: "Odkaz na nabídku zkopírován",
  toastSessionCanceled: "Tato seance byla z časových důvodů zrušena",
  toastSessionWithYourself: "Nemůžete si zarezervovat seanci sami!",
  toastPayoutMethodAdded: "Způsob platby byl úspěšně přidán",
  toastPayoutMethodDeleted: "Způsob platby byl úspěšně smazán",
  toastPrimaryCardError: "Nelze nastavit hlavní kartu",
  toastPrimaryCardAdded: "Primární karta byla úspěšně přidána",
  toastTooLargeFile: "Velikost souboru je příliš velká",
  toastOfferNotAdded: "Nabídka nebyla přidána",
  toastOfferNotEdited: "Nabídka nebyla upravena",
  toastSessionLinkCopied: "Odkaz na seanci je zkopírován",
  toastMaxParticipantsReached: "Byl dosažen maximální počet účastníků",
  toastNotMayJoin:
    "Bohužel se nemůžete připojit k této seanci, protože dosáhla své kapacity.",
  toastLinkExpired: "Platnost tohoto odkazu již vypršela.",
  toastCannotStartSession: "Rychlou seanci nyní nelze spustit",
  toastSessionExtendedSuccessfully: "Seance byla úspěšně prodloužena",
  toastTimeslotStartAndEnd:
    "Nemůžete přidat časové okno, které začíná a končí současně",
  toastTimeslotEndsInPast:
    "Nemůžete přidat časové okno, které končí v minulosti",
  toastTimeslotDuplicate: "Nelze přidat duplicitní časový úsek",
  toastSkillNameTooLong:
    "Název vaší dovednosti je příliš dlouhý, zkuste vybrat kratší název",
  buttonSave: "Uložit",
  buttonCancel: "zrušení",
  buttonApprove: "Schválit",
  buttonDelete: "Vymazat",
  buttonChat: "Chat",
  buttonOpenChat: "Chat",
  buttonFiles: "Soubory",
  buttonNotNow: "Teď ne",
  buttonSubmit: "Předložit",
  buttonNo: "Ne",
  buttonEnd: "Konec",
  buttonSignUp: "Zaregistrujte se",
  buttonYes: "Ano",
  buttonEdit: "Upravit",
  buttonCopy: "kopírovat",
  buttonReply: "Odpověď",
  buttonAdd: "Přidat",
  buttonSend: "Poslat",
  buttonRequest: "Požádejte o podporu",
  buttonSet: "Nastavení",
  buttonEditPhoto: "Upravit fotografii",
  buttonStartAnyway: "Přesto začněte",
  buttonWait: "Počkejte",
  buttonCopyLink: "Kopírovat odkaz",
  buttonLeave: "Odejít",
  buttonExtraMinutes: "10 minut navíc",
  buttonViewOffer: "Zobrazit podrobnosti",
  buttonMoreDetails: "Více informací",
  buttonContinue: "Pokračovat",
  buttonBookACall: "Objednejte si hovor",
  notificationSetup: "Nastavit",
  notificationFillProfile: "Vyplňte profil",
  notificationHi: "Ahoj!",
  notificationAddYourName:
    "Přidejte své jméno a fotografii pro oživení komunikace, pokud se na to cítíte. My na vás netlačíme :)",
  notificationSetupBilling: "Nastavení fakturace",
  notificationAddPaymentMethod:
    "Přidejte platební metodu, abyste si mohli rezervovat seanci.",
  notificationGoToBilling: "Přejděte na Fakturace",
  notificationWeAreSureYouHaveALotToSay:
    "Jsme si jisti, že o sobě máte hodně co říct. Vyplňte svůj profil a začněte sdílet!",
  notificationAddAnOffer: "Přidejte nabídku",
  notificationAddYourOffers: "Přidejte své nabídky",
  notificationSetupVariousKindsOfSessions:
    "Nastavte pro své klienty různé druhy seancí, které budou dostupné k rezervaci na vašem profilu.",
  notificationGoToMyOffers: "Přejděte do nabídky",
  notificationConnectStripe: "Nastavení plateb",
  notificationSetupPayouts: "Přihlášení k platbám",
  notificationInOrderToReceiveMoney:
    "Abyste mohli přijímat peníze od svých klientů, musíte si vytvořit platební metodu.",
  notificationGoToPayouts: "Přejděte na Platbám",
  notificationSetYourSchedule: "Nastavte si rozvrh",
  notificationWorkAtConvenientHours: "Pracujte, kdy se Vám to hodí",
  notificationSetupSchedule:
    "Nastavte rozvrh a vaši klienti si budou moci rezervovat seance pouze podle něj.",
  accountOn: "Účet zapnutý",
  accountOff: "Účet je vypnutý",
  cancelSession: "Zrušit seanci",
  paymentFailed: "Platba selhala",
  paymentFailedDescription:
    "Vaše platba neproběhla a my jsme nemohli požádat o novou seanci.",
  areYouSureCancel: "Opravdu chcete zrušit tuto seanci?",
  areYouSureCancelPaidSession:
    "Opravdu chcete zrušit? Vaše platba bude vrácena.",
  areYouSureCancelLessThanADay:
    "Opravdu chcete zrušit, když zbývá méně než 24 hodin? Vaše platba NEBUDE vrácena.",
  link: "Odkaz",
  view: "Otevřít",
  requires_action: "Vyžaduje akci",
  succeeded: "Uspěl",
  signUpTitle: "Zaregistrujte se",
  profileTitleNotify: "Profil | Waylight",
  dashboardTitleNotify: "Hlavní panel | Waylight",
  chatTitleNotify: "Chat | Waylight",
  with: "s",
  expertsTitle: "Experti",
  expertsTitleNotify: "Prozkoumat | Waylight",
  signUpTitleNotify: "Zaregistrujte se | Waylight",
  chatTitle: "Chat",
  clientsTitle: "klienti",
  clientsTitleNotify: "Klienti | Waylight",
  clientTitle: "Klient",
  fixedPrice: "Pevná cena",
  internetConnectionLost: "Ztratilo se připojení k internetu",
  fiveMinutesLeft: "Zbývá 5 minut",
  summarizeMeeting: "Shrňte prosím své setkání a podělte se o své dojmy",
  oopsConnectionLost: "Jejda... Spojení se skupinou bylo ztraceno",
  partyTemporaryLostConnection:
    "Zdá se, že účastnící setkání dočasně ztratila spojení. Omluváme se za nepříjemnosti.",
  leaveAReviewFor: "Zanechte recenzi pro",
  leaveAReview: "Zanechat recenzi",
  review: "Posouzení",
  followers: "Následovníci",
  rating: "Hodnocení",
  hitStarRate: "Ohodnoťte seanci níže!",
  reviewsEmpty: "Zatím nejsou žádné recenze",
  reviews: "Recenze",
  sessionEnded: "Seance byla úspěšně ukončena",
  thankYouForLighting: "Děkujeme, že jste někomu posvítili na cestu!",
  sessionNotStarted: "Seance nebyla zahájena",
  devicePermissionTittle:
    "Povolte Waylight přístup k mikrofonu a kameře. Pokud nemáte video, obnovte stránku.",
  networkConnection: "Kvalita vašeho internetového připojení je",
  unknown: "neznámý",
  low: "nízký",
  average: "průměrný",
  good: "dobrý",
  expertDidNotCome:
    "Váš expert se bohužel nedostavil. Nebude vám nic účtováno a dostanete náhradu. Omlouváme se za nepříjemnosti.",
  noFilesAdded: "Nebyly přidány žádné soubory.",
  enterYourEmail: "vložte svou emailovou adresu",
  required: "Políčko je vyžadováno",
  emailOrPassword: "nesprávný email nebo heslo",
  emailInUse: "Tento email se již používá",
  businessIsNotAvailableTitle:
    "Waylight Business není ve vaší zemi k dispozici. Buďte první, kdo se dozví, kdy bude spuštěn.",
  beInTouch: "Být v kontaktu",
  businessIsNotAvailableEmailAddress: "Emailová adresa",
  pleaseTryAgain:
    "Nepodařilo se nám uložit vaši emailovou adresu. Prosím zkuste to znovu.",
  thankYou: "Děkujeme, jakmile to bude zveřejněno, budeme vás kontaktovat.",
  wereNoChatMessages: "V této seanci nebyly žádné chatové zprávy",
  yourCountry: "Země vaší banky",
  open: "OTEVŘENO",
  offerName: "Název nabídky",
  gatewayCountryWarning:
    "Upozornění: Toto by měla být země vaší bankovní instituce a NELZE změnit později, což může ovlivnit vaši schopnost přijímat platby.",
  addAttachment: "Přidat přílohu",
  you: "Vy",
  explore: "Prozkoumat",
  stats: "Statistiky",
  more: "Zobrazit více",
  noPrice: "Bezplatné nabídky",
  theyWillAppear: "Objeví se zde, když začnete komunikovat",
  noExpertFoundUsingFilter:
    "Nebyli nalezeni žádní experti používající tento filtr.",
  tryAnotherInput: "Zkuste jiný tag nebo něco napsat  ručně.",
  noFavoriteExpertsFound: "Nebyli nalezeni žádní oblíbení experti.",
  tryAddingSomeone: "Zkuste někoho přidat do oblíbených.",
  agoraDeniedError:
    "Chcete-li používat Waylight, povolte v nastavení přístup k mikrofonu a kameře!",
  editCover: "Upravit úvodní fotku",
  language: "Jazyk",
  invalid: "Neplatný",
  scrollPastHistory: "Procházejte historií své minulé seance výše",
  earnedFromLastSession: "výdělek z poslední seance",
  typeHere: "Napište zde",
  schedule: "Plán",
  offers: "Nabídky",
  qrSharing: "Stáhněte si a sdílejte svůj QR kód.",
  qrSharingMobile: "Dlouhým stisknutím QR kódu jej zkopírujte a sdílejte.",
  preview: "Náhled",
  back: "Zadní",
  love: "Vyrobeno s láskou Waylight",
  allRightsReserved: "© 2024 Waylight Inc. Všechna práva vyhrazena.",
  allRightsReservedSecond:
    "© 2022 Arceon Consulting OÜ. Všechna práva vyhrazena",
  termsOfService: "Podmínky služby",
  privacyPolicy: "Zásady ochrany osobních údajů",
  sessionSharingpopupTitle: "Nelze spustit sdílení obrazovky!",
  sessionSharingpopupDescription:
    "Zkontrolujte, zda máte nejnovější verzi prohlížeče a zda má systémová oprávnění pro sdílení obrazovky. Nakonec můžete zkusit obnovit stránku. Pokud problém přetrvává, kontaktujte podporu!",
  sessionSharingpopupSafariDescription:
    "Váš prohlížeč nepodporuje sdílení obrazovky. Nejnovější verze populárních prohlížečů tuto funkci podporují.",
  copy: "kopírovat",
  expertWithoutOffers: "Tento expert zatím nepřidal žádné placené nabídky.",
  errorViewHeader: "Jejda! Něco se pokazilo!",
  errorViewDescription:
    "Došlo k chybě. Tento odkaz může být nefunkční nebo k položce nemáte přístup.",
  returnToHome: "Návrat domů",
  invalidURL:
    "Neplatná URL. Ujistěte se, že to začíná ' https://' nebo 'http:// '",
  invalidVideoExternalURL:
    "Neplatná adresa URL videa. Přidejte odkaz na YouTube nebo Vimeo.",
  repeatedURL: "Tento odkaz již byl přidán",
  tooLongUrl:
    "Tento odkaz je příliš dlouhý. Prosím, nepoužívejte odkazy delší než 128 znaků.",
  edited: "Upraveno",
  emptyCards: "Zatím jste nepřidali žádné karty",
  emptyBillingHistory: "Zatím nemáte žádné transakce",
  emptyBillingHistoryDescription:
    "Zobrazí se zde, když zahájíte rezervaci seancí",
  addNewSkill: "Přidejte novou dovednost",
  confirmationAddSkill: "Jste si jisti, že chcete přidat novou dovednost?",
  addPaymentMethod: "Přidat způsob platby",
  stripeDescription: "Pro transakce s experty z 50+ zemí",
  fondyDescription: "Pro transakce s experty z Ukrajiny",
  fondyAddCardDescription:
    "Chcete-li pokračovat, přidejte platební metodu. Pro potvrzení se uskuteční zkušební platba.",
  timeZoneChangedHeader: "Časové pásmo změněno",
  timeZoneSChangedDescription:
    "Zjistili jsme, že se vaše časové pásmo změnilo. Chcete aktualizovat časové pásmo svého profilu?",
  updateButton: "Aktualizace",
  and: "a",
  moreFiles: "více souborů",
  addPayout: "Přidejte alespoň jeden způsob přijímání plateb",
  testChargeWarning: "Pro potvrzení se uskuteční zkušební platba.",
  payoutMethods: "Vaše platební metody",
  identifier: "Identifikátor",
  stopWar: "Zastavit válku",
  yourAreOnlyOneHere: "Jsi tu jediný",
  yourAreOnlyOneHereSingle:
    "Jste tu zatím sami \nSdílejte níže uvedený odkaz, aby se ostatní mohli připojit",
  clientView: "Klient",
  businessView: "Business",
  mainPage: "Hlavní stránka",
  signUpCompleted: "Registrace dokončena",
  selectPaymentInfo: "Dostupné způsoby platby závisí na bráně vašeho experta",
  payment: "Platby",
  paymentWasDeclined: "Platba byla zamítnuta.",
  payoutWasDeclined: "Platba byla zamítnuta.",
  primary: "Hlavní",
  notUsed: "Nepoužívá",
  releaseBannerTitle: "Waylight byl aktualizován na novou verzi.",
  refresh: "Obnovit",
  country: "Země",
  viewAll: "Zobrazit vše",
  videoPresentation: "Video prezentace",
  bookFor: "Kniha pro",
  bookForFree: "Rezervujte zdarma",
  follow: "Následovat",
  followed: "Následoval",
  emptyPortfolio: "Zdá se, že v tomto portfoliu nejsou žádné soubory",
  media: "Média",
  other: "Soubory",
  emptyReview: "Zdá se, že nejsou žádné recenze",
  newest: "Nejnovější",
  oldest: "Nejstarší",
  viewProfileInfo: "Zobrazit informace o profilu",
  emptyTransactions: "Zdá se, že neprobíhají žádné transakce",
  emptyOffers: "Bohužel tento expert zatím žádné nabídky nemá.",
  searchExperts: "Hledejte experty",
  modifySchedule: "Upravit rozvrh",
  editMode: "Režim úprav",
  finishEditing: "Dokončete úpravy",
  editPersonalInfo: "Upravit osobní údaje",
  editPortfolio: "Upravit portfolio",
  editOffers: "Upravit nabídky",
  profileId: "Osobní veřejné ID",
  profileSettings: "Nastavení profilu",
  editPublicID: "Upravit veřejné ID",
  removeTimeslot: "Odstraňte časový úsek",
  areYouSureRemoveTimeslot: "Opravdu chcete odstranit časový úsek?",
  qrCode: "QR kód",
  communication: "Sdělení",
  editCoverDescription: "Přizpůsobte si svůj titulní obrázek",
  editProfileData: "Upravit údaje profilu",
  editProfileDataDescription: "Přidejte informace o sobě a zkušenostech",
  editPublicIDDescription: "Vyberte pro svůj profil jedinečné veřejné ID",
  profilePhoto: "Profilová fotka",
  publicIDDescription: "Toto je automaticky generované ID!",
  publicIDWarning:
    "Nezapomeňte to změnit na něco skvělého v režimu úprav, jako je „supergirl“.",
  followWarning: "Tlačítko Sledovat bude fungovat pro všechny uživatele.",
  openChatWarning:
    "Nebudete si moci chatovat sami se sebou, ale vaši klienti ano.",
  bookingOfferWarning: "Uživatelé budou moci zakoupit nabídku kliknutím zde.",
  shareOffer: "Kopírovat odkaz",
  turnOffAccount: "Vypněte účet",
  turnOnAccount: "Zapnout účet",
  areYouSureYouWantTurnOff: "Opravdu chcete svůj účet úplně vypnout?",
  areYouSureYouWantTurnOn: "Opravdu chcete svůj účet znovu zapnout?",
  disableFreeChat: "Zakázat bezplatný chat",
  enableFreeChat: "Povolit chat zdarma",
  areYouSureYOuWantDisableFreeChat: "Opravdu chcete zakázat chat zdarma?",
  areYouSureYOuWantEnableFreeChat: "Opravdu chcete povolit chat zdarma?",
  cancelEditing: "Zrušit úpravy",
  descriptionCancelEditing: "Opravdu chcete zrušit úpravy?",
  takenPublicID: "Toto veřejné ID je již obsazeno",
  charactersMatch: "2-64 znaků",
  charactersBetweenMatch: "Pole musí mít 1 až 64 znaků",
  charactersMaxMatch: "Maximální délka pole je 64 znaků",
  deletePhoto: "Smazat fotku",
  descriptionDeletePhoto: "Opravdu chcete smazat svou fotku?",
  accountOnDescription:
    "Když je účet vypnutý, není dostupný a není viditelný žádným způsobem (přímé odkazy, výsledky vyhledávání atd.)",
  freechat: "Chat zdarma",
  freechatDescription:
    "Když je bezplatný chat zakázán, uživatelé s vámi tímto způsobem nebudou moci zahájit komunikaci. Se svými klienty však komunikovat můžete.",
  freeChatWarning:
    "Tento účet zakázal bezplatný chat. Ke komunikaci můžete požádat o seanci.",
  editMessage: "Upravit zprávu",
  replyTo: "Odpovědět",
  file: "Soubor",
  unableToJoinSession: "Nelze se připojit k seanci",
  backgroundWarning:
    "Upozornění: Pozadí není plně podporováno v prohlížečích Safari a Firefox a nemusí fungovat.",
  single: "Rychlý",
  scheduleSession: "Naplánujte si seanci s expertem nebo tvůrcem.",
  startSessionImmediately:
    "Okamžitě zahajte bezplatnou seanci. Pozvěte ostatní přímo pomocí odkazu.",
  quickSessionWarningTittle: "Opravdu chcete zahájit seanci?",
  quickSessionWarningDescription:
    "Máte naplánovanou seanci, která se překrývá s rychlou seancí, kterou se pokoušíte zahájit.",
  connectIn: "Připojte se",
  connectOn: "Připojte se",
  sessionDecisionTittle: "Vaše skupina se neobjevila.",
  expertSessionDecision:
    "Chcete seanci ukončit nebo počkat déle? Ukončení seance bude mít za následek převod platby na váš účet.",
  clientSessionDecision:
    "Chcete seanci ukončit nebo počkat déle? Po ukončení seance vám bude vrácena platba.",
  cancelledSessionTittle: "Tato seance byla zrušena",
  cancelledSessionExpertDescription:
    "Platba byla převedena na váš účet. Omlouváme se za nepříjemnosti.",
  cancelledSessionClientDescription:
    "Nebude vám nic účtováno a dostanete náhradu. Omlouváme se za nepříjemnosti.",
  areYouSureYouWantLeaveSession: "Opravdu chcete seanci opustit?",
  areYouSureYouWantLeaveSessionDescription:
    "Do seance se budete moci vrátit, dokud neskončí.",
  addExtraMinutes: "Přidejte dalších 10 minut",
  addExtraMinutesDescription:
    "Opravdu chcete k této seanci přidat dalších 10 minut?",
  unableAddExtraMinutes: "Nelze přidat dalších 10 minut",
  unableAddExtraMinutesDescription:
    "Bohužel máte naplánovanou seanci za méně než 10 minut.",
  monday: "pondělí",
  tuesday: "úterý",
  wednesday: "středa",
  thursday: "Čtvrtek",
  friday: "pátek",
  saturday: "sobota",
  sunday: "Neděle",
  h: "h",
  sharingSetting: "Nastavení sdílení",
  call: "Volání",
  public: "Veřejnost",
  byLinkOnly: "Pouze pomocí odkazu",
  offerInfo: "Nabídněte informace",
  coverVideo: "Obálka / video",
  imageVideo: "Obrázek / video",
  chooseCountry: "Vyber zemi",
  passwordChangedSuccessfully: "heslo bylo úspěšně změněno",
  connected: "Připojeno",
  passedALot: "Minulý",
  chooseCountryBank: "Vyberte zemi své banky nebo finanční služby.",
  createAccountContinue: "Chcete-li pokračovat, vytvořte si účet",
  metasignInTitle: "Přihlásit se | Waylight",
  metasignUpTitle: "Registrace | Waylight",
  metapasswordForgotTitle: "Zapomenuté heslo | Waylight",
  metapasswordResetTitle: "Obnovit heslo | Waylight",
  clientflowStep1Title: "Najděte odkaz v BIO nebo ve Waylight  vyhledávání ",
  clientflowStep1:
    "Najděte odkaz Waylight na svého autora, influencera, experta nebo tutora na sociálních sítích (Instagram, Facebook, YouTube, LinkedIn atd.) nebo zadejte jméno nebo přezdívku do vyhledávání Waylight.",
  clientflowStep2Title:
    "Zarezervujte si videohovor nebo odběr  k blogu nebo chatu",
  clientflowStep2:
    "Otevřete a prozkoumejte obchodní profil svého autora, influencera, experta nebo lektora. Najděte zajímavou nabídku na videohovor 1:1, zarezervujte si ji a skočte na to v naplánovaný čas. Zkuste chatovat po nebo před, abyste získali více informací v dlouhodobém horizontu. Přihlaste se k odběru blogu pouze pro osobní členy s návody, články, video s radami, zákulisím, osobními životními zkušenostmi, lekcemi, hudbou atd.",
  clientflowStep3Title: "Zkontrolujte decentralizovaná hodnocení a recenze",
  clientflowStep3:
    "Decentralizované hodnocení vytváří u tvůrců a expertů dobré povědomí, nelze je prodat ani zfalšovat a vytvářejí u klientů a odběratelů pocit důvěry, transparentnosti, důkazu odbornosti a poptávky po obsahu. Zanechte svá skutečná hodnocení po videohovorech, chatech a příspěvcích pouze pro členy v blozích. Sdílejte své recenze a pomozte komunitě Waylight vyzdvihnout ty nejlepší.",
  confirmBooking: "Potvrdit výběr",
  free: "Volný",
  unableVerifyEmail: "Nepodařilo se nám ověřit váš email.",
  thankYouForVerifiedEmail: "Děkujeme za ověření vašeho emailu!",
  reSend: "Přeposlat",
  signInToContinue: "Chcete-li pokračovat, přihlaste se",
  switchingToClientView: "Přepnutí na zobrazení klienta",
  switchingToExpertView: "Přepnutí na obchodní zobrazení",
  cookiePermission:
    "Vážíme si vašeho soukromí. Soubory cookie používáme k vylepšení vašeho zážitku z prohlížení, poskytování personalizovaného obsahu a analýze naší návštěvnosti. Kliknutím na „Přijmout“ souhlasíte s naším používáním souborů cookie.",
  readMore: "Přečtěte si více",
  notReadableDevicesWarning:
    "Upozornění: Vaše kamera a/nebo mikrofon se právě používají nebo k nim není přístup!",
  howPayoutsWork: "Jak fungují platby",
  payoutsOnboarding1: "Pro aktivaci plateb dokončete registraci.",
  payoutsOnboarding2:
    "Udržujeme vaše peníze v bezpečí, dokud nedokončíte vstupní proces.",
  payoutsOnboarding3:
    "V závislosti na vaší zemi budete potřebovat své bankovní údaje nebo údaje na kartě.",
  payoutsOnboarding4: "Platby probíhají každé pondělí.",
  payoutsOnboarding5: "Stav plateb můžete zkontrolovat v Transakcích.",
  payoutsOnboarding6: "Platební brána účtuje 0,25 % + 0,25 $ z platby.",
  payoutsOnboardingEcommpay6:
    "Platební brána účtuje 0,5% za karty a 0% za obchodníky, pokud jsou povoleny poplatky klienta.",
  payoutsOnboarding7:
    "Pokud platbu dostáváte poprvé, může doručení trvat 7–14 pracovních dní z důvodu omezení brány.",
  payoutsOnboarding8: "Obvykle platba trvá přibližně 3 pracovní dny.",
  payoutsOnboarding9:
    "Změna země v současné době není možná. Pokud to potřebujete, kontaktujte nás a pokusíme se to vyřešit případ od případu.",
  payoutsOnboarding10: "Waylight účtuje variabilní provizi podle vašeho plánu.",
  payoutsOnboarding11: "Pokud máte nějaké dotazy, kontaktujte",
  supportAlternative: "Podpora",
  freeSessionDecision: "Chcete ukončit seanci nebo počkat déle?",
  cancelledFreeSessionDescription:
    "Jste jediný, kdo se objevil. Omlouváme se za nepříjemnosti.",
  m: "m",
  cityNotFound:
    "Nebyly nalezeny žádné možnosti. Vyberte nejbližší velké město.",
  notifications: "Oznámení",
  telegramBot: "Telegram Bot",
  notConnected: "není připojen",
  connectTelegramBot: "Připojit Telegram Bot",
  disconnectTelegramBot: "Odpojit Telegram Bot",
  telegramConnected: "připojen",
  bgSettingsDescription:
    "Pomocí těchto nastavení můžete upravit pozadí videostreamu",
  selectBackground: "Vyberte pozadí",
  bookingDelay: "Nastavení zpoždění rezervace",
  bookingDelayDescription: "Určete, s jakým předstihem můžete být rezervováni.",
  processingFee: "Poplatky za zpracování",
  payoutsOnboardingEcommpay1:
    "Vyberte si osobní kreditní kartu nebo metodu výplaty pro soukromé podnikání.",
  payoutsOnboardingEcommpay3:
    "Musíte buď přidat alespoň jednu osobní kreditní kartu + svůj individuální daňový kód NEBO dokončit onboarding pro obchodníky Fondy, aby transakce fungovaly!",
  payoutsOnboardingEcommpay4: "Výplaty probíhají po každé transakci.",
  payoutsOnboardingEcommpay7:
    "Výplaty probíhají nejpozději následující pracovní den.",
  tutorials: "Produkty",
  tutorialsAndCourses: "Koupit produkty",
  noOffers: "Zdá se, že zde zatím nejsou žádné nabídky",
  newSessionOffer: "Nová nabídka sezení",
  newSessionOfferDescription: "Vytvořte placené nebo zdarma video sezení",
  newTutorialOffer: "Nová nabídka produktu",
  newTutorialOfferDescription: "Vytvořte placený nebo zdarma produkt",
  sessionName: "Název sezení",
  tutorialName: "Název produktu",
  tutorialNameDescription:
    "Vyberte název pro svůj produkt, například „Taneční lekce č. 1“",
  cover: "Obal",
  tutorialCoverTooltip:
    "Nahrajte obrázek nebo video, které se zobrazí v popisu produktu.",
  getTutorialPaid: "Za každý nákup tohoto produktu dostanete zaplaceno.",
  unlockedAfterPurchase: "Odemčeno po zakoupení",
  unlockedAfterPurchaseDescription:
    "Přidejte obsah, který si vaši klienti odemknou.",
  tutorialDetailsDescription:
    "Sem přidejte komentáře, podrobnosti nebo instrukce",
  removeSection: "Odstranit sekci",
  addAnotherSection: "Přidat další sekci",
  details: "Podrobnosti",
  toastEmptyUnlockedContent: "Odemčený obsah nemůže být prázdný",
  toastTutorialNotEdited: "Tento produkt nebyl upravován",
  removeSectionDescription: "Opravdu chcete odstranit sekci?",
  buyFor: "Koupit za",
  buttonOpenChatAlt: "Otevřít chat",
  buttonShare: "Sdílet",
  tutorial: "Produkt",
  toastBuyingYourOwnTutorial: "Nemůžete si koupit vlastní produkt!",
  purchasingInProgress: "Probíhá transakce",
  byClickingConfirm: "Kliknutím,",
  iAgreeWith: "souhlasím s",
  allowWaylightToCharge:
    "a umožnit společnosti Waylight nyní a v budoucnu stahovat peníze z mé karty v souladu s podmínkami předplatného, dokud nezruším.",
  paymentProcessingFees: "Mohou být účtovány poplatky za zpracování platby.",
  bookingTutorialWarning:
    "Uživatelé budou moci produkt zakoupit kliknutím zde.",
  editTutorials: "Upravit produkty",
  topReview: "Nejlepší recenze",
  addForFree: "Přidat zdarma",
  tutorialPaymentFailed:
    "Vaše platba neproběhla a nemohli jste zakoupit tento produkt.",
  buy: "Koupit",
  tutorialWith: "produkt od",
  purchased: "Zakoupeno",
  noTutorialsFound: "Nebyly nalezeny žádné produkty",
  noTutorialsFoundDescription:
    "Zobrazí se zde, až zakoupíte nebo přidáte produkt",
  tags: "Štítky",
  createSessionOffer: "Vytvořit nabídku sezení",
  createSessionOfferDescription: "Přidejte placené nebo zdarma video sezení.",
  createTutorial: "Vytvořit nabídku produktu",
  createTutorialDescription: "Přidejte placený nebo zdarma produkt.",
  requires_transfer: "Vyžaduje zaškolení",
  pending: "Čeká na vyplacení",
  requires_payout: "Čeká na vyplacení",
  requires_capture: "Zadržen",
  reschedule: "Přeplánovat",
  rescheduleDelay: "Nastavit odložení přeplánování",
  rescheduleDelayDescription:
    "Uveďte, za kolik dní před sezením můžete přeplánovat.",
  rescheduleSession: "Přeplánovat sezení",
  rescheduleSessionDescription: "Opravdu chcete sezení přeplánovat?",
  buttonChange: "Přeplánovat",
  timeslots: "Časové úseky",
  specifyReason: "Uveďte důvod změny data a času.",
  cannotRescheduleSession: "Nelze přeplánovat sezení",
  rescheduleReason: "Důvod přeplánování",
  optionLink: "Přidat externí odkaz",
  optionUpload: "Nebo nahrajte soubor",
  tutorialLinkDescription: "Přidejte odkaz na svůj produkt.",
  tutorialUploadDescription:
    "Sem nahrajte libovolný soubor. Můžete přidat jeden soubor na sekci.",
  bgNone: "Žádné",
  bgLowBlur: "Nízká rozmazanost",
  bgMediumBlur: "Střední rozmazanost",
  bgHighBlur: "Vysoká rozmazanost",
  bgSolidColour: "Jednolitá barva",
  bgCustomImage: "Vlastní obrázek",
  telegramNotifications: "Telegramová oznámení",
  connectYourTelegramAccount:
    "Připojte svůj účet Telegram pro přijímání oznámení od Waylight.",
  buttonConnectTelegram: "Připojit Telegram",
  notificationGoToMyProfile: "Přejít na můj profil",
  setupSchedule: "Nastavit rozvrh",
  aboutTheTutorial: "O produktu",
  added: "Přidáno",
  orderingChangeInProgress: "Probíhá změna pořadí...",
  gloryToUkraine: "Sláva Ukrajině!",
  terroristStates:
    "Waylight nefunguje pro uživatele z sankcionovaných zemí s diktátorskými a teroristickými režimy: Rusko, Írán, Severní Korea, Sýrie, Kuba, Venezuela, Afghánistán.",
  never: "Bez omezení",
  expiration: "Časový limit",
  expirationDescription:
    "Klienti budou mít přístup k tomuto produktu po určitý počet dní",
  daysLeft: "zbývajících dní",
  tutorialExpired: "Váš přístup k tomuto produktu vypršel.",
  days: "dnů",
  tips: "Dary",
  newTipOffer: "Nová nabídka darů",
  newTipOfferDescription: "Vytvořit dar",
  tipName: "Název daru",
  tipNameDescription: "Vyberte název pro váš dar",
  tipCoverTooltip:
    "Nahrajte obrázek nebo video, které se zobrazí v popisu daru.",
  aboutTheTip: "O daru",
  describeYourTip:
    "Přidejte popis daru: sbírání darů, provozování charitativní akce nebo nákup kávy?",
  tipAmounts: "Přidejte tři částky darů",
  tipAmountsDescription:
    "Vaši klienti také budou mít možnost zadat vlastní částku, pokud si to přejí.",
  bonusTipper: "(Volitelně) Poslat bonus klientovi",
  bonusTipperDescription:
    "Přidejte obsah nebo zprávu, kterou klienti obdrží jako bonus po darování.",
  tipLinkDescription: "Přidejte odkaz na váš bonus.",
  tipMessageDescription: "Přidejte zde svou textovou zprávu.",
  toastTipNotEdited: "Tento dar nebyl upraven",
  leaveATip: "Zanechat dar",
  bookingTipWarning: "Uživatelé mohou zanechat dar klepnutím sem.",
  includesAReward: "Obsahuje odměnu",
  selectTip: "Vyberte, kolik byste chtěli darovat",
  selectTipAmount: "Vyberte částku pro dar",
  toastBuyingYourOwnTip: "Nemůžete si koupit vlastní dar!",
  tipSuccessfullyBought: "Dar byl úspěšně zakoupen",
  tip: "Dar",
  tipPaymentFailed: "Platba se nezdařila a nebylo možné zakoupit tento dar.",
  purchasedOffers: "Zakoupené",
  noTipsFound: "Žádné dary nebyly nalezeny",
  noTipsFoundDescription: "Zde se zobrazí, když zakoupíte dar",
  thankYouExclamation: "Děkuji!",
  editTips: "Upravit dary",
  tipPriceLimit: "Cena může být od ${{minPrice}} do ${{maxPrice}}.",
  tipPriceLimitForUA: "Cena může být od ₴{{minPrice}} do ₴{{maxPrice}}.",
  cardAuthorization: "Autorizace kreditní karty",
  failed: "Selhalo",
  communityName: "Název komunity",
  communityNameDescription:
    "Vyberte název pro vaši komunitu, například „Můj exkluzivní Telegram kanál pouze pro předplatitele“",
  getCommunityPaid: "Cena předplatného",
  Period: "Období*",
  expirationCommunityDescription: "Nastavte délku předplatného",
  aboutTheCommunityOffer: "O nabídce",
  TelegramChannelName: "Název Telegram kanálu/skupiny*",
  communityTelegramNameDescription:
    "Vyberte název vašeho kanálu nebo skupiny. Pokud je prázdné, klikněte na ikonu informací pro instrukce.",
  communities: "Komunity",
  community: "Komunita",
  subscribeFor: "Předplatit si",
  subscribed: "Předplaceno",
  unsubscribe: "Zrušit předplatné",
  unsubscribeDescription:
    "Jste si jisti, že chcete zrušit předplatné?\nVáš přístup vyprší",
  subscribeDescription: "{{community}}.",
  usubscribed: "Zrušeno předplatné",
  unlimited: "Neomezené",
  subscribeAgain: "Předplatit znovu",
  unsubscribeDescriptionModal:
    "Jste si jisti, že chcete se znovu předplatit?\nBude vám účtována další platba na",
  subscribe: "Předplatit",
  currentlyMember: "Momentálně jste členem {{title}} na Telegramu.",
  notCurrentlyMember: "Momentálně nejste členem {{title}} na Telegramu.",
  newCommunityOffer: "Nová nabídka komunity",
  newCommunityOfferDescription: "Vytvořte nebo spravujte komunitu",
  editCommunities: "Upravit komunity",
  joinCommunities: "Připojit se ke komunitám",
  noCommunitiesAdded: "Nebyly přidány žádné komunity",
  TheyWillAppear: "Zde se objeví, když vytvoříte své nabídky.",
  createCommunity: "Vytvořit komunitu",
  noAccessToData: "Žádný přístup k datům! Přidejte",
  noAccessToDataAsAnAdministrator:
    "jako správce do vaší skupiny, abyste poskytli přístup.",
  retryPayment: "Zkusit platbu znovu",
  areYouSureYouWantToSetCard:
    "Jste si jisti, že chcete nastavit kartu *{{last4}} jako primární?",
  thingsToTry: "Věci, které můžete vyzkoušet:",
  checkIfYouHaveSufficient:
    "Zkontrolujte, zda máte dostatek prostředků na svém účtu nebo zda jsou povoleny internetové platby.",
  selectDifferentPaymentMethod: "Vyberte jinou platební metodu.",
  set: "Nastavit",
  primaryCard: "Primární",
  setPrimary: "Nastavit jako primární",
  inviteMember: "Pozvat člena",
  copyLink: "Kopírovat odkaz",
  members: "Členové",
  cancel: "Zrušit",
  actions: "AKCE",
  subscribedUntil: "PŘEDPLACENO DO",
  waylightClients: "Klienti Waylight",
  nameMembers: "JMÉNO",
  admin: "Správce",
  external: "Externí",
  subscriber: "Předplatitel",
  unsubscribed: "Zrušeno předplatné",
  kicked: "Vyhodit",
  owner: "Vlastník",
  save: "Uložit",
  kickAndBan: "Vyhodit",
  areYouSureYouWantToRemove:
    "Jste si jisti, že chcete odstranit „{{name}}“ ze svého kanálu?",
  WeHaveEncounteredAnIssue:
    "Narazili jsme na problém se vaší opakovanou platbou!",
  yourPaymentHasNotGone:
    "Vaše platba se neuskutečnila a nebyli jsme schopni opakovaně zúčtovat poplatek za vaše předplatné.",
  gatewayFee: "Klienti platí poplatky za bránu",
  gatewayFeeDescription:
    "Při zapnutí této volby budou klientům účtovány poplatky za platební bránu. Při vypnutí budou poplatky odečteny z výplat podniku.",
  enableGatewayFee: "Povolit poplatek za platební bránu klienta?",
  areYouSureYOuWantEnableGatewayFee:
    "Jste si jisti, že chcete povolit poplatek za platební bránu klienta?",
  disableGatewayFee: "Zakázat poplatek za platební bránu klienta?",
  areYouSureYOuWantDisableGatewayFee:
    "Jste si jisti, že chcete zakázat poplatek za platební bránu klienta? Poplatek bude odečten z vašich výplat!",
  communityTooltip:
    "Ujistěte se, že jste přidali Waylight Bota do své skupiny/kanálu jako správce, připojili svůj účet Telegram k Waylight a že se jedná o jedinou nabídku pro tento kanál!",
  communityCoverTooltip:
    "Nahrajte obrázek nebo video, které se zobrazí v popisu komunity.",
  setCard: "Nastavit",
  expirationDateMustBeInFuture: "Datum expirace musí být v budoucnosti",
  accessGranted: "Přístup povolen",
  noAccess: "Bez přístupu",
  manageCommunity: "Spravovat komunitu",
  manage: "Spravovat",
  January: "Leden",
  February: "Únor",
  March: "Březen",
  April: "Duben",
  May: "Květen",
  June: "Červen",
  July: "Červenec",
  August: "Srpen",
  September: "Září",
  October: "Říjen",
  November: "Listopad",
  December: "Prosinec",
  toastBuyingYourOwnCommunity: "Nemůžete se přihlásit do vlastní komunity!",
  priceLimitCommunity: "Cena může být od ${{minPrice}} do ${{maxPrice}}.",
  priceLimitCommunityForUA: "Cena může být od ₴{{minPrice}} do ₴{{maxPrice}}.",
  toastRetryPaymentSuccess: "Váš pokus o opětovnou platbu byl úspěšný.",
  toastCommunityLinkCopied: "Odkaz na komunitu zkopírován",
  communityPaymentFailed: "Platba za komunitu se nezdařila",
  AddExpirationDateManually: "Přidat datum expirace ručně:",
  SetTimeFormat: "Nastavit formát času",
  events: "Události",
  newEventOffer: "Nová nabídka události",
  newEventOfferDescription: "Vytvořte časově omezenou událost",
  eventName: "Název události",
  eventNameDescription:
    "Vyberte název pro vaši událost, např. „SEO Masterclass“",
  eventCoverTooltip: "Nápověda k obálce události",
  getEventPaid: "Dostáváte platbu za každý zakoupený sedadlo na této události",
  aboutTheEventOffer: "O události",
  dateAndTime: "Datum a čas",
  clickToSetDateAndTime: "Klikněte pro nastavení data a času",
  DescribeYourEvent: "Stručně popište svou událost.",
  onlineEvent: "Online událost",
  offlineEvent: "Offline událost",
  eventLinkDescription:
    "Přidejte informace o místě konání události, např. Zoom nebo Meet",
  uploadOptional: "Nahrát (volitelné)",
  uploadEventDetails:
    "Sem nahrajte soubor s volitelnými podrobnostmi o události",
  addInformationAboutLocation:
    "Přidejte informace o místě konání události nebo odkaz na mapu",
  location: "Odkaz na místo",
  address: "Adresa",
  addressOptional: "Adresa (volitelné)",
  addAddressEvent: "Přidejte adresu události",
  AllDayEvent: "Toto je celodenní událost.",
  toastBuyingYourOwnEvent: "You can not buy your own event!",
  event: "Událost",
  timeToEvent: "Čas do události",
  hoursEvent: "hodiny",
  seatsLeft: "zbývajících míst",
  follower: "Sledující",
  priceLimitEventForUA: "Cena může být od ₴{{minPrice}} do ₴{{maxPrice}}.",
  soldOut: "Vyprodáno!",
  joinEvents: "Účastnit se událostí",
  noEventFound: "Nebyly nalezeny žádné události",
  noEventsFoundDescription:
    "Zde se objeví, až se zúčastníte alespoň jedné události",
  editEvents: "Upravit události",
  attendForfree: "Zúčastnit se zdarma",
  disabled: "Vypnuto",
  enabled: "Zapnuto",
  personalCreditcard: "Osobní (Kreditní karta)",
  privateEntrepreneur: "Soukromý podnikatel (IBAN)",
  selectPayoutMethod: "Vyberte způsob výplaty",
  enterYourPersonalTaxCode: "Zadejte svůj osobní daňový kód*",
  createFondyMerchantAccount:
    "Vytvořte si obchodnický účet Fondy a dokončete proces získání přístupu:",
  create: "Vytvořit",
  participantsMin: "Minimální počet účastníků je 1",
  personalTaxCodeError: "Individuální daňový kód musí být platné číslo!",
  participantsError: "Počet účastníků musí být platné číslo!",
  peName: "Název OS",
  url: "Webová stránka",
  okpo: "OKPO",
  phone: "Telefon",
  peNameTooltip:
    "Oficiální název vašeho soukromého podnikání: 'Příjmení Jméno Křestní jméno'.",
  urlTooltip:
    "URL vašeho podnikání. Může to být váš web, Facebook, Instagram atd.",
  okpoTooltip: "OKPO kód vašeho soukromého podnikání.",
  phonePayoutTooltip: "Váš telefonní číslo.",
  paymentsTipStripe:
    "Platby jsou vždy povoleny pro uživatele Stripe. Výplaty jsou dostupné po dokončení registrace.",
  paymentsTipFondy1:
    "Soukromí podnikatelé: platby jsou povoleny po vytvoření obchodníka. Výplaty jsou dostupné po dokončení registrace.",
  paymentsTipFondy2:
    "Kreditní karty: platby a výplaty jsou povoleny po přidání kombinace soukromé kreditní karty a individuálního daňového kódu.",
  phoneError: "Telefon musí být platné číslo!",
  toastTaxCodeAdded: "Váš individuální daňový kód byl úspěšně přidán.",
  toastTaxCodeError:
    "Při pokusu o přidání vašeho individuálního daňového kódu došlo k chybě!",
  toastPECodeAdded:
    "Váš nový obchodník Fondy byl úspěšně vytvořen. Zkontrolujte svou emailovou schránku pro zahájení procesu integrace!",
  toastPECodeError:
    "Při pokusu o vytvoření nového obchodníka Fondy došlo k chybě!",
  onboarding: "Onboarding",
  notCompletedOnboarding: "Nedokončeno",
  completedOnboarding: "Dokončeno",
  completeOnboarding: "Dokončit onboarding",
  onboardingTooltip:
    "Dokončete onboarding v ovládacím panelu Fondy. Zkontrolujte svou emailovou schránku na pozvánku k vytvoření hesla, přihlaste se do ovládacího panelu Fondy, nahrajte požadované dokumenty a přidejte digitální podpis. Po schválení bude váš účet aktivován.",
  fondyMerchantAccountDetails: "Podrobnosti o účtu obchodníka Fondy",
  eventIsLife: "Událost je živě",
  created: "Zahájeno",
  processing: "Zpracování",
  goToFondyDashboard: "Otevřete svůj Fondy dashboard",
  youMustCompleteFondy:
    "Musíte dokončit registraci v Fondy pomocí emailového pozvánky, kterou jste obdrželi na:",
  chooseEventType: "Vyberte typ události",
  addException: "Přidat výjimku",
  manageScheduleExceptions: "Spravovat výjimky v harmonogramu",
  addRemoveSpecificTime:
    "Přidat nebo odebrat konkrétní čas, kdy jste dostupní nebo nedostupní.",
  exceptionType: "Typ výjimky:",
  available: "Dostupný",
  notAvailable: "Nedostupný",
  yes: "Ano",
  no: "Ne",
  removeException: "Odebrat výjimku",
  removeExceptionDesc: "Jste si jisti, že chcete odebrat výjimku {{time}}?",
  NoExceptionsAdded: "Nebyly přidány žádné výjimky",
  availableAllDay: "Budu celý den dostupný.",
  notAvailableAllDay: "Celý den nebudu dostupný.",
  exceptionDeleteSuccessfully: "Výjimka byla úspěšně odstraněna",
  exceptionDeleteError: "Vaše výjimka nemohla být odstraněna",
  exceptionSavedSuccessfully: "Výjimka byla úspěšně uložena",
  exceptionSavedError: "Vaše výjimka nemohla být uložena",
  addJoininstructions: "Přidat pokyny ke připojení",
  addJoininstructionsDesc:
    "Přidejte obsah nebo zprávu, kterou vaši klienti uvidí po zakoupení, když se ještě nepřipojili k kanálu",
  joinChannel: "Připojit se ke kanálu",
  communityUploadDescription: "Nahrát obrázek nebo video.",
  detailsOptional: "Podrobnosti (nepovinné)",
  type: "Typ",
  youCannotAddPast: "Nemůžete přidat časový úsek, který končí v minulosti",
  Sun: "Ned",
  Mon: "Pon",
  Tue: "Úte",
  Wed: "Stř",
  Thu: "Čtv",
  Fri: "Pát",
  Sat: "Sob",
  toastEventNotEdited: "Při pokusu o úpravu události došlo k chybě!",
  refunded: "vráceno",
  declined: "odmítnuto",
  verified: "ověřeno",
  transactionSuccessful: "Transakce úspěšná",
  myTabs: "Mé karty",
  tabStatus: "{{tabName}} jsou {{status}}",
  controlOfferTabs:
    "Typy nabídek, které si klienti mohou zobrazit na vaší hlavní stránce:",
  onlyEnglishLetters: "Povoleny jsou pouze anglická písmena a číslice",
  notificationCompleteOnboarding: "Dokončete Onboarding",
  notificationYourPaidOffersAreUnavailable:
    "Vaše placené nabídky nejsou dostupné pro vaše klienty.",
  products: "Produkty",
  bookingAvailable: "Dostupnost rezervací",
  bookingAvailableDescription:
    "Určete, zda jste obecně k dispozici k rezervaci.",
  availableKey: "K dispozici",
  notAvailableKey: "Není k dispozici",
  paymentOccurs: "Platba se vyskytuje každý",
  paymentOccursOneTime: "Jednorázová platba.",
  eventIsOver: "Událost skončila",
  integrations: "Integrace",
  externalAnalytics: "Externí analýza",
  externalAnalyticsDescription:
    "Nastavte své identifikátory účtů externí analýzy níže.",
  participantsManage: "Účastníci",
  manageEvent: "Spravovat událost",
  noEventsAdded: "Nebyly přidány žádné události",
  createEvent: "Vytvořit událost",
  shareEvent: "Sdílet událost",
  waylightProfile: "Profil Waylight",
  tickedId: "ID vstupenky",
  checkIn: "Check-in",
  checkedIn: "Přítomen",
  notCheckedin: "Nepřítomen",
  codeIsValid: "Kód je platný! Přítomen!",
  codeisInvalid: "Kód je neplatný! Zkuste to prosím znovu.",
  codeIsUsed: "Tento kód je již použit!",
  buyMore: "Koupit více",
  noParticipantsYet: "Zatím žádní účastníci",
  emptyDeteilsBuysTickets:
    "Zde se zobrazí, až někdo zakoupí vstupenky na vaši událost.",
  connectYourTelegram: "Připojte svůj účet Telegram pro přístup ke kanálu!",
  change: "Změna",
  numberOfTickets: "Počet lístků:",
  selectPaymentMethodTitle: "Vybrat způsob platby:",
  or: "- nebo -",
  addACard: "Přidat kartu",
  subscriptionLevels: "Úrovně předplatného",
  subscriptionLevelsTooltip:
    "Přidejte až 3 úrovně k předplatnému vaší komunity. Například: měsíčně, čtvrtletně a ročně.",
  levelName: "Název úrovně",
  starter: "Začátečník",
  pro: "Profesionál",
  levelDiscription: "Název úrovně zobrazený klientům",
  period: "Období",
  periodDiscription:
    "Klienti budou účtováni pro zachování předplatného v tomto intervalu",
  priceDiscription: "Dostáváte platbu v určených intervalech",
  removeLevel: "Odstranit úroveň",
  addAnotherLevel: "Přidat další úroveň",
  noTrial: "Bez zkušební doby",
  trialDiscription: "Počet dnů přístupu, který dostanou vaši klienti zdarma",
  trialPeriod: "Zkušební doba:",
  selectSubscription: "Vybrat předplatné:",
  subscription: "Předplatné",
  removeLevelDescription: "Opravdu chcete odstranit úroveň?",
  tryForFree: "Vyzkoušejte zdarma",
  startTrial: "Zahájit trial",
  subscribedSuccessfully: "Přihlášení bylo úspěšné",
  edit: "Upravit",
  expirationColumName: "platnost",
  kick: "Vykopnout",
  trial: "Zkušební",
  notJoined: "Nepřipojeno",
  telegramProfile: "Telegram profil",
  notAuthorized: "Neautorizován",
  noMembersFoundFilter:
    "Podle nastaveného filtru nebyli nalezeni žádní členové.",
  tryADifferentFilter: "Zkuste jiný filtr nebo hledací vstup.",
  "not joined": "Nepřipojeni",
  "not authorized": "Neautorizován",
  membership: "Členství",
  priceDiscriptionEvents: "Získáte platbu za každý nákup sedadel na tomto akci",
  fanZone: "Zóna fanoušků",
  vipZone: "VIP zóna",
  selectTickets: "Vyberte vstupenky:",
  attendance: "Návštěvnost",
  eventLevels: "Úrovně události",
  noTicketsSelected: "Nevybrány žádné vstupenky",
  SetMaximumNumberOfSeats: "Nastavte maximální počet míst pro tento stupeň",
  participants: "Počet míst",
  import: "Import",
  export: "Export",
  labelNameDescription: "Vyberte štítek pro vaše importovaná data",
  hidden: "skryté",
  upload: "Nahrát",
  labelName: "Název štítku",
  fileIsRequired: "Musíte nahrát soubor!",
  code: "kód",
  level: "Úroveň",
  label: "Štítek",
  exportFileError: "Export se nezdařil!",
  exportFileSuccess: "Soubor úspěšně exportován.",
  importFileSuccess: "Soubor úspěšně importován.",
  importFileError: "Import se nezdařil!",
  statsSuccess: "Statistiky registrace úspěšně načteny.",
  statsError: "Při načítání statistiky registrace došlo k chybě!",
  uploadAText: "Prostý text nebo soubor csv do 50 MB",
  onlyTextFilesAllowed: "Jsou povoleny pouze soubory textu nebo csv!",
  toastCommunityNotEdited: "Při úpravě komunity došlo k chybě",
  toastYouCanSelectOnlyImage: "Můžete vybrat pouze jeden obrázek pro záhlaví",
  sections: "Sekce",
  logInOrSignup: "Přihlaste se nebo se zaregistrujte na Waylight níže",
  continue: "Pokračovat",
  invalidEmail: "Neplatný email",
  enterCode: "Zadat kód",
  haveSentCodeTo: "Odeslali jsme kód na",
  paste: "Vložit",
  resendCodeIn: "Znovu odeslat kód... (za {{time}} sekund)",
  resendCode: "Znovu odeslat kód",
  waylight: "Waylight",
  pleaseChooseCountry: "Prosím, vyberte zemi vaší banky nebo finanční služby.",
  buyAccessFor: "Koupit přístup za",
  thankYouForFollowing: "Děkujeme za sledování!",
  incorrectCode: "Kód je nesprávný nebo vypršel!",
  enterEmailToContinue: "Zadejte email pro pokračování",
  city: "Město",
  social: "Sociální",
  earned: "celkem",
  priceLimitARS:
    "Cena může být ARS$ 0, nebo ARS$ {{minPrice}} až ARS$ {{maxPrice}}.",
  priceLimitAUD: "Cena může být A$ 0, nebo A$ {{minPrice}} až A$ {{maxPrice}}.",
  priceLimitEUR: "Cena může být €0, nebo €{{minPrice}} až €{{maxPrice}}.",
  priceLimitBOB:
    "Cena může být Bs. 0, nebo Bs. {{minPrice}} až Bs. {{maxPrice}}.",
  priceLimitBGN: "Cena může být лв 0, nebo лв {{minPrice}} až лв {{maxPrice}}.",
  priceLimitCAD:
    "Cena může být CA$ 0, nebo CA$ {{minPrice}} až CA$ {{maxPrice}}.",
  priceLimitCLP:
    "Cena může být CL$ 0, nebo CL$ {{minPrice}} až CL$ {{maxPrice}}.",
  priceLimitCOP:
    "Cena může být COL$ 0, nebo COL$ {{minPrice}} až COL$ {{maxPrice}}.",
  priceLimitCRC: "Cena může být ₡0, nebo ₡{{minPrice}} až ₡{{maxPrice}}.",
  priceLimitHRK: "Cena může být kn 0, nebo kn {{minPrice}} až kn {{maxPrice}}.",
  priceLimitCZK: "Cena může být Kč 0, nebo Kč {{minPrice}} až Kč {{maxPrice}}.",
  priceLimitDKK: "Cena může být kr 0, nebo kr {{minPrice}} až kr {{maxPrice}}.",
  priceLimitDOP:
    "Cena může být RD$ 0, nebo RD$ {{minPrice}} až RD$ {{maxPrice}}.",
  priceLimitHKD:
    "Cena může být HK$ 0, nebo HK$ {{minPrice}} až HK$ {{maxPrice}}.",
  priceLimitEGP:
    "Cena může být EGP 0, nebo EGP {{minPrice}} až EGP {{maxPrice}}.",
  priceLimitHUF: "Cena může být Ft 0, nebo Ft {{minPrice}} až Ft {{maxPrice}}.",
  priceLimitISK: "Cena může být kr 0, nebo kr {{minPrice}} až kr {{maxPrice}}.",
  priceLimitINR: "Cena může být ₹0, nebo ₹{{minPrice}} až ₹{{maxPrice}}.",
  priceLimitIDR: "Cena může být Rp 0, nebo Rp {{minPrice}} až Rp {{maxPrice}}.",
  priceLimitILS: "Cena může být ₪0, nebo ₪{{minPrice}} až ₪{{maxPrice}}.",
  priceLimitUYU: "Cena může být $U 0, nebo $U {{minPrice}} až $U {{maxPrice}}.",
  priceLimitGBP: "Cena může být £0, nebo £{{minPrice}} až £{{maxPrice}}.",
  priceLimitTRY: "Cena může být ₺0, nebo ₺{{minPrice}} až ₺{{maxPrice}}.",
  priceLimitTTD:
    "Cena může být TT$ 0, nebo TT$ {{minPrice}} až TT$ {{maxPrice}}.",
  priceLimitTHB: "Cena může být ฿0, nebo ฿{{minPrice}} až ฿{{maxPrice}}.",
  priceLimitCHF:
    "Cena může být CHF 0, nebo CHF {{minPrice}} až CHF {{maxPrice}}.",
  priceLimitSEK: "Cena může být kr 0, nebo kr {{minPrice}} až kr {{maxPrice}}.",
  priceLimitZAR: "Cena může být R 0, nebo R {{minPrice}} až R {{maxPrice}}.",
  priceLimitSGD:
    "Cena může být SGD$ 0, nebo SGD$ {{minPrice}} až SGD$ {{maxPrice}}.",
  priceLimitSAR:
    "Cena může být SAR 0, nebo SAR {{minPrice}} až SAR {{maxPrice}}.",
  priceLimitRON:
    "Cena může být lei 0, nebo lei {{minPrice}} až lei {{maxPrice}}.",
  priceLimitPLN: "Cena může být zł 0, nebo zł {{minPrice}} až zł {{maxPrice}}.",
  priceLimitPHP: "Cena může být ₱ 0, nebo ₱ {{minPrice}} až ₱ {{maxPrice}}.",
  priceLimitPEN:
    "Cena může být S/. 0, nebo S/. {{minPrice}} až S/. {{maxPrice}}.",
  priceLimitPYG: "Cena může být ₲ 0, nebo ₲ {{minPrice}} až ₲ {{maxPrice}}.",
  priceLimitNOK: "Cena může být kr 0, nebo kr {{minPrice}} až kr {{maxPrice}}.",
  priceLimitNZD:
    "Cena může být NZ$ 0, nebo NZ$ {{minPrice}} až NZ$ {{maxPrice}}.",
  priceLimitMXN:
    "Cena může být MX$ 0, nebo MX$ {{minPrice}} až MX$ {{maxPrice}}.",
  priceLimitKES:
    "Cena může být KSh 0, nebo KSh {{minPrice}} až KSh {{maxPrice}}.",
  linkInBIOtool: "Nástroj Link in BIO",
  thatHelpsYouEarn: "který vám pomáhá vydělávat!",
  earnMore: "Vydělávejte více",
  withWaylight: "s Waylight:",
  descriptionEarn:
    "Vydělejte si 10krát více na svém příjmu ze sociálních médií!",
  descriptionEarnPart: "Spusťte za pouhé 2 minuty!",
  earnOnPaidCommunities: "Vydělávejte na placených komunitách.",
  createAndManage:
    "Vytvářejte a spravujte své komunity s předplatným v Telegramu.",
  EarnPaidDigital: "Vydělávejte na placených digitálních produktech.",
  hostAndManage:
    "Pořádejte a spravujte kurzy, tutoriály, e-knihy, průvodce, soubory atd.",
  earnOnline: "Vydělávejte na online a osobních událostech.",
  sellTicketsAndManage:
    "Prodávejte vstupenky a spravujte své online a osobní skupinové události.",
  EarnServices: "Vydělávejte na placených službách.",
  sellManage: "Prodávejte a spravujte tok svých služeb.",
  earnOnlineVideoSessions: "Vydělávejte na placených online video sezeních.",
  ScheduleBookingsOnlineVideo:
    "Nastavte rozvrh a získávejte rezervace na online video sezeních se svými klienty.",
  EarnAppointments: "Vydělávejte na placených osobních schůzkách.",
  ScheduleSignups:
    "Nastavte rozvrh a získávejte přihlášky na své osobní nebo skupinové schůzky 1 na 1.",
  earnTipping: "Vydělávejte na spropitném.",
  getTippingSupport:
    "Získejte spropitné ve prospěch svých kanálů nebo za sdílení obsahu na sociálních sítích.",
  youWilGet: "Získáte",
  tools: "1. Nástroje",
  sales: "2. Prodeje",
  clientsGet: "3. Klienti",
  howItWorks: "Jak to funguje",
  GetClients: "Získejte nové prodeje a klienty",
  useWaylightGrowth:
    "Používejte nástroje a rady růstového motoru Waylight k většímu prodeji a získávání nových klientů ze sociálních sítí.",
  createWaylight: "Vytvořte Waylight",
  creatorsExperts: "Tvůrci a experti",
  interactionsMonthly: "Měsíční interakce",
  monthOverMonth: "Měsíc k měsíci",
  forWhom: "Pro koho:",
  pricing: "Ceny:",
  chooseYourPlan: "Vyberte si svůj plán",
  saveWhenChoosingYearlyPlan: "Ušetříte více při výběru ročního plánu!",
  monthlyBilling: "Měsíční fakturace",
  annuallyBilling: "Roční fakturace",
  freePlan: "Zdarma",
  startLowestCosts: "Začněte s nejnižšími náklady a prozkoumejte Waylight",
  mo: "/měs.",
  signUp: "Přihlásit se",
  impressionMo: "{{comission}}% poplatky za transakci.",
  basicToolsIncluded: "Základní nástroje jsou zahrnuty!",
  basic: "Základní",
  getEverythingBasic:
    "Získejte vše v zdarma verzi s nižšími transakčními poplatky",
  contactSales: "Kontaktujte prodejce",
  transactionFees: "2% transakční poplatky.",
  publishingMarketplace: "Publikování ve vyhledávání tržiště.",
  saveBadge: "Ušetřete {{percent}}%",
  bestValue: "Nejlepší hodnota",
  GetMoreSales:
    "Získejte více prodejů ze sociálních sítí bez transakčních poplatků",
  ziroTransactionFees: "0% transakční poplatky.",
  proToolsIncluded: "Profesionální nástroje jsou zahrnuty!",
  customizations: "Přizpůsobení.",
  verifiedSign: "Ověřený podpis.",
  prioritySupport: "Podpora s prioritou.",
  launchExpert: "K projektu připojen odborník na spuštění.",
  askTelegram: "Zeptejte se na Telegramu",
  askWhatsApp: "Zeptejte se na WhatsAppu",
  askEmail: "Zeptejte se přes e-mail",
  MakeTenX: "Získejte 10krát více",
  onYourSocialMedia: "na svých sociálních médiích",
  revenue: "příjem! 🎉",
  launchJust: "Spusťte za pouhé",
  twoMinutes: "2 minuty!",
  product: "Produkt:",
  customersDataManagement: "Správa dat o zákaznících",
  reviewsProduct: "Recenze",
  portfolioProduct: "Portfólio",
  businessWebsite: "Webové stránky podniku",
  securePayments: "Bezpečné platby",
  tipping: "Spropitné",
  paidAppointments: "Placené osobní schůzky",
  paidVideoSessions: "Placené online video sezení",
  paidServices: "Placené služby",
  ticketsOnlineEvents: "Vstupenky na online události",
  ticketsScanner: "Skenování vstupenek",
  ticketsPersonEvents: "Vstupenky na osobní události",
  paidAccessCourses: "Placený přístup k kurzům",
  paidAccessDigitalProducts: "Placený přístup k digitálním produktům",
  paidGroupChannelAccessTelegram:
    "Placený přístup ke skupinám a kanálům na Telegramu",
  paidSubscriptionCommunitiesTelegram:
    "Placené předplatné komunity na Telegramu",
  productMenuItem: "Produkt",
  PricingMenuItem: "Ceny",
  Creator: "Tvůrce",
  Freelancer: "Freelancer",
  Tutor: "Tutor",
  Coach: "Trenér",
  Influencer: "Influencer",
  ask: "Zeptejte se",
  anyQuestions: "jakékoliv otázky zde:",
  how: "Jak",
  itWorks: "to funguje",
  businessflowStep1new:
    "Přidejte svůj odkaz Waylight do BIO sociálních médií a začněte oslovovat své publikum a klienty sdílením příběhů a příspěvků o něm.",
  businessflowStep2new:
    "Použijte nástroje a rady růstového motoru Waylight k zvýšení prodejů a získání nových klientů ze sociálních médií.",
  businessflowStep3new:
    "Získejte platbu za své komunity, digitální produkty, online a offline události, spropitné, osobní a video relace atd.",
  businessCalculatorNew: "Kalkulátor příjmů Waylight",
  howMuchMoneyNew: "Zjistěte, kolik peněz by vám přinášeli odběratelé.",
  estimateNumberNew: "1. Odhadněte počet klientů a odběratelů",
  howMuchYouChargeNew: "2. Kolik můžete účtovat za nabídku",
  for: "Pro",
  whom: "koho",
  getEverythingBasicNew:
    "Získejte vše v základní verzi s nižším úrovní transakčních poplatků",
  startLowestCostsNew:
    "Začněte bez předem stanovených nákladů a prozkoumejte Waylight",
  GetMoreSalesNew:
    "Získávejte více prodejů ze svých sociálních médií bez transakčních poplatků",
  onYourSocialMediaNew: "na svých příjmech ze sociálních médií! 🎉",
  accordionv2label0: "V jakých zemích pracujete?",
  accordionv2text0:
    "Waylight Business působí ve více než 55 zemích na 5 kontinentech. Můžete se obrátit na naši podporu ohledně vaší země, nebo jednoduše zkusit se zaregistrovat a zjistit to. Klienti mohou platit za vaše nabídky ze 150+ zemí světa.",
  accordionv2label1: "Jak budu placen?",
  accordionv2text1:
    "Výplaty dostanete na svůj bankovní účet každé pondělí. Připojení vašeho bankovního účtu k Waylight Business trvá méně než 1 minutu.",
  accordionv2label2: "Které nástroje jsou zahrnuty v ceně?",
  accordionv2text2:
    "To závisí na plánu, který si vyberete, ale v každém případě získáte přístup k plným obchodním tokům monetizace Waylight, jako jsou placené komunity v telegramu, vstupenky, digitální produkty, kurzy, schůzky, video relace, spropitné, firemní webové stránky, údaje klienta, prodejní motory, akademie monetizace atd.",
  accordionv2label3: "Máte Apple Pay, Google Pay, PayPal?",
  accordionv2text3:
    "Ano, máme. Nazýváme to „Smooth Checkout“. Waylight pracuje na automatizaci prodejních toků s vyššími konverzními sazbami, takže máme jedno kliknutí na pokladně (s PayPal v plánu).",
  accordionv2label4: "Jak mi Waylight pomůže zvýšit prodeje o 10x?",
  accordionv2text4:
    "Získáte přístup k motoru růstu prodejů a akademii monetizace, které vám pomohou prodávat více a získávat více klientů ze sociálních médií. Navíc Waylight již vytvořil automatizované toky a nástroje, které vám poskytují vyšší konverzní sazby.",
  accordionv2label5: "Co potřebuji pro začátek?",
  accordionv2text5:
    "Je to snadné 👌 Zaregistrujte se, přidejte svůj bankovní účet, vytvořte nabídky, připojte se k akademii monetizace, přidejte odkazy na sociální média a začněte vydělávat stále více peněz.",
  connectWaylight: "Připojte se na Waylight!",
  toastTooLargeFileTwilio:
    "Tento soubor je příliš velký. Maximální velikost souboru je 150 MB.",
  enableSold: "Povolit indikátory prodeje nabídek?",
  areYouSureYOuWantEnableSold:
    "Opravdu chcete povolit indikátory prodeje nabídek? Hodnoty prodeje budou viditelné pro všechny uživatele.",
  disableSold: "Zakázat indikátory prodeje nabídek?",
  areYouSureYOuWantDisableSold:
    "Opravdu chcete zakázat indikátory prodeje nabídek?",
  soldEnabled: "Indikátory prodeje nabídek jsou povoleny",
  soldEnabledDescription:
    "Když je tato možnost povolena, hodnoty prodeje jsou viditelné pro všechny uživatele.",
  tipOrCommunitypriceLimitARS: "Cena může být ARS$ 1879.31 až ARS$ 9396554.46.",
  tipOrCommunitypriceLimitAUD: "Cena může být A$ 3.01 až A$ 15097.1 .",
  tipOrCommunitypriceLimitEUR: "Cena může být €1.81 až €9070.",
  tipOrCommunitypriceLimitBOB: "Cena může být Bs. 13.81 až Bs. 69119.48.",
  tipOrCommunitypriceLimitBGN: "Cena může být лв 3.55 až лв 17750.81.",
  tipOrCommunitypriceLimitCAD: "Cena může být CA$ 2.74 až CA$ 13718.98.",
  tipOrCommunitypriceLimitCLP: "Cena může být CL$ 1864.56 až CL$ 9321644.2.",
  tipOrCommunitypriceLimitCOP:
    "Cena může být COL$ 8072.11 až COL$ 40360576.84.",
  tipOrCommunitypriceLimitCRC: "Cena může být ₡1055.02 až ₡5277582.56.",
  tipOrCommunitypriceLimitHRK: "Cena může být kn 36 až kn 70472.",
  tipOrCommunitypriceLimitCZK: "Cena může být Kč 45.71 až Kč 228558.",
  tipOrCommunitypriceLimitDKK: "Cena může být kr 13.54 až kr 67719.",
  tipOrCommunitypriceLimitDOP: "Cena může být RD$ 295 až RD$ 588928.",
  tipOrCommunitypriceLimitHKD: "Cena může být HK$ 15.58 až HK$ 77915.",
  tipOrCommunitypriceLimitEGP: "Cena může být EGP 154.75 až EGP 309501.",
  tipOrCommunitypriceLimitHUF: "Cena může být Ft 716 až Ft 3579217.",
  tipOrCommunitypriceLimitISK: "Cena může být kr 684 až kr 1369300.",
  tipOrCommunitypriceLimitINR: "Cena může být ₹167.89 až ₹839435.",
  tipOrCommunitypriceLimitIDR: "Cena může být Rp 78852 až Rp 157703833.",
  tipOrCommunitypriceLimitILS: "Cena může být ₪19 až ₪36657.",
  tipOrCommunitypriceLimitUYU: "Cena může být $U 81 až $U 402925.",
  tipOrCommunitypriceLimitGBP: "Cena může být £1.56 až £7779.95.",
  tipOrCommunitypriceLimitTRY: "Cena může být ₺67.06 až ₺335289.19.",
  tipOrCommunitypriceLimitTTD: "Cena může být TT$ 13.58 až TT$ 67917.76.",
  tipOrCommunitypriceLimitTHB: "Cena může být ฿69.82 až ฿349109.6.",
  tipOrCommunitypriceLimitCHF: "Cena může být CHF 1.73 až CHF 8647.",
  tipOrCommunitypriceLimitSEK: "Cena může být kr 20.82 až kr 104092.39.",
  tipOrCommunitypriceLimitZAR: "Cena může být R 94 až R 186359.",
  tipOrCommunitypriceLimitSGD: "Cena může být SGD$ 2.63 až SGD$ 13146.69.",
  tipOrCommunitypriceLimitSAR: "Cena může být SAR 19 až SAR 37496.",
  tipOrCommunitypriceLimitRON: "Cena může být lei 9.04 až lei 45196.94.",
  tipOrCommunitypriceLimitPLN: "Cena může být zł 7.8 až zł 39008.41.",
  tipOrCommunitypriceLimitPHP: "Cena může být ₱ 281 až ₱ 560255.",
  tipOrCommunitypriceLimitPEN: "Cena může být S/. 19 až S/. 38040.",
  tipOrCommunitypriceLimitPYG: "Cena může být ₲ 36387 až ₲ 72772680.",
  tipOrCommunitypriceLimitNOK: "Cena může být kr 21.38 až kr 106903.",
  tipOrCommunitypriceLimitNZD: "Cena může být NZ$ 3.33 až NZ$ 16646.",
  tipOrCommunitypriceLimitMXN: "Cena může být MX$ 37.92 až MX$ 189595.",
  tipOrCommunitypriceLimitKES: "Cena může být KSh 803 až KSh 1605000.",
  authorizeToAccess: "Musíte autorizovat přístup",
  noCommunitiesFound: "Nebyly nalezeny žádné komunity",
  noCommunitiesFoundDescription:
    "Zobrazí se zde, když zakoupíte nebo přidáte komunitu",
  following: "Následující",
  appointments: "Schůzky",
  share: "Sdílet",
  addOffer: "Přidat nabídku",
  aboutMe: "O mně",
  estimatedAmount:
    "Celková částka úspěšných převodů na váš připojený účet Stripe ve výchozí měně.",
  ConnectYourTelegram: "Připojte svůj Telegram:",
  ConnectYourTelegramAccount:
    "Připojte svůj účet na Telegramu a přijímejte oznámení od tvůrců, které sledujete.",
  connectTelegram: "Připojit Telegram",
  buyAgain: "Koupit znovu",
  purchases: "Zakoupeno",
  videoGuide: "Videonávod",
  businessBilling: "Fakturace pro podniky",
  noPaymentMethodsAdded: "Nebyly přidány žádné platební metody",
  yourPaymentMethods: "Vaše platební metody",
  yourPlan: "Váš plán",
  yearlyBilling: "Roční fakturace",
  selectFree: "Vybrat zdarma",
  whatIncluded: "Co je zahrnuto",
  selectStarter: "Vybrat základní",
  selectPro: "Vybrat profesionální",
  enterPromoCode: "Zadejte promo kód:",
  apply: "Použít",
  selected: "Vybráno",
  waylightSubscription: "Předplatné Waylight",
  cashbackRefferals: "Odkazy",
  referrals: "Partneři",
  shareLink: "Sdílet odkaz",
  howReferralsWork: "Jak fungují odkazy",
  cashback: "Cashback",
  yourCashbackCode: "Váš kód cashbacku",
  yourCustomPartnerReferralCode: "Váš vlastní kód partnera",
  howCashbackWork: "Jak funguje cashback",
  toastPromoCodeSuccess: "Váš promo kód byl úspěšně použit!",
  toastPromoCodeError: "Při používání vašeho promo kódu došlo k chybě!",
  enterPromoCodeSignUp: "Zadejte promo kód (volitelně):",
  enterPromoCodeBtn: "+ Zadat promo kód",
  billingBusinessTooltip: "Vyberte svůj plán níže.",
  referralTooltip: "Toto je váš jedinečný kód cashbacku.",
  cashbackTooltip: "Toto je váš jedinečný kód partnera.",
  currentPlan: "Aktuální plán:",
  neverExpires: "Nikdy",
  expires: "Vyprší:",
  emptyBillingBusinessHistoryDescription:
    "Prázdný popis historie obchodní fakturace",
  notificationGoToBillingBusiness:
    "Narazili jsme na problém s platbou vaší předplatné! ",
  WeHaveEncounteredAnIssueSubscription:
    "Narazili jsme na problém s platbou vaší předplatné",
  checkIfYouHaveSufficientSubscription:
    "Zkontrolujte, zda máte dostatečné prostředky na svém účtu nebo zda jsou povoleny internetové platby.",
  selectDifferentPaymentMethodSubscription: "Vyberte jinou platební metodu.",
  referralsDescriptions1:
    "Děkujeme, že jste naším ceněným partnerským partnerem!",
  referralsDescriptions2:
    "Dokončete zprovoznění, abyste dostali své provize za odkazy. Vaše peníze u nás jsou v bezpečí, dokud se nepřipojíte.",
  referralsDescriptions3:
    "Budete potřebovat údaje o svém bankovním účtu nebo údaje o kartě podle vaší země.",
  referralsDescriptions4:
    "Platby se provádějí každé pondělí. Můžete kontrolovat stav platby v transakcích.",
  referralsDescriptions5:
    "Váš partnerský kód poskytuje dodatečné slevy na předplatné Waylight.",
  referralsDescriptions6:
    "Vaše slevy a sazby sdílení příjmů jsou upraveny na základě naší dohody.",
  referralsDescriptions7:
    "Pokud je to poprvé, co dostáváte platbu, může trvat 7-14 pracovních dnů, než dorazí kvůli omezením brány. Obvykle platby trvají asi 3 pracovní dny.",
  cashbackDescriptions1:
    "Děkujeme vám, že jste členem naší programu cashbacku!",
  cashbackDescriptions2:
    "Dokončete zprovoznění, abyste dostali své provize za odkazy. Vaše peníze u nás jsou v bezpečí, dokud se nepřipojíte.",
  cashbackDescriptions3:
    "Budete potřebovat údaje o svém bankovním účtu nebo údaje o kartě podle vaší země.",
  cashbackDescriptions4:
    "Platby se provádějí každé pondělí. Můžete kontrolovat stav platby v transakcích.",
  cashbackDescriptions5:
    "Sdílejte svůj odkaz a získávejte sdílení příjmů od nových zákazníků.",
  cashbackDescriptions6:
    "Vaše cashback je 20-30% z všech poplatků Waylight po dobu 1 roku (pouze 30% z ročních předplatných Pro).",
  cashbackDescriptions7:
    "Pokud je to poprvé, co dostáváte platbu, může trvat 7-14 pracovních dnů, než dorazí kvůli omezením brány. Obvykle platby trvají asi 3 pracovní dny.",
  invalidGTMiD:
    "Neplatné ID GTM. Zadejte pouze své ID, ne kód, například: GTM-AA11BB2.",
  invalidMetaPixelID:
    "Neplatné ID Meta Pixel. Zadejte pouze své ID, ne kód, například: 123456789012345.",
  invalidHotjarID:
    "Neplatné ID Hotjar. Zadejte pouze své ID, ne kód, například: 1112223.",
  accountCreated: "Účet vytvořen",
  toastOUrlCopied: "Odkaz na odkaz byl úspěšně zkopírován!",
  YouAreOnFreePlan: "Momentálně jste již na bezplatném plánu",
  referralCodeCanNotBeUsed:
    "Tento referenční kód nelze použít pro vaši současnou zemi účtu: {{country}}",
  createWaylightBusiness: "Vytvořte podnik Waylight",
  pleaseChooseCountryBank:
    "Vyberte zemi vašeho bankovního účtu nebo finanční služby:",
  referralsDescriptionsUA4:
    "Platby se provádějí po každé transakci. Stav platby můžete kontrolovat v transakcích.",
  referralsDescriptionsUA7:
    "Platby se provádějí nejpozději do dalšího pracovního dne.",
  cashbackDescriptionsUA4:
    "Platby se provádějí po každé transakci. Stav platby můžete kontrolovat v transakcích.",
  cashbackDescriptionsUA7:
    "Platby se provádějí nejpozději do dalšího pracovního dne.",
  issueSubscriptionPayment:
    "Narazili jsme na problém s platbou vaší předplatné!",
  revenueSharhingSelectPE:
    "Referenční program je k dispozici pouze pro aktivované účty PE na Ukrajině.",
  youCanChangeYourAccount: "Můžete změnit typ účtu v nastavení.",
  yourReferralCodeWillAppear:
    "Váš referenční kód se zde zobrazí, jakmile dokončíte onboarding PE.",
  goToPayoutSettings: "Přejít na nastavení výplat",
  commission: "Poplatek",
  youAreOnSamePlan:
    "Momentálně jste na stejném plánu, který se snažíte vybrat! Prosím, vyberte jiný plán.",
  onlyCyrillicLettersAllowed: "Povoleny jsou pouze cyrilice",
  dragToChangePosition: "Přetáhněte pro změnu pozice",
  eventsQrcode: "Krátké kódy událostí",
  eventsQrcodeDescription:
    "Když je tato možnost zapnutá, vaše vstupenky na události budou generovány s krátkými 4místnými PINy a QR kódy. Když je vypnuto, budou generovány bezpečnější 8místné PINy s čárovými kódy.",
  areYouSureYOuWantEnableQrcode:
    "Jste si jisti, že chcete povolit krátké PINy událostí s QR kódy?",
  areYouSureYOuWantDisableQrcode:
    "Jste si jisti, že chcete zakázat krátké PINy událostí a generovat místo toho čárové kódy?",
  enableEventsQrcode: "Povolit krátké kódy událostí",
  disableEventsQrcode: "Zakázat krátké kódy a přepnout na čárové kódy",
  authorizeScanner: "Autorizovat skener",
  toastScannerUrlCopied: "URL skeneru zkopírováno",
  total: "Celkem:",
  gtmIdDescription: "Místní GTM pro tuto nabídku.",
  metaPixelIdDescription: "Místní Meta Pixel pro tuto nabídku.",
  tutorialBoughtPreview: "Toto je náhled zakoupeného stavu vašeho produktu.",
  tutorialNotBoughtPreview:
    "Takto vidí klienti váš produkt, když není zakoupen.",
  authorizeToAccessPurchases:
    "Prosím, autorizujte se, abyste mohli přistupovat k vašim nákupům!",
  myPromotions: "Můj promo kód",

  describeTheDetailsPromotions:
    "Vyberte nabídku, uveďte název, vyberte URL a přidejte detaily akce níže",
  promotionDropdownDescription:
    "Vyberte svou nabídku ze seznamu. Později ji pro tuto akci nebudete moci změnit.",
  offerNamePromotions: "Nabídka",
  promotionsTooltip:
    "Vyberte nabídku, pro kterou bude tato akce platit. Můžete vytvořit tolik dalších akcí pro tuto nabídku, kolik budete chtít.",
  offerNameDescriptionPromotions:
    "Zadejte název akce. To je jen pro vaše interní účely.",
  codePromotionsDescription:
    "Zadejte jedinečný promo kód pomocí anglických písmen a čísel. Zkuste magické tlačítko generování, pokud chcete!",
  expirationPromotions: "Platnost",
  name: "Název",
  codePromotions: "Kód",
  usersNumDescription: "Tento promo kód lze použít uvedený početkrát.",
  max_uses: "Počet použití",
  pricingPromotionsDiscription: "pricingPromotionsDiscription",
  discount: "Sleva",
  discountTitle: "Sleva(y):",
  selectOfferDiscounts:
    "Vyberte nabídku výše a začněte spravovat slevy pro ni.",
  promoNameCanNotBeEmpty: "Název promo akce nesmí být prázdný",
  urlIsAlreadyTaken: "Tato URL je již zabrána!",
  promoCodeValidation:
    "Promo kód může obsahovat pouze velká a malá anglická písmena a číslice od 0 do 9!",
  promotions: "Akce",
  enterPromoCodePromotions: "Zadejte promo kód",
  promoCodeIncorrectExpired: "Tento promo kód je nesprávný nebo vypršel!",
  selectedPromoCodeNotAvailable: "Vybraný promo kód není k dispozici!",
  weWereUnableToProcessYourTransaction:
    "Nepodařilo se nám zpracovat vaši transakci s vybraným promo kódem kvůli vypršení jeho platnosti, omezení použití nebo nedostupnosti. Prosím, zahajte nákup znovu.",
  promotionsEmptyStateHeader:
    "Podle nastaveného filtru nebyly nalezeny žádné akce.",
  promotionsEmptyStateDescription:
    "Vytvořte akce, zkuste jiný filtr nebo vyhledávací dotaz.",
  toastPromotionUrlCopied: "URL akce zkopírována!",
  toastPromotionCodeCopied: "Promo kód zkopírován!",
  managePromotionsForYourOffers: "Spravujte akce pro své nabídky",
  offerPromotions: "Nabídka akce",
  deletePromotionDescription: "Jste si jisti, že chcete smazat propagaci?",
  deletePromotionTitle: "Smazat propagaci",
  addPromotion: "Přidat propagaci",
  toastPromotionNotEditedEnabled: "Nepodařilo se nám upravit vaši propagaci!",
  toastPromotionNotEdited: "Nepodařilo se nám upravit vaši propagaci!",
  usage: "Použito",
  discountedPriceError: "Snížená cena nemůže být nižší než ekvivalent 2 USD!",
  priceWithDiscount: "Cena se slevou",
  promoCodeAddedToast: "Promo kód byl úspěšně použit!",
  toastPromotionSavedSuccessfully: "Promo kód byl úspěšně vytvořen",
  toastPromotiomNotAdded: "Při vytváření vaší propagace došlo k chybě!",
  myPromotionsMenu: "Moje propagace",
  setSubscriptionlevelManually: "Nastavit úroveň předplatného ručně:",
  settingLevelManuallyNotifyClientAutomatically:
    "Nastavení úrovně ručně automaticky upozorní klienta a použije se při dalším účtování {{expiration}}",
  subscribeForFree: "Přihlásit se zdarma",
  toastSavedSuccessfullyUpdatedLevelCommunity:
    "Úroveň komunity byla úspěšně uložena a aktualizována.",
  toastCommunityLevelNotUpdated: "Úroveň komunity nebyla aktualizována.",
  addNewCardStripe: "Přidat novou kartu (Stripe):",
  creditCard: "Kreditní karta",
  promocode: "Promocode",
  nonFollowers: "Následovníci",
  dueOn: "K zadání",
  exportMyClientsSuccess: "Export klientů byl úspěšně dokončen.",
  exportMyClientsError: "Chyba při exportu klientů.",
  eventTelegramDescription:
    "Vyberte název svého kanálu nebo skupiny. Pokud zde nic nevidíte, ujistěte se, že jste přidali Waylight Bot do své skupiny nebo kanálu jako správce a toto je jediná nabídka pro tento kanál!",
  onlineTelegramEvent: "Online telegramová událost",
  joinEvent: "Připojit se k události",
  levels: "Úrovně",
  paidAccess: "Placený přístup",
  clientWillPayOnceAccess: "Klient zaplatí jednou za přístup",
  noOffersFound: "Nenalezeny žádné nabídky",
  noOffersFoundDescription:
    "Objeví se zde, když zakoupíte nebo přidáte nabídku",
  youCanNotManageEnabled:
    "No tienes ninguna oferta habilitada que puedas gestionar.",
  enableOffersForManagement:
    "Habilita sesiones, eventos o comunidades para gestionarlas aquí.",
  specifyQuickSessionPriceDuration: "Uveďte cenu a délku rychlé relace",
  youGetPaidSpecifiedPriceForFixedAmountTime:
    "Za pevně stanovený čas dostanete zaplaceno určenou částku.",
  videoCallQuick: "Videohovor",
  tabs: "Karty",
  pleaseEnterEmailAccessQuickSession:
    "Zadejte prosím svůj e-mail pro přístup k rychlé relaci",
  disconnectWhatsAppBot: "Odpojit WhatsApp-bota",
  connectWhatsAppBot: "Připojit WhatsApp-bota",
  WhatsAppBot: "WhatsApp-bot",
  videoCallSession: "Videohovor",
  offerCardSize: "Velikost karty nabídky",
  large: "Velká",
  small: "Malá",
  chooseCardSizeOffer: "Vyberte velikost karty pro svou nabídku v seznamu",
  copyProfileLink: "Zkopírovat odkaz na profil",
  profileQRCode: "Můj QR kód profilu",
  copyCommunitiesLink: "Odkaz na komunity",
  copyEventsLink: "Odkaz na události",
  copyProductsLink: "Odkaz na produkty",
  copySessionsLink: "Odkaz na schůzky",
  copyTipsLink: "Odkaz na tipy",
  copyPortfolioLink: "Odkaz na portfolio",
  copyReviewsLink: "Odkaz na recenze",
  leaveReviewForMe: "Zanechte recenzi pro mě",
  shareYourWaylight: "Sdílejte svůj Waylight",
  myProfileQRCode: "Můj QR kód profilu",
  menu: "Nabídka",
  selectOfferExportData: "Vyberte nabídku pro export dat",
  setCancellationDelay: "Nastavit zpoždění zrušení",
  approvalRequired: "Vyžadováno schválení",
  cancellationFailed: "Zrušení selhalo",
  youUnableCancelDesctiption:
    "Nemůžete zrušit schůzku během {{cancelDelay}} hodin před jejím začátkem. Kontaktujte vlastníka schůzky pro další podrobnosti.",
  rescheduleFailed: "Přeplánování selhalo",
  unableRescheduleDescription:
    "Nemůžete přeplánovat schůzku během {{rescheduleDelay}} hodin před jejím začátkem. Kontaktujte vlastníka schůzky pro další podrobnosti.",
  errorRefundDescription:
    "Něco se pokazilo při rušení schůzky. Kontaktujte prosím ",
  refundFailed: "Vrácení peněz selhalo",
  appointment: "Schůzka",
  addInformationAboutAppointment:
    "Přidejte informace o schůzce, které vaši klienti odemknou.",
  addMapLink: "Přidat odkaz na mapu",
  addAddressAppointment: "Přidat adresu schůzky",
  uploadFileOptionalAppointmentDetails:
    "Nahrajte jakýkoli soubor s volitelnými podrobnostmi o schůzce",
  locationOptional: "Odkaz na místo (volitelné)",
  appointmentName: "Název schůzky",
  appointmentErrorName: "Název schůzky nesmí být prázdný",
  upcomingIn: "Nadcházející za ",
  joinVideoCall: "Připojit se k videohovoru",
  cancelAppointment: "Zrušit schůzku",
  areYouSureWantCancelClient:
    "Jste si jisti, že chcete zrušit? Vaše platba bude vrácena do 5-10 dnů. Poplatky za platební bránu mohou být zadrženy.",
  areYouSureWantCancelExpert:
    "Jste si jisti, že chcete zrušit? Platba vašeho klienta bude vrácena do 5-10 dnů. Poplatky za platební bránu mohou být zadrženy.",
  aboutAppointment: "O nabídce",
  finishAppointmentTitle: "Jste si jistý, že chcete dokončit schůzku?",
  finishAppointmentDescription:
    "Stav vaší schůzky bude změněn na ‘proběhla’ a bude ukončena.",
  newAppointmentOffer: "Nová nabídka schůzky",
  editAppointments: "Upravit schůzky",
  cancellationReason: "Důvod zrušení",
  aboutAppointmentCreatUpdate: "O schůzce",
  sessionRescheduledByHost: "Vaše schůzka byla přeložena pořadatelem",
  active: "Aktivní",
  headline: "Nadpis",
  userName: "Uživatelské jméno",
  turnOf: "Vypnout",
  promotion: "Propagace",
  qRCode: "QR kód",
  timeframe: "Časový rámec",
  youAreInPreviewMode:
    "Jste v režimu náhledu. Chcete-li interagovat s profilem, klikněte níže a opusťte tento režim!",
  backToMyProfile: "Zpět na můj profil",
  previewMode: "Režim náhledu",
  editMenu: "Upravit menu",
  createMenu: "Vytvořit menu",
  digitalProduct: "Digitální produkt",
  buyTicketsHere: "Kupte si lístky zde",
  unsubscribedSuccessfully: "Úspěšně odhlášeno",
  errorWhileSubscribing: "Chyba při přihlašování",
  errorWhileUnsubscribing: "Chyba při odhlašování",
  add: "Přidat",
  bio: "Životopis",
  bioTooLong: "Omezte prosím svůj životopis na 300 znaků",
  publicIdNotAllowed:
    "Tento veřejný ID nelze vytvořit. Vyberte si prosím jiný.",
  telegram: "Telegram",
  whatsApp: "WhatsApp",
  email: "E-mail",
  instagram: "Instagram",
  less: "Méně",
  moreText: "Více",
  buyAccessForFree: "Získat přístup zdarma",
  verifyClientLink: "Ověřte svůj klientský odkaz",
  toastCopiedSuccessfully: "Zkopírováno úspěšně",
};
